// src/utils/logout.js
import { updateActivityStatus } from './updateActivityStatus';

export const handleLogout = (userId) => {
  updateActivityStatus(userId, 'offline');
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('assignedDealerships');
  window.location.href = '/login';
};
