import React, { useEffect, useState, useCallback, useRef } from 'react';
import { updateActivityStatus } from '../../utils/updateActivityStatus';
import { getUserFromToken } from '../../utils/auth';

const UserActivityTracker = ({ children }) => {
  const [isAway, setIsAway] = useState(false);
  const timeoutIdRef = useRef(null); // Use useRef for timeout ID
  const user = useRef(getUserFromToken()); // Use useRef to store user, preventing unnecessary recalculations
  const AWAY_TIME = 5 * 60 * 1000; // 5 minutes of inactivity

  // Function to update user status if it changes
  const setStatus = useCallback(
    (status) => {
      if (user.current && user.current.id) {
        // Only update if the status has actually changed
        if ((status === 'away' && !isAway) || (status === 'online' && isAway)) {
          updateActivityStatus(user.current.id, status); // Update the user's status
          setIsAway(status === 'away'); // Update local state only if the status changes
          console.log(`User activity status updated to: ${status}`);
        }
      }
    },
    [isAway]
  );

  // Function to reset the inactivity timer
  const resetInactivityTimeout = useCallback(() => {
    // Clear existing timeout if it exists
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    // Set new timeout to change the user's status to 'away' after inactivity
    timeoutIdRef.current = setTimeout(() => {
      setStatus('away'); // Set user status to away after timeout
    }, AWAY_TIME);

    // If the user is marked as away and they become active, set them to 'online'
    if (isAway) {
      setStatus('online'); // Set user status back to online on activity
    }
  }, [isAway, setStatus]);

  // Set up event listeners for user activity
  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown', 'click'];

    // Attach listeners for user activity
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetInactivityTimeout);
    });

    // Set initial timeout
    resetInactivityTimeout();

    // Cleanup event listeners and timeouts on component unmount
    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimeout);
      });
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current); // Clean up timeout
    };
  }, [resetInactivityTimeout]);

  return <>{children}</>;
};

export default UserActivityTracker;
