import axios from "axios";
import { createVehicleLogEntry } from "./createVehicleLogEntry"; // Adjust the path as needed

/**
 * Handles the status change and updates relevant job details.
 * @param {Object} params - The function parameters.
 * @param {Object} params.contact - Current contact details.
 * @param {Function} params.setContact - Function to update the contact state.
 * @param {string} params.status - The new status to set.
 * @param {string|null} params.jobStatus - An optional job status to set as a subStatus.
 * @param {string} params.userId - The user ID performing the operation.
 */
export const handleStatusChange = async ({
  contact,
  setContact,
  status,
  jobStatus = null,
  userId,
}) => {
  if (!userId) {
    console.error("User ID is required to perform this action.");
    return;
  }

  let updatedContact = { ...contact };
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`;
  let requestBody = { job_id: contact.id };

  // Handle major statuses
  if (status === "Account" || status === "EFT") {
    updatedContact.dealStatus = "Won";
    updatedContact.job_status = "Approved";
    updatedContact.subStatus = "";
    requestBody = {
      ...requestBody,
      dealStatus: "Won",
      job_status: "Approved",
      subStatus: "",
    };
  } else if (status === "Insurance") {
    updatedContact.dealStatus = "Pending";
    updatedContact.job_status = "Pending";
    updatedContact.subStatus = "";
    requestBody = {
      ...requestBody,
      dealStatus: "Pending",
      job_status: "Pending",
      subStatus: "",
    };
  } else if (status === "DNC" || status === "No") {
    updatedContact.dealStatus = "Lost";
    updatedContact.job_status = "Lost";
    updatedContact.subStatus = "";
    requestBody = {
      ...requestBody,
      dealStatus: "Lost",
      job_status: "Lost",
      subStatus: "",
    };
  } else {
    // Handle custom statuses
    updatedContact.dealStatus = status;
    if (jobStatus) {
      updatedContact.subStatus = jobStatus;
      requestBody = {
        ...requestBody,
        dealStatus: "Pending",
        job_status: "Pending",
        subStatus: jobStatus,
      };
    } else {
      requestBody.dealStatus = status;
    }
  }

  setContact(updatedContact);

  try {
    // Make API request to update status
    await axios.post(apiUrl, requestBody);

    // Log status change activity
    await createVehicleLogEntry({
      registration_number: updatedContact.registration,
      user_id: userId,
      activity_title: "Status Updated",
      activity_description: `Changed status to ${updatedContact.dealStatus}`,
    });
  } catch (error) {
    console.error("Error updating dealStatus and job_status:", error);
  }
};
