import React, { useState, useMemo } from "react";
import { insertInsuranceClaim } from "../../utils/insertInsuranceClaim";
import "./InsuranceCompanyModal.css";

const InsuranceCompanyModal = ({
  show,
  onClose,
  onInsuranceCompanyChange,
  registrationNumber,
  selectedJobId,
  contactNumber,
  firstName,
  lastName,
  serviceChecks,
}) => {
  const [selectedCompany, setSelectedCompany] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [fleet, setFleet] = useState("No");

  // Capitalize and map service keys to user-friendly labels
  const capitalizeServiceKey = (key) => {
    const keyMap = {
      headlight: "Headlight",
      windscreen: "Windscreen",
      polish: "Paint Protection",
    };
    return keyMap[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  // Extract services into a structured array for rendering
  const extractedServices = useMemo(() => {
    if (!serviceChecks) {
      console.error("No serviceChecks provided.");
      return [];
    }

    let parsedServiceChecks = serviceChecks;

    // Safely parse `serviceChecks` if it's a JSON string
    try {
      if (typeof serviceChecks === "string") {
        parsedServiceChecks = JSON.parse(serviceChecks);
      }

      if (typeof parsedServiceChecks !== "object") {
        throw new Error("Invalid serviceChecks format.");
      }

      // Convert the parsed serviceChecks object into an array
      const servicesArray = Object.entries(parsedServiceChecks).map(
        ([key, { selected, option }]) => ({
          key,
          selected: selected || false,
          option: option || "",
          label: `${capitalizeServiceKey(key)}${option ? ` - ${option}` : ""}`,
        })
      );

      return servicesArray;
    } catch (error) {
      console.error("Error parsing or processing serviceChecks:", error);
      return [];
    }
  }, [serviceChecks]);

  const handleServiceCheckChange = (serviceKey, selected, option) => {
    console.log(`Changed ${serviceKey}:`, { selected, option });
    // Handle state updates in the parent if required
  };

  const handleSubmit = async () => {
    // Check if at least one service is selected
    const isAnyServiceSelected = extractedServices.some(
      (service) => service.selected
    );

    console.log('isAnyServiceSelected', isAnyServiceSelected)
    console.log('selectedCompany', selectedCompany)
    console.log('idNumber', idNumber)
    console.log('firstName', firstName)
    console.log('lastName', lastName)
    console.log('contactNumber', contactNumber)
    console.log('policyNumber ', policyNumber)
    console.log('registrationNumber', registrationNumber)
    console.log('selectedJobId', selectedJobId)
    console.log('isAnyServiceSelected', isAnyServiceSelected)
  
    if (
      selectedCompany.trim() &&
      idNumber.trim() &&
      firstName.trim() &&
      lastName.trim() &&
      contactNumber.trim() &&
      policyNumber.trim() &&
      registrationNumber &&
      selectedJobId &&
      isAnyServiceSelected
    ) {
      const servicesRequired = extractedServices.map((service) => ({
        key: service.key,
        selected: service.selected,
        option: service.option,
      }));
  
      const insuranceDetails = {
        insurance_company: selectedCompany.trim(),
        id_number: idNumber.trim(),
        owner_name: firstName.trim(),
        owner_last_name: lastName.trim(),
        contact_number: contactNumber.trim(),
        policy_number: policyNumber.trim(),
        is_fleet: fleet,
        services_required: JSON.stringify(servicesRequired),
        vehicle_registration: registrationNumber,
        job_id: selectedJobId,
      };

      console.log('Insurance Details: ', insuranceDetails)
  
      try {
        await insertInsuranceClaim(insuranceDetails);
        onInsuranceCompanyChange(selectedCompany);
      } catch (error) {
        console.error("Error inserting insurance claim:", error);
      }
    } else {
      let errorMessage = "Please ensure all fields are filled.";
      if (!isAnyServiceSelected) {
        errorMessage = "Please select at least one service.";
      }
      console.error(errorMessage);
      alert(errorMessage);
    }
  };
  

  if (!show) return null;

  return (
    <div className="insurance-modal-backdrop">
      <div className="insurance-modal-content">
        <h2>Insurance Company Details</h2>
        <div className="insurance-modal-columns">
          {/* Left Column */}
          <div className="insurance-column">
            <div className="insurance-form-group">
              <label htmlFor="vehicle-registration">Vehicle Registration</label>
              <input
                type="text"
                id="vehicle-registration"
                value={registrationNumber}
                readOnly
                placeholder="Vehicle Registration"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="id-number">ID Number</label>
              <input
                type="text"
                id="id-number"
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                placeholder="Enter ID Number"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="owner-name">Name</label>
              <input
                type="text"
                id="owner-name"
                value={firstName}
                readOnly
                placeholder="Enter Owner Name"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="owner-last-name">Last Name</label>
              <input
                type="text"
                id="owner-last-name"
                value={lastName}
                readOnly
                placeholder="Enter Owner Last Name"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="insurance-company">
                Select Insurance Company
              </label>
              <select
                id="insurance-company"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <option value="">Select a company</option>
                <option value="InsureGood">InsureGood</option>
                <option value="SafeCo">SafeCo</option>
                <option value="PremiumPlus">PremiumPlus</option>
                <option value="AutoGuard">AutoGuard</option>
                <option value="EliteCoverage">EliteCoverage</option>
                <option value="SafeGuard">SafeGuard</option>
                <option value="PremiumAuto">PremiumAuto</option>
                <option value="AutoProtect">AutoProtect</option>
                <option value="SafeRide">SafeRide</option>
                <option value="DriveSafe">DriveSafe</option>
                <option value="AutoShield">AutoShield</option>
                <option value="DriveSure">DriveSure</option>
                <option value="CarCare">CarCare</option>
                <option value="ProtectPlus">ProtectPlus</option>
                <option value="TotalProtect">TotalProtect</option>
                <option value="SuperShield">SuperShield</option>
              </select>
            </div>
          </div>

          {/* Right Column */}
          <div className="insurance-column">
            <div className="insurance-form-group">
              <label htmlFor="contact-number">Contact Number</label>
              <input
                type="text"
                id="contact-number"
                value={contactNumber}
                readOnly
                placeholder="Enter Contact Number"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="policy-number">Policy Number</label>
              <input
                type="text"
                id="policy-number"
                value={policyNumber}
                onChange={(e) => setPolicyNumber(e.target.value)}
                placeholder="Enter Policy Number"
              />
            </div>
            <div className="insurance-form-group">
              <label htmlFor="fleet">Fleet</label>
              <select
                id="fleet"
                value={fleet}
                onChange={(e) => setFleet(e.target.value)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="insurance-form-group insurance-checkbox-group">
              <label>Services Required</label>
              <div className="services-checkboxes">
                {extractedServices.map(({ key, selected, label }) => (
                  <label key={key}>
                    <input
                      type="checkbox"
                      checked={selected}
                      onChange={(e) =>
                        handleServiceCheckChange(
                          key,
                          e.target.checked,
                          e.target.checked ? label : ""
                        )
                      }
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="insurance-modal-actions">
          <button className="insurance-modal-submit" onClick={handleSubmit}>
            Submit
          </button>
          <button className="insurance-modal-cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCompanyModal;
