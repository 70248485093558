import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import HistoryModal from "../../components/HistoryModal/HistoryModal"; // Import HistoryModal
import { fetchAllContacts } from "../../utils/fetchContacts"; // Import the fetch contacts utility
import "./Contacts.css";

export const Contacts = () => {
  const [contacts, setContacts] = useState([]); // State to hold contacts
  const [searchQuery, setSearchQuery] = useState(""); // Single search field
  const [selectedContact, setSelectedContact] = useState(null);

  // Fetch all contacts on component mount
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await fetchAllContacts(); // Fetch contacts from the backend
        setContacts(data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  // Handle contact row click (open history modal)
  const handleRowClick = (contact) => {
    setSelectedContact(contact);
  };

  // Filter contacts based on the search query
  const filteredContacts = contacts.filter((contact) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (contact.name?.toLowerCase() || "").includes(searchLower) || // Safely handle null or undefined names
      (contact.number || "").includes(searchQuery) || // Safely handle null or undefined numbers
      (contact.alternativeNumber || "").includes(searchQuery) || // Safely handle null or undefined alternative numbers
      (contact.registrationNumber?.toUpperCase() || "").includes(
        searchQuery.toUpperCase()
      ) || // Safely handle null or undefined registration numbers
      (contact.email?.toLowerCase() || "").includes(searchLower) // Safely handle null or undefined emails
    );
  });

  return (
    <div className="contact-container">
      <Header />
      <div className="contact-page-content">
        <Sidebar />
        <main className="contact-main-content">
          <h2>Contact List</h2>
          <div className="contact-filters">
            <input
              type="text"
              placeholder="Search by Name, Number, Registration, or Email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="contact-input"
            />
          </div>
          {selectedContact && (
            <HistoryModal
              show={selectedContact !== null}
              onClose={() => setSelectedContact(null)}
              registrationNumber={selectedContact.registrationNumber}
              columns={null} // Pass null or relevant columns if available
              historyData={null} // Fetch or pass history data if required
            />
          )}
          <div className="table-container">
            <table className="contact-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Number</th>
                  <th>Alternative Number</th>
                  <th>Registration Number</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {filteredContacts.map((contact) => (
                  <tr key={contact.id} onClick={() => handleRowClick(contact)}>
                    <td>{contact.name}</td>
                    <td>{contact.number}</td>
                    <td>{contact.alternativeNumber}</td>
                    <td>{contact.registrationNumber}</td>
                    <td>{contact.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};
