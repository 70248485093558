import axios from "axios";

export const fetchTagVariants = async (setAvailableVariants) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_TAG_VARIANTS_URL);
    setAvailableVariants(response.data);  // This must be a valid function
  } catch (error) {
    console.error("Error fetching tag variants:", error);
  }
};
