import axios from "axios";

export const updateDealershipGroup = async (groupData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/dealership-groups/${groupData.group_id}`,
      groupData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating dealership group:", error);
    throw error;
  }
};
