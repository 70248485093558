// utils/fetchAssignedAgent.js
import axios from 'axios';

export const fetchAssignedAgent = async (job_id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/jobs/${job_id}/agent`);
    return response.data.assigned_agent;  // Assuming the API returns the assigned agent in the response
  } catch (error) {
    console.error('Error fetching assigned agent:', error);
    throw error;
  }
};

