// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionsFilter.css */

.action-filter {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 100%;
}

.action-filter h2 {
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--grey-background-color);
}

.action-filters {
  display: flex;
  flex-direction: row;
  position: relative;
}

.action-filter-link {
  background-color: var(--light-background-color);
  color: var(--text-dark-color);
  border: none;
  border-radius: 0px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.view-invoice-btn {
  background-color: #13cc6f;
  border: none;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.action-filter-link:hover,
.view-invoice-btn:hover {
  background-color: var(--secondary-color);
  color: var(--text-light-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionFilter/ActionFilter.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,+CAA+C;EAC/C,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,eAAe;EACf,aAAa;EACb,qBAAqB;EACrB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,eAAe;AACjB;;AAEA;;EAEE,wCAAwC;EACxC,8BAA8B;AAChC","sourcesContent":["/* ActionsFilter.css */\r\n\r\n.action-filter {\r\n  display: flex;\r\n  flex-direction: column;\r\n  border-radius: 4px;\r\n  width: 100%;\r\n}\r\n\r\n.action-filter h2 {\r\n  margin-bottom: 10px;\r\n  font-size: 16px;\r\n  color: var(--grey-background-color);\r\n}\r\n\r\n.action-filters {\r\n  display: flex;\r\n  flex-direction: row;\r\n  position: relative;\r\n}\r\n\r\n.action-filter-link {\r\n  background-color: var(--light-background-color);\r\n  color: var(--text-dark-color);\r\n  border: none;\r\n  border-radius: 0px;\r\n  border: 1px solid var(--primary-color);\r\n  cursor: pointer;\r\n  padding: 10px;\r\n  text-decoration: none;\r\n  width: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  text-align: center;\r\n  font-size: 14px;\r\n}\r\n\r\n.view-invoice-btn {\r\n  background-color: #13cc6f;\r\n  border: none;\r\n  color: #fff;\r\n  padding: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.action-filter-link:hover,\r\n.view-invoice-btn:hover {\r\n  background-color: var(--secondary-color);\r\n  color: var(--text-light-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
