import axios from "axios";
import { createVehicleLogEntry } from "./createVehicleLogEntry"; // Adjust path as needed

/**
 * Handles the insurance company change and updates relevant job details.
 * @param {Object} params - The function parameters.
 * @param {Object} params.contact - Current contact details.
 * @param {Function} params.setContact - Function to update the contact state.
 * @param {string} params.insurance_broker - The new insurance broker name.
 * @param {string} params.userId - The user ID performing the operation.
 * @param {Function} params.setShowInsuranceCompanyModal - Function to toggle the modal visibility.
 */
export const handleInsuranceCompanyChange = async ({
  contact,
  setContact,
  insurance_broker,
  userId,
  setShowInsuranceCompanyModal,
}) => {
  if (!userId) {
    console.error("User ID is required to perform this action.");
    return;
  }

  const updatedContact = {
    ...contact,
    insurance_broker,
    insurance: true,
    dealStatus: "Pending",
    job_status: "Pending",
  };
  setContact(updatedContact);

  try {
    // Update job details via API
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`, {
      job_id: updatedContact.id,
      insurance_broker,
      insurance: true,
      dealStatus: "Pending",
      job_status: "Pending",
      subStatus: updatedContact.subStatus || "",
    });

    // Log the insurance change activity
    await createVehicleLogEntry({
      registration_number: updatedContact.registration,
      user_id: userId,
      activity_title: "Insurance Company Updated",
      activity_description: `Updated insurance broker to ${insurance_broker}`,
    });
  } catch (error) {
    console.error("Error updating insurance:", error);
  }

  setShowInsuranceCompanyModal(false);
};
