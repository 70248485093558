import axios from "axios";

const fetchServices = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/services`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log('Fetching services: ', response.data.data)
    return response.data.data; // Return fetched data
  } catch (error) {
    console.error("Error fetching services:", error);
    throw error; // Rethrow the error to handle it in the caller
  }
};

export default fetchServices;
