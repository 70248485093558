import { useState, useCallback } from "react";

export const useServiceAdvisors = (serviceAdvisors, formData, handleChange) => {
  const [selectedServiceAdvisors, setSelectedServiceAdvisors] = useState([]);

  const [showServiceAdvisorDropdown, setShowServiceAdvisorDropdown] = useState(
    false
  );

  // Utility function to sanitize input
  const sanitizeServiceAgents = (agents) => {
    if (Array.isArray(agents)) return agents; // Already an array
    if (typeof agents === "string") {
      try {
        return JSON.parse(agents); // Parse if it's a valid JSON string
      } catch {
        console.error("Error sanitizing service agents:", agents);
        return []; // Default to an empty array if input is invalid
      }
    }
    return []; // Default to an empty array if input is invalid
  };

  // Initialize `selectedServiceAdvisors` from `formData.service_agents`
  const initializeSelectedServiceAdvisors = () => {
    if (formData.service_agents && serviceAdvisors.length > 0) {
      const advisorIds = sanitizeServiceAgents(formData.service_agents);

      const selectedAdvisors = advisorIds
        .map((id) =>
          serviceAdvisors.find((advisor) => advisor.service_advisor_id === id)
        )
        .filter(Boolean)
        .map((advisor) => ({
          id: advisor.service_advisor_id,
          name: advisor.service_advisor_name,
        }));

      setSelectedServiceAdvisors(selectedAdvisors);
    }
  };

  // Synchronize `selectedServiceAdvisors` to `formData.service_agents`
  const syncSelectedServiceAdvisorsToFormData = useCallback(() => {
    const advisorIds = selectedServiceAdvisors.map((advisor) => advisor.id);
    handleChange({
      target: {
        name: "service_agents",
        value: JSON.stringify(advisorIds),
      },
    });
  }, [selectedServiceAdvisors, handleChange]);

  // Toggle dropdown visibility
  const handleServiceAdvisorDropdownToggle = () => {
    setShowServiceAdvisorDropdown((prev) => !prev);
  };

  // Handle selection/deselection of advisors
  const handleServiceAdvisorChange = (advisorId, advisorName) => {
    setSelectedServiceAdvisors((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (advisor) => advisor.id === advisorId
      );

      const updatedSelection = isAlreadySelected
        ? prevSelected.filter((advisor) => advisor.id !== advisorId) // Remove if already selected
        : [...prevSelected, { id: advisorId, name: advisorName }]; // Add new advisor

      return updatedSelection;
    });

    syncSelectedServiceAdvisorsToFormData();
  };

  return {
    selectedServiceAdvisors,
    setSelectedServiceAdvisors,
    showServiceAdvisorDropdown,
    handleServiceAdvisorDropdownToggle,
    handleServiceAdvisorChange,
    initializeSelectedServiceAdvisors,
  };
};
