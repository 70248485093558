import axios from "axios";

/**
 * Fetch assigned dealerships and their goals for the given agent ID.
 * @param {number} agentId - The ID of the agent.
 * @returns {Promise<Array>} Array of assigned dealership objects including names and goals.
 */
export const fetchAssignedDealerships = async (agentId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/assigned/${agentId}`
    );

    // The backend now provides merged dealership IDs, names, and goals
    const { data } = response.data;

    return data; // Return the complete array of dealership objects
  } catch (error) {
    console.error("Error fetching assigned dealerships and goals:", error);
    throw error;
  }
};
