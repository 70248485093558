import React, { useState, useEffect } from "react";

const TechnicianSelector = ({
  technicians = [],
  selectedTechnicians = "",
  showTechnicianDropdown,
  handleTechnicianDropdownToggle,
  handleTechnicianChange,
}) => {
  // Parse incoming `selectedTechnicians` to an array
  const parseSelectedTechnicians = (techniciansString) => {
    if (Array.isArray(techniciansString)) return techniciansString;
    if (typeof techniciansString === "string") {
      return techniciansString
        .split(",")
        .map((name) => name.trim())
        .filter(Boolean); // Remove empty names
    }
    return [];
  };

  // Local state to track selected technicians
  const [localSelectedTechnicians, setLocalSelectedTechnicians] = useState(
    parseSelectedTechnicians(selectedTechnicians)
  );

  useEffect(() => {
    // Sync local state when props change
    setLocalSelectedTechnicians(parseSelectedTechnicians(selectedTechnicians));
  }, [selectedTechnicians]);

  // Handle toggle for checkbox selection
  const toggleTechnicianSelection = (technicianName) => {
    const updatedSelection = localSelectedTechnicians.includes(technicianName)
      ? localSelectedTechnicians.filter((name) => name !== technicianName) // Remove if already selected
      : [...localSelectedTechnicians, technicianName]; // Add if not selected

    setLocalSelectedTechnicians(updatedSelection);

    // Propagate changes to parent
    handleTechnicianChange(updatedSelection.join(", "));
  };

  return (
    <div className="technician-dropdown">
      <label>Technician Selector</label>
      <div
        onClick={handleTechnicianDropdownToggle}
        className="technician-dropdown-select styled-input"
      >
        {localSelectedTechnicians.length > 0
          ? localSelectedTechnicians.join(", ")
          : "Select Technicians"}
      </div>
      {showTechnicianDropdown && (
        <div className="technician-dropdown-menu">
          {technicians.length > 0 ? (
            technicians.map((technician) => {
              const technicianName = `${technician.user_first_name} ${technician.user_last_name}`;
              const isChecked = localSelectedTechnicians.includes(
                technicianName
              );

              return (
                <div key={technician.user_id} className="technician-option">
                  <input
                    type="checkbox"
                    id={`tech-${technician.user_id}`}
                    checked={isChecked}
                    onChange={() => toggleTechnicianSelection(technicianName)}
                  />
                  <label htmlFor={`tech-${technician.user_id}`}>
                    {technicianName}
                  </label>
                </div>
              );
            })
          ) : (
            <div className="technician-no-options">
              No Technicians Available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TechnicianSelector;
