import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import axios from 'axios';
import "./ManageCompanyTargets.css";

const ManageCompanyTargets = () => {
  const [targets, setTargets] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    user_id: "",
    username: "",
    chips_target: "",
    hlr_target: "",
    polish_target: ""
  });
  const [editingTarget, setEditingTarget] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchTargets();
    fetchUsersWithTargets();
  }, []);

  const fetchTargets = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/targets`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setTargets(response.data.data || []);
    } catch (error) {
      console.error("Error fetching targets:", error);
    }
  };

  const fetchUsersWithTargets = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users-with-targets`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data || []);
    } catch (error) {
      console.error("Error fetching users with targets:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "user_id") {
      const selectedUser = users.find((user) => user.user_id === parseInt(value));
      if (selectedUser) {
        updatedFormData.username = selectedUser.username;
      } else {
        updatedFormData.username = "";
      }
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTarget) {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/targets/${editingTarget.id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/targets`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      }
      fetchTargets();
      setShowModal(false);
      setEditingTarget(null);
      setFormData({
        user_id: "",
        username: "",
        chips_target: "",
        hlr_target: "",
        polish_target: ""
      });
    } catch (error) {
      console.error("Error creating/updating target:", error);
    }
  };

  const handleEdit = (target) => {
    setEditingTarget(target);
    setFormData({
      user_id: target.user_id,
      username: target.username,
      chips_target: target.chips_target,
      hlr_target: target.hlr_target,
      polish_target: target.polish_target
    });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/targets/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchTargets();
    } catch (error) {
      console.error("Error deleting target:", error);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingTarget(null);
    setFormData({
      user_id: "",
      username: "",
      chips_target: "",
      hlr_target: "",
      polish_target: ""
    });
  };

  return (
    <div className="ManageCompanyTargets">
      <h2>Manage Company Targets</h2>
      <div className="icon-container" onClick={handleOpenModal}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Chips Target</th>
            <th>HLR Target</th>
            <th>Polish Target</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {targets.length > 0 ? targets.map((target) => (
            <tr key={target.id} onClick={() => handleEdit(target)}>
              <td>{target.username}</td>
              <td>{target.chips_target}</td>
              <td>{target.hlr_target}</td>
              <td>{target.polish_target}</td>
              <td className="actions-cell">
                <FaTrash onClick={(e) => {e.stopPropagation(); handleDelete(target.id);}} />
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="5">No targets found</td>
            </tr>
          )}
        </tbody>
      </table>

      {showModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{editingTarget ? "Edit Target" : "Add New Target"}</h2>
            <form onSubmit={handleSubmit}>
              <label>Username</label>
              <select
                name="user_id"
                value={formData.user_id}
                onChange={handleChange}
                required
              >
                <option value="">Select User</option>
                {users.length > 0 ? users.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.username}
                  </option>
                )) : (
                  <option disabled>No users found</option>
                )}
              </select>
              <label>Chips Target</label>
              <input
                type="number"
                name="chips_target"
                value={formData.chips_target}
                onChange={handleChange}
                required
              />
              <label>HLR Target</label>
              <input
                type="number"
                name="hlr_target"
                value={formData.hlr_target}
                onChange={handleChange}
                required
              />
              <label>Polish Target</label>
              <input
                type="number"
                name="polish_target"
                value={formData.polish_target}
                onChange={handleChange}
                required
              />
              <div className="modal-buttons">
                <button type="submit">Submit</button>
                <button type="button" onClick={handleCloseModal}>Close</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageCompanyTargets;
