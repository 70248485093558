import React from "react";
import { FaTrash } from "react-icons/fa";

const BasePricingView = ({ pricing, onEdit, onDelete }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Trade Price Excl.</th>
            <th>Trade Price Incl.</th>
            <th>Retail Price Excl.</th>
            <th>Retail Price Incl.</th>
            <th>Gross Profit Excl.</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pricing.map((pricingItem) => (
            <tr
              key={pricingItem.service_id}
              onClick={() => onEdit(pricingItem)}
            >
              <td>{pricingItem.service_name}</td>
              <td>{pricingItem.trade_price_excl}</td>
              <td>{pricingItem.trade_price_incl}</td>
              <td>{pricingItem.retail_price_excl}</td>
              <td>{pricingItem.retail_price_incl}</td>
              <td>{pricingItem.gross_profit_excl}</td>
              <td className="actions-cell">
                <FaTrash
                  className="trash-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(pricingItem.service_id);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BasePricingView;
