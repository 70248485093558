import axios from 'axios';

// Fetch all contacts from the system (jobs)
export const fetchAllContacts = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contacts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts:", error);
    throw error;
  }
};
