import axios from 'axios';

export const createVehicleLogEntry = async (logData) => {
  try {
    const token = localStorage.getItem("token"); // Assuming token-based authentication
    const userData = JSON.parse(localStorage.getItem("user")); // Get user info from localStorage

    // Ensure that userData exists and has an id
    if (!userData || !userData.id) {
      throw new Error("User ID is missing from session. Cannot create a vehicle log entry.");
    }
    // Ensure the token exists
    if (!token) {
      throw new Error("Token is missing. Cannot authenticate the request.");
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle-logs`,
      {
        registration_number: logData.registration_number,
        user_id: userData.id, // Fetch user ID from session
        activity_title: logData.activity_title,
        activity_description: logData.activity_description,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in headers
        },
      }
    );

    return response.data; // Return the response data (log ID, success message)

  } catch (error) {
    console.error("Error creating vehicle log entry:", error.message);
    throw error; // Propagate the error to be handled by the calling function
  }
};
