// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './global.css';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import './utils/axiosInterceptor';
import UserActivityTracker from './components/UserActivityTracker/userActivityTracker';
import { BrowserRouter as Router } from 'react-router-dom'; // Ensure Router is in the root

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <Router>
        <UserActivityTracker>
          <App />
        </UserActivityTracker>
      </Router>
    </AuthProvider>
);
