import React, { useState, useEffect } from "react";
import "./WidgetReport.css";
import { fetchServiceCheckCounts } from "../../utils/fetchServiceCheckCounts";
import { fetchJobCountsByAgentAndDealership } from "../../utils/fetchJobCountsByAgentAndDealership";
import { fetchAssignedDealerships } from "../../utils/fetchAssignedDealerships";
import { Link } from "react-router-dom";

const WidgetReport = ({ jobCounts, userId, dateRange }) => {
  const [serviceCounts, setServiceCounts] = useState({
    total_chips: 0,
    total_replace_jobs: 0,
    total_polish_jobs: 0,
    total_headlight_repairs: 0, // Initial state for headlight repairs
  });

  const [dealershipGoals, setDealershipGoals] = useState([]);
  const [totals, setTotals] = useState({
    chips_goal: 0,
    hlr_goal: 0,
    pp_goal: 0,
    surveyed_cars: 0, // Include surveyed cars total
  });
  const [selectedDealership, setSelectedDealership] = useState("all");

  // State to hold job service counts and surveyed cars
  const [jobServiceCounts, setJobServiceCounts] = useState({
    headlight: 0,
    windscreen: 0,
    polish: 0,
    surveyedCars: 0, // Add surveyedCars
  });

  // Debugging log for selected dealership
  useEffect(() => {
    console.log("Selected dealership:", selectedDealership);
  }, [selectedDealership]);

  // Function to calculate win percentage
  function calculateWinPercentage() {
    const lostDeals = Number(jobCounts.lostDeals);
    const newDeals = Number(jobCounts.newDeals);
    const pendingDeals = Number(jobCounts.pendingDeals);
    const wonDeals = Number(jobCounts.wonDeals);

    const totalDeals = lostDeals + newDeals + pendingDeals + wonDeals;

    if (totalDeals === 0) {
      return 0;
    }

    const winPercentage = (wonDeals / totalDeals) * 100;
    return Math.round(winPercentage);
  }

  // Fetch service check counts on userId and dateRange change
  useEffect(() => {
    async function fetchData() {
      if (userId && dateRange.startDate && dateRange.endDate) {
        try {
          const data = await fetchServiceCheckCounts(
            userId,
            dateRange.startDate,
            dateRange.endDate
          );
          setServiceCounts({
            total_chips: data.total_chips || 0,
            total_replace_jobs: data.total_replace_jobs || 0,
            total_polish_jobs: data.total_polish_jobs || 0,
            total_headlight_repairs: data.total_headlight_repairs || 0, // Set headlight repairs count
          });
        } catch (error) {
          console.error("Error fetching service check counts:", error);
        }
      }
    }

    fetchData();
  }, [userId, dateRange]);

  // Fetch dealership goals and names on mount and calculate totals
  useEffect(() => {
    async function fetchDealershipData() {
      try {
        const data = await fetchAssignedDealerships(userId);

        // Calculate totals for all dealerships
        const calculatedTotals = data.reduce(
          (totals, goal) => {
            totals.chips_goal += goal.chips_goal || 0;
            totals.hlr_goal += goal.hlr_goal || 0;
            totals.pp_goal += goal.pp_goal || 0;
            totals.surveyed_cars += goal.surveyed_cars || 0; // Include surveyed cars in totals
            return totals;
          },
          { chips_goal: 0, hlr_goal: 0, pp_goal: 0, surveyed_cars: 0 }
        );

        setDealershipGoals(data);
        setTotals(calculatedTotals);
      } catch (error) {
        console.error("Error fetching dealership data:", error);
      }
    }

    fetchDealershipData();
  }, [userId]);

  // Fetch job service counts and surveyed cars when the dealership or date range changes
  useEffect(() => {
    async function fetchJobCounts() {
      console.log("Fetching job counts for dealership:", selectedDealership);

      if (userId && dateRange.startDate && dateRange.endDate) {
        try {
          const dealershipName =
            selectedDealership === "all"
              ? "all" // Pass "all" for the backend to handle
              : dealershipGoals.find(
                  (goal) => goal.dealership_id === parseInt(selectedDealership)
                )?.dealership_name;

          if (dealershipName) {
            const counts = await fetchJobCountsByAgentAndDealership(
              userId,
              dealershipName,
              dateRange // Pass the date range
            );
            console.log("Fetched job counts:", counts);

            // Update job service counts, including surveyed cars
            setJobServiceCounts({
              ...counts.serviceCounts,
              surveyedCars: counts.surveyedCars, // Include surveyedCars
            });
          } else {
            console.warn(
              "Dealership ID not found for selected option:",
              selectedDealership
            );
          }
        } catch (error) {
          console.error("Error fetching job service counts:", error);
        }
      }
    }

    fetchJobCounts();
  }, [selectedDealership, userId, dealershipGoals, dateRange]);

  // Handle dropdown selection
  const handleSelectionChange = (e) => {
    setSelectedDealership(e.target.value);
  };

  // Get the data to display based on selection
  const displayedGoals =
    selectedDealership === "all"
      ? totals
      : dealershipGoals.find(
          (goal) => goal.dealership_id === parseInt(selectedDealership)
        ) || {
          chips_goal: 0,
          hlr_goal: 0,
          pp_goal: 0,
          surveyed_cars: 0,
        };

  const wonDeals = Number(jobCounts.wonDeals || 0);
  const lostDeals = Number(jobCounts.lostDeals || 0);
  const newDeals = Number(jobCounts.newDeals || 0);
  const pendingDeals = Number(jobCounts.pendingDeals || 0);
  const totalDeals = wonDeals + lostDeals + newDeals + pendingDeals;

  return (
    <div className="widgets-column-container">
      <div className="widgets-left-column">
        <div className="agent-daily-figures-header">
          <h1>Agent Daily Figures</h1>
        </div>
        <div className="widget-report">
          <Link to="/pipeline">
            <div className="widget">
              <div className="widget-text">
                <h3>New Deals</h3>
                <h4>{jobCounts.newDeals || 0}</h4>
              </div>
            </div>
          </Link>
          <Link to="/pipeline">
            <div className="widget">
              <div className="widget-text">
                <h3>Pending Deals</h3>
                <h4>{jobCounts.pendingDeals || 0}</h4>
              </div>
            </div>
          </Link>
          <Link to="/pipeline">
            <div className="widget">
              <div className="widget-text">
                <h3>Won Deals</h3>
                <h4>{jobCounts.wonDeals || 0}</h4>
              </div>
            </div>
          </Link>
          <div className="widget">
            <div className="widget-text">
              <h3>Conversion Rate</h3>
              <h4>{calculateWinPercentage()}%</h4>
            </div>
          </div>
          <div className="widget">
            <div className="widget-text">
              <h3>Assigned Tasks</h3>
              <h4>{jobCounts.assignedTasks || 0}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="widgets-right-column">
        <div className="dealership-widgets-header">
          <h1>Dealership Daily Goals</h1>
          {/* Dropdown to select dealership */}
          <select onChange={handleSelectionChange} value={selectedDealership}>
            <option value="all">All Dealerships</option>
            {dealershipGoals.map((goal) => (
              <option key={goal.dealership_id} value={goal.dealership_id}>
                {goal.dealership_name || `Dealership ${goal.dealership_id}`}
              </option>
            ))}
          </select>
        </div>

        {/* Display goals */}
        <div className="widget-report">
          <div className="widget">
            <div className="widget-text">
              <h3>Surveyed Cars</h3>
              <h4>
                {jobServiceCounts.surveyedCars} / {displayedGoals.surveyed_cars}
              </h4>
            </div>
          </div>
          <div className="widget">
            <div className="widget-text">
              <h3>Chips Goal</h3>
              <h4>
                {jobServiceCounts.windscreen} / {displayedGoals.chips_goal}
              </h4>
            </div>
          </div>
          <div className="widget">
            <div className="widget-text">
              <h3>Headlight Repairs Goal</h3>
              <h4>
                {jobServiceCounts.headlight} / {displayedGoals.hlr_goal}
              </h4>
            </div>
          </div>
          <div className="widget">
            <div className="widget-text">
              <h3>Paint Protection Goal</h3>
              <h4>
                {jobServiceCounts.polish} / {displayedGoals.pp_goal}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetReport;
