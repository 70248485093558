import axios from "axios";

export const fetchAllServiceAdvisors = async () => {
  try {

    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/service-advisors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching service advisors:", error);
    throw error;
  }
};
