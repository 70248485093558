import { useState, useRef } from "react";
import { fetchCallCenterAgents } from "../utils/fetchCallCenterAgents";
import { fetchTechnicians } from "../utils/fetchTechnicians";
import { fetchDealershipGroups } from "../utils/fetchDealershipGroups";
import { fetchAllServiceAdvisors } from "../utils/fetchAllServiceAdvisors";
import fetchDealershipGoals from "../utils/fetchDealershipGoals";

export const useFetchData = () => {
  const [callCenterAgents, setCallCenterAgents] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [dealershipGroups, setDealershipGroups] = useState([]);
  const [serviceAdvisors, setServiceAdvisors] = useState([]);
  const [goals, setGoals] = useState({});
  const fetched = useRef(false);
  const fetchData = async (dealership_id) => {
    if (fetched.current) return; // Avoid duplicate fetching
    fetched.current = true;

    try {

      // Fetch all necessary data in parallel
      const [technicianData, groups, advisors] = await Promise.all([
        fetchTechnicians(),
        fetchDealershipGroups(),
        fetchAllServiceAdvisors(),
      ]);

      setTechnicians(Array.isArray(technicianData) ? technicianData : []);
      setDealershipGroups(Array.isArray(groups) ? groups : []);
      setServiceAdvisors(Array.isArray(advisors) ? advisors : []);

      // Fetch call center agents
      const agents = await fetchCallCenterAgents();
      setCallCenterAgents(Array.isArray(agents) ? agents : []);

      // Fetch dealership goals if dealership_id exists
      if (dealership_id) {
        const dealershipGoals = await fetchDealershipGoals(dealership_id);
        setGoals(dealershipGoals?.data?.[0] || {});
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const resetFetchFlag = () => {
    fetched.current = false;
  };

  return {
    callCenterAgents,
    technicians,
    dealershipGroups,
    serviceAdvisors,
    goals,
    fetchData,
    resetFetchFlag,
  };
};
