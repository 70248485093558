import axios from 'axios';
import { refreshToken, isTokenExpired } from './auth'; // Import utility functions

// Axios interceptor for token handling
axios.interceptors.request.use(async (config) => {
  let token = localStorage.getItem("token");

  if (token) {
    const isExpired = isTokenExpired(token);
    if (isExpired) {
      // If the token is expired, refresh it
      await refreshToken();
      token = localStorage.getItem("token"); // Get the updated token after refresh
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Use the valid or refreshed token
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});
