import React, { useState, useEffect } from "react";
import ModalPortal from "../ModalPortal/ModalPortal";
import "./HistoryModal.css";
import { fetchAllJobs } from "../../utils/fetchAllJobs"; // Import the fetch function

const HistoryModal = ({
  show,
  onClose,
  registrationNumber,
  historyData = [], // Ensure historyData is always an array
  columns,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [localHistoryData, setLocalHistoryData] = useState([]); // State to hold the history data

  // Helper function to format services correctly
  const formatServices = (serviceChecksJson) => {
    if (!serviceChecksJson) return "No services selected";

    const serviceChecks =
      typeof serviceChecksJson === "string"
        ? JSON.parse(serviceChecksJson)
        : serviceChecksJson;
    const formattedServices = Object.entries(serviceChecks)
      .filter(([_, value]) => value.selected) // Only include selected services
      .map(([key, value]) => `${key}: ${value.option || "Selected"}`) // Display option or just 'Selected'
      .join(", ");

    return formattedServices || "No services selected";
  };

  // Helper function to format date correctly
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    // Handle DD.MM.YYYY format
    const dateParts = dateString.split(".");
    if (dateParts.length === 3) {
      const [day, month, year] = dateParts;
      const formattedDate = `${year}-${month}-${day}`;
      const date = new Date(formattedDate);

      return isNaN(date) ? "Invalid Date" : date.toLocaleDateString();
    }

    const date = new Date(dateString);
    return isNaN(date) ? "Invalid Date" : date.toLocaleDateString();
  };

  // Function to normalize data from columns into the standardized format
  const normalizeDataFromColumns = (columns) => {
    if (!columns) return []; // Ensure columns is defined
    const allItems = Object.values(columns).flatMap((column) => column.items); // Combine items from all columns
    const filteredJobs = allItems.filter(
      (job) => job.registration === registrationNumber
    );

    return filteredJobs.map((job) => ({
      reg: job.registration || "N/A",
      make: job.vehicleMake || "N/A",
      date: job.created_at || "N/A",
      dealer: job.dealership || "N/A",
      client: `${job.firstName || "N/A"} ${job.lastName || "N/A"}`,
      tel: job.contactNumber || "N/A",
      altTel: job.alt_contactNumber || "N/A",
      services: formatServices(job.service_checks),
      insurance: job.insurance ? "Yes" : "No",
      brokerContact: job.insurance_broker || "N/A",
    }));
  };

  // Function to normalize data from API into the standardized format
  const normalizeDataFromAPI = (fetchedData) => {
    if (!fetchedData) return []; // Ensure fetchedData is defined
    return fetchedData.map((job) => ({
      reg: job.reg || "N/A",
      make: job.make || "N/A",
      date: job.date || "N/A", // Already formatted in API response
      dealer: job.dealer || "N/A",
      client: job.client || "N/A",
      tel: job.tel || "N/A",
      altTel: job.altTel || "N/A",
      services: formatServices(job.service_checks),
      insurance: job.insurance ? "Yes" : "No",
      brokerContact: job.brokerContact || "N/A",
    }));
  };

  // Fetch data if columns are not provided and use the API
  const fetchHistoryFromAPI = async () => {
    try {
      const data = await fetchAllJobs(registrationNumber);
      const normalizedData = normalizeDataFromAPI(data);
      setLocalHistoryData(normalizedData);
    } catch (error) {
      console.error("Error fetching job history:", error);
    }
  };

  // Decide which data source to use for history data
  useEffect(() => {
    if (show) {
      if (columns) {
        const historyFromColumns = normalizeDataFromColumns(columns);
        setLocalHistoryData(historyFromColumns);
      } else if (registrationNumber) {
        fetchHistoryFromAPI();
      }
    }
  }, [show, registrationNumber, columns]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  if (!show && !isClosing) return null;

  const dataToDisplay = Array.isArray(localHistoryData) && localHistoryData.length > 0 ? localHistoryData : Array.isArray(historyData) ? historyData : []; // Ensure the data is always an array

  return (
    <ModalPortal>
      <div className="overlay" onClick={handleClose}></div>
      <div className={`history-modal ${isClosing ? "slide-out" : ""}`}>
        <button onClick={handleClose} className="close-button">
          X
        </button>
        <h2>History Details</h2>
        <div className="table-container">
          {dataToDisplay.length === 0 ? (
            <p>No history available for this registration number.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>REG</th>
                  <th>MAKE</th>
                  <th>DATE</th>
                  <th>DEALER</th>
                  <th>CLIENT</th>
                  <th>TEL</th>
                  <th>ALT TEL</th>
                  <th>SERVICES</th>
                  <th>INSURANCE</th>
                  <th>BROKER CONTACT</th>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay.map((row, index) => (
                  <tr key={index}>
                    <td>{row.reg}</td>
                    <td>{row.make}</td>
                    <td>{formatDate(row.date)}</td>
                    <td>{row.dealer}</td>
                    <td>{row.client}</td>
                    <td>{row.tel}</td>
                    <td>{row.altTel}</td>
                    <td>{row.services}</td>
                    <td>{row.insurance}</td>
                    <td>{row.brokerContact}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </ModalPortal>
  );
};

export default HistoryModal;
