import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TaskModal.css";
import { addCustomTag } from "../../utils/addCustomTag";
import { fetchTags } from "../../utils/fetchTags";
import { fetchTagVariants } from "../../utils/fetchTagVariants";
import { deleteTask } from "../../utils/deleteTask";
import { completeTask } from "../../utils/completeTask";
import { createTask } from "../../utils/createTask";
import { updateTask } from "../../utils/updateTask";
import { fetchCallCenterAgents } from "../../utils/fetchCallCenterAgents";
import { notifyTaskAssignment } from "../../utils/notifyTaskAssignment";

const TaskModal = ({
  onClose,
  task,
  isNewTask,
  onComplete,
  registrationNumber,
}) => {
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [dueTime, setDueTime] = useState("");
  const [vehicleRegistration, setVehicleRegistration] = useState(
    registrationNumber || ""
  );
  const [availableTags, setAvailableTags] = useState([]);
  const [availableVariants, setAvailableVariants] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [taskUserAssigned, setTaskUserAssigned] = useState(null);
  const [customTagName, setCustomTagName] = useState("");
  const [customTagColor, setCustomTagColor] = useState("#000000");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callCenterAgents, setCallCenterAgents] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");

  // Fetch initial data (tags, call center agents, and user info)
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setTaskUserAssigned(user.id);
    }

    fetchTags(setAvailableTags);
    fetchTagVariants(setAvailableVariants);
    fetchCallCenterAgents(setCallCenterAgents);
  }, []);

  // Populate form with task data when editing an existing task
  useEffect(() => {
    if (task) {
      setTaskTitle(task.task_title || "");
      setTaskDescription(task.task_description || "");
      setDueDate(
        task.task_due_date ? new Date(task.task_due_date) : new Date()
      );
      setDueTime(task.task_due_time || "");
      setVehicleRegistration(
        registrationNumber || task.task_registration_number || ""
      );
      setSelectedTags(Array.isArray(task.task_tags) ? task.task_tags : []);
    }
  }, [task, registrationNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (!taskTitle || !taskDescription || !dueDate || !dueTime) {
      toast.error("Please fill out all required fields.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastClassName: "centered-toast",
      });
      setIsSubmitting(false);
      return;
    }

    const taskData = {
      task_title: taskTitle,
      task_description: taskDescription,
      task_due_date: dueDate.toLocaleDateString("en-CA"),
      task_due_time: dueTime,
      task_registration_number: vehicleRegistration,
      task_tags: selectedTags,
      task_status: task ? task.task_status : "incomplete",
      task_user_assigned: selectedAgentId || taskUserAssigned,
    };

    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const senderId = user?.id;

      if (isNewTask) {
        await createTask(taskData);

        if (selectedAgentId && selectedAgentId !== taskUserAssigned) {
          await notifyTaskAssignment(senderId, selectedAgentId, taskTitle);
        }

        toast.success("Task created successfully!");
      } else {
        await updateTask(task.task_id, taskData);

        if (selectedAgentId && selectedAgentId !== taskUserAssigned) {
          await notifyTaskAssignment(senderId, selectedAgentId, taskTitle);
        }

        toast.success("Task updated successfully!");
      }

      onClose();
    } catch (error) {
      toast.error("Error saving task");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (!isNewTask && task) {
      try {
        await deleteTask(task.task_id);
        toast.success("Task deleted successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastClassName: "centered-toast",
        });
        setShowConfirmDelete(false);
        onClose();
      } catch (error) {
        toast.error("Error deleting task", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastClassName: "centered-toast",
        });
      }
    }
  };

  const handleDelete = () => {
    setShowConfirmDelete(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const handleCompleteTask = async () => {
    try {
      const completeTaskData = {
        ...task,
        task_status: "complete",
      };
      await completeTask(completeTaskData);

      toast.success("Task marked as complete!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastClassName: "centered-toast",
      });

      if (onComplete) {
        onComplete(task.task_id);
      }

      onClose();
    } catch (error) {
      toast.error("Error marking task as complete.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastClassName: "centered-toast",
      });
    }
  };

  const handleAddTag = (tag) => {
    if (!selectedTags.some((t) => t.name === tag.name)) {
      setSelectedTags((prevTags) => [...prevTags, { ...tag }]);
    }
  };

  const handleRemoveTag = (tagName) => {
    setSelectedTags((prevTags) =>
      prevTags.filter((tag) => tag.name !== tagName)
    );
  };

  const handleTagColorChange = (tagName, color) => {
    setSelectedTags((prevTags) =>
      prevTags.map((tag) => (tag.name === tagName ? { ...tag, color } : tag))
    );
  };

  const handleAddCustomTag = () => {
    addCustomTag(
      customTagName,
      customTagColor,
      availableVariants,
      setAvailableVariants,
      fetchTagVariants,
      setCustomTagName,
      setCustomTagColor
    );
  };

  const handleSelectVariant = (e) => {
    setSelectedVariant(e.target.value);
  };

  const handleAddVariant = async () => {
    if (selectedVariant) {
      const variant = availableVariants.find(
        (variant) => variant.tag_id === parseInt(selectedVariant)
      );
      if (variant) {
        handleAddTag({ name: variant.variant_name, color: variant.color });
      }
    }
  };

  const systemTags = availableTags.filter((tag) => tag.type === "system");
  const customTags = availableTags.filter((tag) => tag.type === "custom");

  return ReactDOM.createPortal(
    <div className="task-modal">
      <ToastContainer />
      <h3>{isNewTask ? "Add A New Task" : "Edit An Existing Task"}</h3>
      <div className="task-modal-input-fields">
        <div className="task-left-column">
          <div className="form-item">
            <label className="task-form-label">Task Title</label>
            <input
              type="text"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              placeholder="Enter task title..."
              required
            />
          </div>

          <div className="form-item">
            <label className="task-form-label">Task Description</label>
            <textarea
              value={taskDescription}
              onChange={(e) => setTaskDescription(e.target.value)}
              placeholder="Describe the task..."
              required
            />
          </div>
          <div className="form-item">
            <label className="task-form-label">Vehicle Registration</label>
            <input
              type="text"
              value={vehicleRegistration}
              onChange={(e) =>
                setVehicleRegistration(e.target.value.toUpperCase())
              }
              placeholder="Vehicle registration (optional)..."
            />
          </div>

          <div className="form-item">
            <label className="task-form-label">Due Date</label>
            <DatePicker
              selected={dueDate}
              onChange={setDueDate}
              dateFormat="yyyy-MM-dd"
              required
            />
          </div>

          <div className="form-item">
            <label className="task-form-label">Time Due</label>
            <input
              type="time"
              value={dueTime}
              onChange={(e) => setDueTime(e.target.value)}
              placeholder="Due time"
              required
            />
          </div>

          {(JSON.parse(localStorage.getItem("user"))?.role === "admin" ||
            JSON.parse(localStorage.getItem("user"))?.role ===
              "management") && (
            <div className="form-item">
              <label className="task-form-label">
                Assign to Call Center Agent
              </label>
              <select
                value={selectedAgentId}
                onChange={(e) => setSelectedAgentId(e.target.value)}
                className="agent-drop-down"
              >
                <option value="">Select an agent</option>
                {callCenterAgents.map((agent) => (
                  <option key={agent.user_id} value={agent.user_id}>
                    {agent.user_first_name} {agent.user_last_name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className="task-right-column">
          <div className="tags-container">
            <div className="standard-tags">
              <h3>Standard Tags</h3>
              {systemTags.map((tag) => (
                <div key={tag.id} className="tag-item">
                  <button
                    className="tag-selector-btn"
                    onClick={() => handleAddTag(tag)}
                  >
                    {tag.name}
                  </button>
                  <input
                    type="color"
                    value={
                      selectedTags.find((t) => t.name === tag.name)?.color ||
                      tag.color
                    }
                    onChange={(e) =>
                      handleTagColorChange(tag.name, e.target.value)
                    }
                  />
                </div>
              ))}
            </div>

            <div className="custom-tags">
              <h3>Custom Tags</h3>
              <div className="custom-tag-row">
                <input
                  type="text"
                  value={customTagName}
                  onChange={(e) => setCustomTagName(e.target.value)}
                  placeholder="Tag name"
                  className="tag-drop-down"
                />
                <input
                  type="color"
                  value={customTagColor}
                  onChange={(e) => setCustomTagColor(e.target.value)}
                  className="color-selector"
                />
                <button className="tag-add-btn" onClick={handleAddCustomTag}>
                  Add Tag
                </button>
              </div>

              <div className="custom-tag-row">
                <select
                  value={selectedVariant}
                  onChange={handleSelectVariant}
                  className="variant-drop-down"
                >
                  <option value="">Select Variant</option>
                  {Array.isArray(availableVariants) &&
                    availableVariants.length > 0 &&
                    availableVariants.map((variant) => (
                      <option key={variant.tag_id} value={variant.tag_id}>
                        {variant.variant_name}
                      </option>
                    ))}
                </select>
                <button className="tag-add-btn" onClick={handleAddVariant}>
                  Add Variant
                </button>
              </div>
            </div>
          </div>

          <h3>Selected Tags</h3>
          <div className="selected-tags-container">
            <div className="selected-tags">
              {selectedTags.map((tag) => (
                <span
                  key={tag.id}
                  className="selected-tag-item"
                  style={{ backgroundColor: tag.color }}
                >
                  {tag.name}
                  <button
                    className="remove-tag-btn"
                    onClick={() => handleRemoveTag(tag.name)}
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div className="modal-actions">
            <button
              onClick={handleCompleteTask}
              className="task-modal-complete-button"
            >
              Complete
            </button>
            <button onClick={handleSubmit} className="task-modal-save-button">
              Save
            </button>
            <button onClick={onClose} className="task-modal-close-button">
              Cancel
            </button>
            <button onClick={handleDelete} className="task-modal-delete-button">
              Delete
            </button>
          </div>
        </div>
      </div>

      {showConfirmDelete && (
        <div className="delete-confirmation-modal">
          <div className="delete-confirmation-content">
            <h4>Are you sure you want to delete this task?</h4>
            <div className="delete-confirmation-actions">
              <button
                onClick={handleConfirmDelete}
                className="confirm-delete-btn"
              >
                Yes
              </button>
              <button
                onClick={handleCancelDelete}
                className="cancel-delete-btn"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>,
    document.getElementById("modal-root")
  );
};

export default TaskModal;
