import React, { useState } from "react";
import "./PipelineGridView.css";

export const PipelineGridView = ({ columns, onRowClick }) => {
  const [filters, setFilters] = useState({
    subStatus: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    dealership: ""
  });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [globalSearch, setGlobalSearch] = useState("");

  const handleFilterChange = (column, value) => {
    setFilters({
      ...filters,
      [column]: value
    });
  };

  const getUniqueValues = (key) => {
    const values = Object.values(columns)
      .flatMap((column) => column.items.map((item) => item[key]));
    return [...new Set(values)];
  };

  const filteredItems = Object.values(columns).flatMap((column) =>
    column.items.filter((item) => {
      return (
        (!filters.subStatus || item.subStatus === filters.subStatus) &&
        (!filters.firstName || item.firstName === filters.firstName) &&
        (!filters.lastName || item.lastName === filters.lastName) &&
        (!filters.contactNumber || item.contactNumber === filters.contactNumber) &&
        (!filters.dealership || item.dealership === filters.dealership) &&
        (globalSearch === "" || 
          Object.values(item).some(value => 
            value && value.toString().toLowerCase().includes(globalSearch.toLowerCase()) // Add null/undefined check
          ))
      );
    })
  );
  

  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }
      const aLower = aValue.toLowerCase();
      const bLower = bValue.toLowerCase();
      if (aLower < bLower) return sortConfig.direction === "asc" ? -1 : 1;
      if (aLower > bLower) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="pipeline-gridview-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search..."
          value={globalSearch}
          onChange={(e) => setGlobalSearch(e.target.value)}
        />
      </div>
      <table className="pipeline-gridview-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("id")}>Job ID</th>
            <th onClick={() => handleSort("registration")}>Registration Number</th>
            <th>
              Sub Status
              <select
                value={filters.subStatus}
                onChange={(e) => handleFilterChange("subStatus", e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValues("subStatus").map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </th>
            <th>
              First Name
              <select
                value={filters.firstName}
                onChange={(e) => handleFilterChange("firstName", e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValues("firstName").map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </th>
            <th>
              Last Name
              <select
                value={filters.lastName}
                onChange={(e) => handleFilterChange("lastName", e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValues("lastName").map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </th>
            <th>
              Number
              <select
                value={filters.contactNumber}
                onChange={(e) => handleFilterChange("contactNumber", e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValues("contactNumber").map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </th>
            <th>
              Dealership
              <select
                value={filters.dealership}
                onChange={(e) => handleFilterChange("dealership", e.target.value)}
              >
                <option value="">All</option>
                {getUniqueValues("dealership").map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item) => (
            <tr key={item.id} onClick={() => onRowClick(item)}>
              <td>{item.id}</td>
              <td>{item.registration}</td>
              <td>{item.subStatus}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.contactNumber}</td>
              <td>{item.dealership}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
