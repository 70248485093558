import React, { useState, useEffect } from "react";
import { fetchImageGallery } from "../../utils/fetchImageGallery";
import ImageModal from "../ImageModal/ImageModal";
import "./ImageGallery.css";

const ImageGallery = ({ registrationNumber }) => {
  const [vehicleImages, setVehicleImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await fetchImageGallery(registrationNumber);
      setVehicleImages(images);
    };

    if (registrationNumber) {
      fetchImages();
    }
  }, [registrationNumber]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <div className="image-gallery">
      {vehicleImages.length > 0 ? (
        vehicleImages.map((image, index) => (
          <img
            key={index}
            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${image.image_path}`}
            alt={`Vehicle ${index}`}
            crossOrigin="anonymous"
            onClick={() => handleImageClick(`${process.env.REACT_APP_MEDIA_BASE_URL}${image.image_path}`)}
          />
        ))
      ) : (
        <p>No vehicle images found</p>
      )}

      {/* Modal for viewing the full-sized image */}
      <ImageModal show={showModal} onClose={handleCloseModal} imageUrl={selectedImage} />
    </div>
  );
};

export default ImageGallery;
