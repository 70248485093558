import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { fetchInvoices } from "../../utils/fetchInvoices";
import { fetchInvoiceDetails } from "../../utils/fetchInvoiceDetails";
import { fetchCompanyDetails } from "../../utils/fetchCompanyDetails"; // Import function to fetch company details
import { generateInvoiceHTML } from "../../utils/generateInvoice";

import "./Invoicing.css";

const Invoicing = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null); // State for company details
  const [activeTab, setActiveTab] = useState("Pending Allocation");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load invoices and company details initially
  useEffect(() => {
    const loadInvoicesAndCompanyDetails = async () => {
      try {
        const [invoices, company] = await Promise.all([
          fetchInvoices(),
          fetchCompanyDetails(), // Fetch the company details
        ]);
        setInvoiceData(invoices);
        setCompanyDetails(company); // Set company details state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadInvoicesAndCompanyDetails();
  }, []);

  // Handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Filter invoices based on active tab
  const filterInvoices = () => {
    if (activeTab === "Pending Allocation") {
      return invoiceData.filter((invoice) => !invoice.loaded);
    } else if (activeTab === "Allocation Complete") {
      return invoiceData.filter((invoice) => invoice.loaded);
    }
    return invoiceData;
  };

  // Handle viewing an invoice
  const handleViewInvoice = async (invoiceId) => {
    try {
      const invoiceDetails = await fetchInvoiceDetails(invoiceId);
      console.log("Viewing detailed invoice:", invoiceDetails); // Log detailed invoice
      generateInvoiceHTML(invoiceDetails, companyDetails); // Pass full invoice details and company details
    } catch (error) {
      console.error("Error viewing invoice:", error);
    }
  };

  // Handle checkbox change to update loaded status
  const handleLoadedChange = (invoiceId, isChecked) => {
    setInvoiceData((prevInvoices) =>
      prevInvoices.map((invoice) =>
        invoice.invoice_id === invoiceId
          ? { ...invoice, loaded: isChecked }
          : invoice
      )
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="invoicing-container">
      <Header />
      <div className="invoicing-page-content">
        <Sidebar />
        <main className="invoicing-main-content">
          <h2>Invoices</h2>
          <div className="tabs">
            <button
              className={`tab ${
                activeTab === "Pending Allocation" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("Pending Allocation")}
            >
              Pending Allocation
            </button>
            <button
              className={`tab ${
                activeTab === "Allocation Complete" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("Allocation Complete")}
            >
              Allocation Complete
            </button>
            <button
              className={`tab ${
                activeTab === "All Invoices" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("All Invoices")}
            >
              All Invoices
            </button>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Registration Number</th>
                  <th>Dealership</th>
                  <th>Total</th>
                  <th>View</th>
                  <th>Loaded</th>
                </tr>
              </thead>
              <tbody>
                {filterInvoices().map((invoice, index) => (
                  <tr key={index}>
                    <td>{invoice.invoice_id}</td>
                    <td>{invoice.vehicle_registration}</td>
                    <td>{invoice.dealership_id}</td>
                    <td>{invoice.total_amount}</td>
                    <td className="view-icon">
                      <button
                        onClick={() => handleViewInvoice(invoice.invoice_id)}
                      >
                        <span className="material-symbols-outlined">
                          visibility
                        </span>
                      </button>
                    </td>
                    {/* Checkbox for Loaded status */}
                    <td className="loaded-checkbox">
                      <input
                        type="checkbox"
                        checked={invoice.loaded}
                        onChange={(e) =>
                          handleLoadedChange(invoice.invoice_id, e.target.checked)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Invoicing;
