import React, { useState, useEffect } from "react";
import axios from "axios";
import { format, startOfDay, endOfDay } from "date-fns"; // Import date-fns functions
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import WidgetReport from "../components/WidgetReport/WidgetReport";
import TaskDiaryTable from "../components/TaskDiaryTable/TaskDiaryTable";
import RangeDatePicker from "../components/CustomDatePicker/CustomDatePicker";
import GoalsModal from "../components/GoalsModal/GoalsModal";
import { updateActivityStatus } from "../utils/updateActivityStatus";
import { fetchJobCounts } from "../utils/fetchJobCounts"; // Correct named import
import "./Dashboard.css";

const Dashboard = () => {
  const [isGoalsModalOpen, setGoalsModalOpen] = useState(false);
  const [assignedDealerships, setAssignedDealerships] = useState([]);
  const openGoalsModal = () => setGoalsModalOpen(true);
  const closeGoalsModal = () => setGoalsModalOpen(false);

  const [jobCounts, setJobCounts] = useState({
    pendingDeals: 0,
    wonDeals: 0,
    lostDeals: 0,
    newDeals: 0,
    assignedTasks: 0,
  });

  const [dateRange, setDateRange] = useState({
    startDate: format(startOfDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
  });

  const user = JSON.parse(localStorage.getItem("user")); // Get the user from localStorage

  // Fetch job counts and assigned tasks on date range change or mount
  useEffect(() => {
    if (user && user.id) {
      fetchJobCounts(dateRange, user.id, setJobCounts); // Pass user.id and setJobCounts to the function
      fetchAssignedTasksCount(dateRange, user.id); // Also call fetchAssignedTasksCount with the updated range
    }
  }, [dateRange, user.id]); // Re-run when dateRange or user.id changes

  useEffect(() => {
    const dealerships = JSON.parse(localStorage.getItem("assignedDealerships")) || [];
    console.log('Dealerships: ', dealerships);
    setAssignedDealerships(dealerships);
  }, []);

  // Update the user's activity status to "online" when the dashboard is mounted
  useEffect(() => {
    if (user && user.id) {
      updateActivityStatus(user.id, "online"); // Set status to 'online'
    }

    // Set status to 'offline' when the component unmounts
    return () => {
      if (user && user.id) {
        updateActivityStatus(user.id, "offline"); // Set status to 'offline'
      }
    };
  }, [user]);

  const fetchAssignedTasksCount = async (range, userId) => {
    if (userId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/tasks/filtered`,
          {
            params: {
              user_id: userId,
              status: "incomplete",
              startDate: range.startDate,
              endDate: range.endDate,
            },
          }
        );
        if (response.data) {
          setJobCounts((prevCounts) => ({
            ...prevCounts,
            assignedTasks: response.data.length,
          }));
        }
      } catch (error) {
        console.error("Error fetching assigned tasks:", error);
      }
    }
  };

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <main className="dashboard-main-content">
          <div className="dashboard-page-header">
            <RangeDatePicker setDateRange={setDateRange} /> {/* Pass setDateRange */}
            <button className="how-am-i-doing" onClick={openGoalsModal}>
              How Am I Doing
            </button>
            <GoalsModal isOpen={isGoalsModalOpen} onClose={closeGoalsModal} />
          </div>
          <WidgetReport jobCounts={jobCounts} userId={user.id} dateRange={dateRange} /> {/* Pass userId and dateRange */}
          <TaskDiaryTable
            onTaskUpdated={() => fetchAssignedTasksCount(dateRange, user.id)}
          />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
