import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TaskDiaryTable.css";
import TaskModal from "../TaskModal/TaskModal";
import { parseISO, format, isValid } from 'date-fns';

const TaskDiaryTable = ({ onTaskUpdated }) => {  // onTaskUpdated is passed as a prop
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [currentTab, setCurrentTab] = useState('incomplete');

  useEffect(() => {
    fetchTasks();
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModalOpen, currentTab]);

  const fetchTasks = async () => {  // No destructuring needed here
    try {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user')); // Get user from localStorage
  
      if (user && user.id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/tasks/filtered`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              user_id: user.id,  // Pass the user ID in the query params
              status: currentTab === 'all' ? '' : currentTab,  // Set status based on the current tab
            }
          }
        );
        const fetchedTasks = response.data.map(task => ({
          ...task,
          task_tags: task.task_tags || []  // Ensure task_tags is handled properly
        }));
        setTasks(fetchedTasks);

        // Call onTaskUpdated prop if it's provided
        if (typeof onTaskUpdated === 'function') {
          onTaskUpdated();
        }
      } else {
        console.error('No user ID found in local storage');
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Function to handle task completion from TaskModal
  const handleTaskCompletion = (taskId) => {
    setTasks(prevTasks => prevTasks.map(task => 
      task.task_id === taskId ? { ...task, task_status: 'complete' } : task
    ));
    fetchTasks(); // Ensure the task list is updated
  };

  const selectTask = (task) => {
    setSelectedTask(task);
    toggleModal();
  };

  const toggleTaskCompletion = async (task) => {
    const updatedTasks = tasks.map((t) =>
      t.task_id === task.task_id ? { ...t, task_status: t.task_status === 'complete' ? 'incomplete' : 'complete' } : t
    );
    setTasks(updatedTasks);  // Optimistically update the tasks
  
    try {
      const token = localStorage.getItem('token');
      const updatedTask = updatedTasks.find(t => t.task_id === task.task_id);
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/tasks/${task.task_id}`, updatedTask, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Fetch the tasks again after the status update
      await fetchTasks();  // Re-fetch the tasks after updating the status
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const filteredTasks = tasks.filter(task => {
    if (currentTab === 'incomplete') return task.task_status === 'incomplete';
    if (currentTab === 'complete') return task.task_status === 'complete';
    return true;
  }).sort((a, b) => {
    const dateA = parseISO(a.task_due_date);
    const dateB = parseISO(b.task_due_date);
    return dateA - dateB;
  });

  return (
    <div className="task-diary-container">
      {isModalOpen && <div className="overlay" />}
      {isModalOpen && (
        <TaskModal
          onClose={toggleModal}
          task={selectedTask}
          onComplete={handleTaskCompletion}
          isNewTask={!selectedTask}
        />
      )}
      <div className="tabs task-tabs">
        <button className={currentTab === 'incomplete' ? 'active' : ''} onClick={() => setCurrentTab('incomplete')}>
          Current Tasks
        </button>
        <button className={currentTab === 'complete' ? 'active' : ''} onClick={() => setCurrentTab('complete')}>
          Completed Tasks
        </button>
        <button className={currentTab === 'all' ? 'active' : ''} onClick={() => setCurrentTab('all')}>
          All Tasks
        </button>
        <button className={currentTab === 'new' ? 'active' : ''} onClick={() => {
          setSelectedTask(null);
          toggleModal();
        }}>
          New Task
        </button>
      </div>
      <div className="task-table-container">
        <table className="task-list">
          <thead>
            <tr>
              <th>Task</th>
              <th>Due Date</th>
              <th>Due Time</th>
              <th>Vehicle Registration</th>
              <th>Tags</th>
              <th>Completed</th>
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map((task) => {
              const dueDate = parseISO(task.task_due_date);
              const formattedDueDate = isValid(dueDate) ? format(dueDate, 'yyyy-MM-dd') : 'Invalid date';

              return (
                <tr key={task.task_id}>
                  <td onClick={() => selectTask(task)}>{task.task_title}</td>
                  <td onClick={() => selectTask(task)}>{formattedDueDate}</td>
                  <td onClick={() => selectTask(task)}>{task.task_due_time}</td>
                  <td onClick={() => selectTask(task)}>{task.task_registration_number}</td>
                  <td onClick={() => selectTask(task)}>
                    <div className="tag-container">
                      {task.task_tags.map((tag) => (
                        <span
                          key={tag.name}
                          className="tag"
                          style={{
                            backgroundColor: tag.color,
                          }}
                        >
                          {tag.name}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="checkbox-actions">
                    <input
                      type="checkbox"
                      checked={task.task_status === 'complete'}
                      onChange={(e) => {
                        e.stopPropagation();
                        toggleTaskCompletion(task);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskDiaryTable;
