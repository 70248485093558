// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .confirm-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-button {
    background-color: grey;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ConfirmationModal/ConfirmationModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".modal {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .modal-content {\r\n    background: white;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    text-align: center;\r\n  }\r\n  \r\n  .button-group {\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 10px;\r\n  }\r\n  \r\n  .confirm-button {\r\n    background-color: red;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .cancel-button {\r\n    background-color: grey;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
