//utils/createNotification.js
import axios from 'axios';

export async function createNotification(notificationData) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notifications`, notificationData);
        return response.data;
    } catch (error) {
        console.error('Error creating notification:', error);
        throw error;
    }
}
