import axios from 'axios';

export const searchJobs = async (query) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/jobs`, {
      params: { q: query }, // `q` will hold the search query
    });
    return response.data;
  } catch (error) {
    console.error("Error searching jobs:", error);
    return [];
  }
};
