// addCustomTag.js

import axios from "axios";

export const addCustomTag = async (
  customTagName,
  customTagColor,
  availableVariants,
  setAvailableVariants,
  fetchTagVariants,
  setCustomTagName,
  setCustomTagColor // Ensure this is a valid function passed correctly
) => {
  if (customTagName) {
    const existingVariant = availableVariants.find(
      (variant) =>
        variant.variant_name &&
        variant.variant_name.toLowerCase() === customTagName.toLowerCase()
    );

    if (existingVariant) {
      // Variant exists
      return;
    } else {
      // Create new custom tag variant
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_TAG_VARIANTS_URL,
          {
            variant_name: customTagName,
            color: customTagColor,
          }
        );

        const newVariant = response.data;

        // Update the available variants
        setAvailableVariants((prevVariants) => [...prevVariants, newVariant]);

        // Reset the custom tag fields
        setCustomTagName("");  // Reset the name
        setCustomTagColor("#000000");  // Reset the color to a default value
        await fetchTagVariants(setAvailableVariants);
      } catch (error) {
        console.error("Error adding custom tag variant:", error);
      }
    }
  }
};
