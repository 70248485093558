import axios from 'axios';

// Function to fetch job history by registration number
export const fetchAllJobs = async (registrationNumber) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/jobs/registration/${registrationNumber}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job history:", error);
    throw error;
  }
};
