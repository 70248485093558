// /src/utils/fetchInvoices.js
export const fetchInvoices = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/invoices`);
      const data = await response.json();
      
      if (data.success) {
        return data.data;  // Assuming 'data.data' holds the array of invoices
      } else {
        throw new Error(data.message || 'Failed to fetch invoices');
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
      throw error;
    }
  };
  