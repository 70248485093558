import React, { useState } from "react";
import "./StatusFilter.css";
import { createVehicleLogEntry } from "../../utils/createVehicleLogEntry";
import { createNotification } from "../../utils/createNotification"; // Import createNotification

const StatusFilter = ({ onInsuranceSelect, onStatusChange, registrationNumber, userId, jobId }) => { // Add jobId as a prop
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [notificationMessage, setNotificationMessage] = useState(""); // State to hold custom message

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const logStatusChange = async (newStatus) => {
    const logData = {
      registration_number: registrationNumber,
      user_id: userId,
      activity_title: "Status Change",
      activity_description: `Status changed to ${newStatus}`,
    };

    try {
      await createVehicleLogEntry(logData); // Call log entry function
    } catch (error) {
      console.error("Error logging status change:", error);
    }
  };

  // Handle showing the modal for the DND option
  const handleDNDSelect = () => {
    setShowModal(true); // Show modal
    setIsDropdownOpen(false);
    setSelectedOption("DND"); // Set DND as the selected option but don't change the status yet
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setNotificationMessage(""); // Clear the message
  };

  // Handle notification submission
  const handleNotificationSubmit = async () => {
    const notificationData = {
      sender_id: userId,
      recipient_id: 3, // Hardcoded for now Janice Weakly
      notification_title: "DND Approval Required",
      notification_content: `Job ID: ${jobId} - Registration number: ${registrationNumber} - ${notificationMessage}`, // Include jobId in the message
      notification_status: "unread",
    };

    try {
      // Update the status only when submitting the DND request
      await onStatusChange("DND"); // Only update the status here, after the submit
      await logStatusChange("DND");

      await createNotification(notificationData); // Create notification
      console.log("DND notification sent.");
    } catch (error) {
      console.error("Error creating notification:", error);
    }

    handleCloseModal(); // Close modal after submission
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    if (option === "Insurance") {
      onStatusChange("Pending", "");
      onInsuranceSelect();
      logStatusChange("Insurance");
    } else if (option === "DND") {
      handleDNDSelect(); // Show the modal without changing the status
    } else if (["Account", "EFT", "DNC", "No", "Pending"].includes(option)) {
      onStatusChange(option);
      logStatusChange(option);
    } else {
      onStatusChange("Pending", option);
    }
  };

  return (
    <div className="status-filter">
      <h2>Status</h2>
      <div className="status-filters">
        <a href="#" className={`status-filter-link ${selectedOption === "Account" ? "active" : ""}`} onClick={() => handleOptionSelect("Account")}>Account</a>
        <a href="#" className={`status-filter-link ${selectedOption === "Insurance" ? "active" : ""}`} onClick={() => handleOptionSelect("Insurance")}>Insurance</a>
        <a href="#" className={`status-filter-link ${selectedOption === "EFT" ? "active" : ""}`} onClick={() => handleOptionSelect("EFT")}>EFT</a>
        <div
          className="status-filter-dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a href="#" className={`status-filter-link ${selectedOption === "Pending" ? "active" : ""}`}>Pending</a>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <a
                href="#"
                className={`dropdown-item ${selectedOption === "NA" ? "active" : ""}`}
                onClick={() => handleOptionSelect("NA")}
              >
                NA
              </a>
              <a
                href="#"
                className={`dropdown-item ${selectedOption === "TCB" ? "active" : ""}`}
                onClick={() => handleOptionSelect("TCB")}
              >
                TCB
              </a>
              <a
                href="#"
                className={`dropdown-item ${selectedOption === "WN" ? "active" : ""}`}
                onClick={() => handleOptionSelect("WN")}
              >
                WN
              </a>
            </div>
          )}
        </div>
        <a href="#" className={`status-filter-link ${selectedOption === "DND" ? "active" : ""}`} onClick={handleDNDSelect}>DND</a>
        <a href="#" className={`status-filter-link ${selectedOption === "DNC" ? "active" : ""}`} onClick={() => handleOptionSelect("DNC")}>DNC</a>
        <a href="#" className={`status-filter-link ${selectedOption === "No" ? "active" : ""}`} onClick={() => handleOptionSelect("No")}>No</a>
      </div>

      {/* Modal for DND notification */}
      {showModal && (
        <div className="dnd-modal">
          <div className="dnd-modal-content">
            <h3 className="dnd-h3">DND Notification</h3>
            <p><strong>Registration Number:</strong> <em>{registrationNumber}</em></p>
            <p><strong>Job ID:</strong> <em>{jobId}</em></p> {/* Display jobId in the modal */}
            <textarea className="dnd-textarea"
              placeholder="Enter a message for the notification..."
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
            <div className="dnd-modal-actions">
              <button className="dnd-button" onClick={handleNotificationSubmit}>Submit</button>
              <button className="dnd-button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusFilter;
