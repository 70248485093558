import React, { useState, useEffect } from "react";
import { fetchDealershipGroup } from "../../utils/fetchDealershipGroup";
import { fetchBasePricing } from "../../utils/fetchBasePricing";
import { getPricing } from "../../utils/getPricing"; // Import the pricing service
import "./DealDetails.css";

const DealDetails = ({ contact, onServiceChange, onContactChange }) => {
  const [dealershipGroup, setDealershipGroup] = useState("Loading...");
  const [editableContact, setEditableContact] = useState({ ...contact });
  const [basePricing, setBasePricing] = useState([]);
  const [pricing, setPricing] = useState({});

  let serviceChecks = {};
  console.log('Contact being passed: ', contact)

  try {
    serviceChecks = contact.service_checks
      ? JSON.parse(contact.service_checks)
      : {};
  } catch (error) {
    console.error("Error parsing service_checks JSON:", error);
    serviceChecks = {};
  }

  useEffect(() => {
    if (contact.dealership) {
      fetchDealershipGroup(contact.dealership).then((group) =>
        setDealershipGroup(group)
      );
    }
  }, [contact.dealership]);

  useEffect(() => {
    setEditableContact({ ...contact });
  }, [contact]);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const pricingData = await fetchBasePricing();
        setBasePricing(pricingData);
        console.log("Fetched Pricing:", pricingData);
      } catch (error) {
        console.error("Error fetching base pricing:", error);
      }
    };
    fetchPricing();
  }, []);

  const handleEditableChange = (field, value) => {
    const updatedContact = { ...editableContact, [field]: value };
    setEditableContact(updatedContact); // Update local state
    onContactChange(updatedContact); // Inform parent of the change
  };
  

  useEffect(() => {
    const updatePricing = () => {
      const selectedOptions = {
        windscreen: {
          package1: !!serviceChecks.windscreen?.package1,
          package2: !!serviceChecks.windscreen?.package2,
        },
        headlight: {
          selected: !!serviceChecks.headlight?.selected,
        },
        polish: {
          option: serviceChecks.polish?.option || "",
          package1: !!serviceChecks.polish?.package1,
          package2: !!serviceChecks.polish?.package2,
        },
      };

      const calculatedPricing = getPricing(basePricing, selectedOptions);
      setPricing(calculatedPricing);
    };

    // Only update pricing if basePricing has loaded
    if (basePricing.length > 0) {
      updatePricing();
    }
  }, [basePricing]); // Removed dependency on serviceChecks

  const handleCheckboxChange = (service, checked, packageType = null) => {
    let updatedService = { ...serviceChecks[service], selected: checked };

    // If the main checkbox is unchecked, reset all related options
    if (!checked) {
      updatedService = {
        selected: false,
        package1: false,
        package2: false,
        option: "",
      };
    } else if (packageType) {
      // If a specific package is being toggled (radio button logic)
      updatedService = {
        ...updatedService,
        package1: packageType === "package1",
        package2: packageType === "package2",
      };
    }

    // Update serviceChecks for this specific service
    const updatedServiceChecks = {
      ...serviceChecks,
      [service]: updatedService,
    };

    // Update the parent state for service checks
    onServiceChange(service, updatedService);

    // Recalculate pricing with the updated service checks
    const selectedOptions = {
      windscreen: {
        package1: !!updatedServiceChecks.windscreen?.package1,
        package2: !!updatedServiceChecks.windscreen?.package2,
      },
      headlight: {
        selected: !!updatedServiceChecks.headlight?.selected,
      },
      polish: {
        option: updatedServiceChecks.polish?.option || "",
        package1: !!updatedServiceChecks.polish?.package1,
        package2: !!updatedServiceChecks.polish?.package2,
      },
    };

    const calculatedPricing = getPricing(basePricing, selectedOptions);
    setPricing(calculatedPricing);
  };

  const handleSelectChange = (service, option) => {
    const updatedService = { ...serviceChecks[service], option };

    // Update serviceChecks for this specific service
    const updatedServiceChecks = {
      ...serviceChecks,
      [service]: updatedService,
    };

    // Update the parent state for service checks
    onServiceChange(service, updatedService);

    // Recalculate pricing with the updated service checks
    const selectedOptions = {
      windscreen: {
        package1: !!updatedServiceChecks.windscreen?.package1,
        package2: !!updatedServiceChecks.windscreen?.package2,
      },
      headlight: {
        selected: !!updatedServiceChecks.headlight?.selected,
      },
      polish: {
        option: updatedServiceChecks.polish?.option || "",
        package1: !!updatedServiceChecks.polish?.package1,
        package2: !!updatedServiceChecks.polish?.package2,
      },
    };

    const calculatedPricing = getPricing(basePricing, selectedOptions);
    setPricing(calculatedPricing);
  };

  return (
    <div className="service-details-container">
      <div className="form-container">
        <h2>Contact Information</h2>
        <table className="contact-details-table">
          <tbody>
            <tr>
              <td>
                <span>
                  <strong>Name:</strong>{" "}
                  <input
                    className="editable-field"
                    type="text"
                    value={editableContact.firstName || ""}
                    onChange={(e) =>
                      handleEditableChange("firstName", e.target.value)
                    }
                  />
                </span>
              </td>
              <td>
                <strong>Surname:</strong>{" "}
                <input
                  className="editable-field"
                  type="text"
                  value={editableContact.lastName || ""}
                  onChange={(e) =>
                    handleEditableChange("lastName", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Tel:</strong>{" "}
                <input
                  className="editable-field"
                  type="text"
                  value={editableContact.contactNumber || ""}
                  onChange={(e) =>
                    handleEditableChange("contactNumber", e.target.value)
                  }
                />
              </td>
              <td>
                <strong>Email:</strong>{" "}
                <input
                  className="editable-field"
                  type="email"
                  value={editableContact.emailAddress || ""}
                  placeholder="Insert email address"
                  onChange={(e) =>
                    handleEditableChange("emailAddress", e.target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Insurance:</strong> {editableContact.insurance_broker}
              </td>
              <td>
                <strong>Registration:</strong> {editableContact.registration}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Make:</strong> {editableContact.vehicleMake}
              </td>
              <td>
                <strong>Model:</strong> {editableContact.vehicleModel}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Year:</strong> {editableContact.year}
              </td>
              <td>
                <strong> Group:</strong> {dealershipGroup}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Type:</strong> {editableContact.type}
              </td>
              <td>
                <strong>Dealership:</strong> {editableContact.dealership}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="services">
          <hr />
          <h2>Services</h2>
          <div className="checkbox-group">
            {/* Windscreen Section */}
            <div className="service-group">
              <div className="checkbox-and-text">
                <input
                  type="checkbox"
                  id="windscreen"
                  checked={!!serviceChecks.windscreen?.selected}
                  onChange={(e) =>
                    handleCheckboxChange("windscreen", e.target.checked)
                  }
                />
                <label htmlFor="windscreen">Windscreen</label>
              </div>
              <select
                className="services-select"
                value={serviceChecks.windscreen?.option || ""}
                onChange={(e) =>
                  handleSelectChange("windscreen", e.target.value)
                }
              >
                <option value="">Select Option</option>
                <option>Repair</option>
                <option>Replacement</option>
              </select>
              <div className="package-radios">
                <label>
                  <input
                    type="radio"
                    id="package1"
                    name="windscreen-package"
                    value="package1"
                    checked={!!serviceChecks.windscreen?.package1}
                    onChange={() =>
                      handleCheckboxChange("windscreen", true, "package1")
                    }
                  />
                  Repair 1
                </label>
                <label>
                  <input
                    type="radio"
                    id="package2"
                    name="windscreen-package"
                    value="package2"
                    checked={!!serviceChecks.windscreen?.package2}
                    onChange={() =>
                      handleCheckboxChange("windscreen", true, "package2")
                    }
                  />
                  Repair 2
                </label>
              </div>
              <div className="quantity-column">Chips x {contact.total_chips}</div>
              <div className="service-price">
                R{pricing.windscreen || "0.00"}
              </div>
            </div>

            {/* Headlight Section */}
            <div className="service-group">
              <div className="checkbox-and-text">
                <input
                  type="checkbox"
                  id="headlight"
                  checked={!!serviceChecks.headlight?.selected}
                  onChange={(e) =>
                    handleCheckboxChange("headlight", e.target.checked)
                  }
                />
                <label htmlFor="headlight">Headlight</label>
              </div>
              <select
                className="services-select"
                value={serviceChecks.headlight?.option || ""}
                onChange={(e) =>
                  handleSelectChange("headlight", e.target.value)
                }
              >
                <option value="">Select Option</option>
                <option>Cloudy & Dull</option>
                <option>Scratched</option>
                <option>Scratched & Cloudy</option>
              </select>
              <div className="service-placeholder"></div>
              <div className="quantity-column"></div>
              <div className="service-price">
                R{pricing.headlight || "0.00"}
              </div>
            </div>

            {/* Polish Section */}
            <div className="service-group">
              <div className="checkbox-and-text">
                <input
                  type="checkbox"
                  id="polish"
                  checked={!!serviceChecks.polish?.selected}
                  onChange={(e) =>
                    handleCheckboxChange("polish", e.target.checked)
                  }
                />
                <label htmlFor="polish">Paint Protection</label>
              </div>
              <select
                className="services-select"
                value={serviceChecks.polish?.option || ""}
                onChange={(e) => handleSelectChange("polish", e.target.value)}
              >
                <option value="">Select Option</option>
                <option>PS</option>
                <option>SUV</option>
              </select>
              <div className="package-radios">
                <label>
                  <input
                    type="radio"
                    id="package1"
                    name="polish-package"
                    value="package1"
                    checked={!!serviceChecks.polish?.package1}
                    onChange={() =>
                      handleCheckboxChange("polish", true, "package1")
                    }
                  />
                  Silver
                </label>
                <label>
                  <input
                    type="radio"
                    id="package2"
                    name="polish-package"
                    value="package2"
                    checked={!!serviceChecks.polish?.package2}
                    onChange={() =>
                      handleCheckboxChange("polish", true, "package2")
                    }
                  />
                  Platinum
                </label>
              </div>
              <div className="quantity-column"></div>
              <div className="service-price">R{pricing.polish || "0.00"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetails;
