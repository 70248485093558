import axios from 'axios';

export const notifyTaskAssignment = async (senderId, assignedUserId, taskTitle) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/notifications`, {
      sender_id: senderId,
      recipient_id: assignedUserId,  // The user assigned the task
      notification_title: 'New Task Assigned',
      notification_content: `You have been assigned a new task: ${taskTitle}`,
      notification_status: 'unread',
    });
    console.log('Notification sent successfully');
  } catch (error) {
    console.error('Error sending task assignment notification:', error);
  }
};
