// utils/serviceAdvisors.js
import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "http://localhost:3005/api/service-advisors";

/**
 * Fetch all service advisors
 * @returns {Promise<Array>} - List of all service advisors
 */
export const fetchAllServiceAdvisors = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/service-advisors`);
    return response.data.data;
  } catch (error) {
    console.error(
      "Error fetching all service advisors:",
      error.message || error
    );
    throw new Error("Failed to fetch service advisors.");
  }
};

/**
 * Fetch a service advisor by ID
 * @param {string} id - Service advisor ID
 * @returns {Promise<Object>} - Service advisor details
 */
export const fetchServiceAdvisorById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data.data;
  } catch (error) {
    console.error(
      `Error fetching service advisor with ID ${id}:`,
      error.message || error
    );
    throw new Error(`Failed to fetch service advisor with ID ${id}.`);
  }
};

/**
 * Create a new service advisor
 * @param {Object} serviceAdvisorData - New service advisor data
 * @param {string} serviceAdvisorData.name - Service advisor name
 * @param {string} serviceAdvisorData.dealership - Service advisor dealership
 * @param {string} serviceAdvisorData.email - Service advisor email
 * @param {string} serviceAdvisorData.tel - Service advisor telephone
 * @returns {Promise<Object>} - Created service advisor ID
 */
export const createServiceAdvisor = async (serviceAdvisorData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/service-advisors`,
      serviceAdvisorData
    );
    return response.data.data;
  } catch (error) {
    console.error("Error creating service advisor:", error.message || error);
    throw new Error("Failed to create service advisor.");
  }
};

/**
 * Update a service advisor by ID
 * @param {string} id - Service advisor ID
 * @param {Object} serviceAdvisorData - Updated service advisor data
 * @param {string} serviceAdvisorData.name - Service advisor name
 * @param {string} serviceAdvisorData.dealership - Service advisor dealership
 * @param {string} serviceAdvisorData.email - Service advisor email
 * @param {string} serviceAdvisorData.tel - Service advisor telephone
 * @returns {Promise<void>}
 */
export const updateServiceAdvisorById = async (id, serviceAdvisorData) => {
  try {
    await axios.put(
      `${API_BASE_URL}/service-advisors/${id}`,
      serviceAdvisorData
    );
  } catch (error) {
    console.error(
      `Error updating service advisor with ID ${id}:`,
      error.message || error
    );
    throw new Error(`Failed to update service advisor with ID ${id}.`);
  }
};

/**
 * Delete a service advisor by ID
 * @param {string} id - Service advisor ID
 * @returns {Promise<void>}
 */
export const deleteServiceAdvisorById = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/${id}`);
  } catch (error) {
    console.error(
      `Error deleting service advisor with ID ${id}:`,
      error.message || error
    );
    throw new Error(`Failed to delete service advisor with ID ${id}.`);
  }
};
