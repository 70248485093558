// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.systemErrors {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var( --border-light-color);
  margin-top: 60px;
}

.systemErrors-content {
  display: flex;
  flex: 1 1;
}

.systemErrors-main {
  flex-grow: 1;
  padding: 20px;
}

.systemErrors-main h1 {
  margin-left: 82px;
  margin-bottom: 20px;
}

.systemErrors-table {
  width: 90%;
  border-collapse: collapse;
  margin-left: 80px;
}

.systemErrors-table th,
.systemErrors-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.systemErrors-table th {
  background-color: var(--light-background-color);
  color: #333;
}

.systemErrors-table tr:nth-child(odd) {
  background-color: var(--light-background-color);
}

.systemErrors-table tr:hover {
  background-color: var(--light-background-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/SystemErrors/SystemErrors.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,4CAA4C;EAC5C,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAO;AACT;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,+CAA+C;EAC/C,WAAW;AACb;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".systemErrors {\r\n  padding: 0;\r\n  margin: 0;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  background-color: var( --border-light-color);\r\n  margin-top: 60px;\r\n}\r\n\r\n.systemErrors-content {\r\n  display: flex;\r\n  flex: 1;\r\n}\r\n\r\n.systemErrors-main {\r\n  flex-grow: 1;\r\n  padding: 20px;\r\n}\r\n\r\n.systemErrors-main h1 {\r\n  margin-left: 82px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.systemErrors-table {\r\n  width: 90%;\r\n  border-collapse: collapse;\r\n  margin-left: 80px;\r\n}\r\n\r\n.systemErrors-table th,\r\n.systemErrors-table td {\r\n  border: 1px solid #ddd;\r\n  padding: 8px;\r\n  text-align: left;\r\n}\r\n\r\n.systemErrors-table th {\r\n  background-color: var(--light-background-color);\r\n  color: #333;\r\n}\r\n\r\n.systemErrors-table tr:nth-child(odd) {\r\n  background-color: var(--light-background-color);\r\n}\r\n\r\n.systemErrors-table tr:hover {\r\n  background-color: var(--light-background-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
