// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page {
  width: 80%;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-main-content {
  margin: 60px 40px 0px 90px;
}

.profile-page section {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.input,
.form-group select {
  padding: 8px;
  margin: 5px 0;
  border: 1px solid var( --border-light-color);
  border-radius: 4px;
}

.save-button,
.upload-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--text-light-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover,
.upload-button:hover {
  background-color: var(--secondary-color);
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--light-background-color);
  display: block;
  margin-bottom: 10px;
}

.profile-left-column {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.profile-right-column {
  display: flex;
  flex-direction: column;
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/ProfilePage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,WAAW;AACb;;AAEA;;EAEE,YAAY;EACZ,aAAa;EACb,4CAA4C;EAC5C,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;EAClB,sCAAsC;EACtC,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;EAEE,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,+CAA+C;EAC/C,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ","sourcesContent":[".profile-page {\r\n  width: 80%;\r\n  padding: 20px;\r\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.profile-main-content {\r\n  margin: 60px 40px 0px 90px;\r\n}\r\n\r\n.profile-page section {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.form-group {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 10px;\r\n  width: 100%;\r\n}\r\n\r\n.input,\r\n.form-group select {\r\n  padding: 8px;\r\n  margin: 5px 0;\r\n  border: 1px solid var( --border-light-color);\r\n  border-radius: 4px;\r\n}\r\n\r\n.save-button,\r\n.upload-button {\r\n  padding: 10px 20px;\r\n  background-color: var(--primary-color);\r\n  color: var(--text-light-color);\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.save-button:hover,\r\n.upload-button:hover {\r\n  background-color: var(--secondary-color);\r\n}\r\n\r\n.profile-picture {\r\n  width: 100px;\r\n  height: 100px;\r\n  border-radius: 50%;\r\n  background-color: var(--light-background-color);\r\n  display: block;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.profile-left-column {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 50%;\r\n}\r\n\r\n.profile-right-column {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
