// /src/utils/fetchCompanyDetails.js
export const fetchCompanyDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company-details`);
      const data = await response.json();
  
      if (data) {
        return data;
      } else {
        throw new Error('Failed to fetch company details');
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
      throw error;
    }
  };
  