import axios from "axios";

/**
 * Create dealership goals in the database.
 * @param {Object} goalsData - The data for the dealership goals.
 * @param {number} goalsData.dealership_id - The ID of the dealership.
 * @param {number} goalsData.chips_goal - The goal for chips.
 * @param {number} goalsData.hlr_goal - The goal for headlight repairs (HLR).
 * @param {number} goalsData.pp_goal - The goal for paint protection (PP).
 * @returns {Promise<Object>} - The API response.
 */
const createDealershipGoals = async (goalsData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/dealership/goals`,
      goalsData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.data && response.data.success) {
      return response.data;
    } else {
      throw new Error("Failed to save dealership goals.");
    }
  } catch (error) {
    console.error("Error creating dealership goals:", error);
    throw error;
  }
};

export default createDealershipGoals;
