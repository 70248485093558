import axios from "axios";

/**
 * Fetch dealership goals by dealership ID.
 * @param {number} dealershipId - The ID of the dealership.
 * @returns {Promise<Object>} - The response containing dealership goals.
 */
const fetchDealershipGoals = async (dealershipId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/dealership/goals/${dealershipId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching dealership goals:", error);
    throw error;
  }
};

export default fetchDealershipGoals;
