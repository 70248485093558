import React, { useState } from "react";
import ModalPortal from "../ModalPortal/ModalPortal";
import "./SubStatusModal.css";

const SubStatusModal = ({ show, onClose, onSubStatusChange }) => {
  const [selectedSubStatus, setSelectedSubStatus] = useState("NA");

  const handleSubStatusChange = () => {
    onSubStatusChange(selectedSubStatus);
  };

  if (!show) return null;

  return (
    <ModalPortal>
      <div className="sub-status-modal-backdrop" onClick={onClose}>
        <div className="sub-status-modal-content" onClick={(e) => e.stopPropagation()}>
          <h4>Select Sub Status</h4>
          <select
            value={selectedSubStatus}
            onChange={(e) => setSelectedSubStatus(e.target.value)}
            className="sub-status-select"
          >
            <option value="NA">NA</option>
            <option value="TCB">TCB</option>
            <option value="WN">WN</option>
          </select>
          <div className="sub-status-modal-actions">
            <button className="sub-status-submit-btn" onClick={handleSubStatusChange}>Submit</button>
            <button className="sub-status-cancel-btn" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default SubStatusModal;
