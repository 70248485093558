import axios from "axios";

// Function to fetch job counts
export const fetchJobCounts = async (range, userId, setJobCounts) => {
  try {
    const startDate = range.startDate; // Use the passed start date
    const endDate = range.endDate;     // Use the passed end date
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/jobs/counts`,
      {
        params: {
          startDate, // Pass the date range to the backend
          endDate,
          userId,
        },
      }
    );

    if (response.data) {
      setJobCounts((prevCounts) => ({
        ...prevCounts,
        ...response.data,
      }));
    }
  } catch (error) {
    console.error("Error fetching job counts:", error);
  }
};
