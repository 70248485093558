import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import Tooltip from "../Tooltip/Tooltip";
import { useLocation, Link } from "react-router-dom";
import SupportModal from "../SupportModal/SupportModal"; // Import the modal
import { submitSupportTicket } from "../../utils/submitSupportTicket"; // Import the submit function

const Sidebar = () => {
  const [sidebarHeight, setSidebarHeight] = useState("calc(100vh - 90px)");
  const [showSupportModal, setShowSupportModal] = useState(false); // State to show/hide the modal
  const location = useLocation();
  const [userRole, setUserRole] = useState(null); // State to store the user's role

  useEffect(() => {
    // Fetch user role from localStorage or session
    const user = JSON.parse(localStorage.getItem("user")); // Assuming user data is stored in localStorage
    if (user && user.role) {
      setUserRole(user.role); // Set the role in state
    }

    const updateHeight = () => {
      setSidebarHeight(`calc(${window.innerHeight}px - 90px)`);
    };

    window.addEventListener("resize", updateHeight);
    updateHeight();

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  // Helper function to determine if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <aside className="sidebar" style={{ height: sidebarHeight }}>
      <ul>
        {/* Conditionally render Dashboard for call center agent, admin, and management */}
        {(userRole === "call center agent" ||
          userRole === "admin" ||
          userRole === "management") && (
          <li className={isActive("/dashboard") ? "actives" : ""}>
            <Tooltip text="Dashboard">
              <Link to="/dashboard">
                <span className="material-symbols-outlined">dashboard</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {/* Conditionally render Pipeline for call center agent, admin, and management */}
        {(userRole === "call center agent" ||
          userRole === "admin" ||
          userRole === "management") && (
          <li className={isActive("/pipeline") ? "actives" : ""}>
            <Tooltip text="Pipeline">
              <Link to="/pipeline">
                <span className="material-symbols-outlined">filter_alt</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {/* Conditionally render Contacts for call center agent, admin, and management */}
        {(userRole === "call center agent" ||
          userRole === "admin" ||
          userRole === "management") && (
          <li className={isActive("/contacts") ? "actives" : ""}>
            <Tooltip text="Contacts">
              <Link to="/contacts">
                <span className="material-symbols-outlined">groups</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {/* Conditionally render Admin route if user is an admin */}
        {userRole === "admin" && (
          <li className={isActive("/admin") ? "actives" : ""}>
            <Tooltip text="Admin">
              <Link to="/admin">
                <span className="material-symbols-outlined">shield_person</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {/* Insurance route for insurance or admin roles */}
        {(userRole === "insurance" || userRole === "admin") && (
          <li>
            <Tooltip text="Insurance">
              <Link to="/insurance">
                <span className="material-symbols-outlined">
                  health_and_safety
                </span>
              </Link>
            </Tooltip>
          </li>
        )}

        {/* Invoicing route for accounts or admin roles */}
        {(userRole === "accounts" || userRole === "admin") && (
          <li>
            <Tooltip text="Invoicing">
              <Link to="/invoicing">
                <span className="material-symbols-outlined">receipt_long</span>
              </Link>
            </Tooltip>
          </li>
        )}
      </ul>

      <ul className="bottom-icons">
        {/* <li className={isActive("/phone-calls") ? "actives" : ""}>
          <Tooltip text="Phone Calls">
            <Link to="/phone-calls">
              <span className="material-symbols-outlined">phone_in_talk</span>
            </Link>
          </Tooltip>
        </li> */}
        <li>
          <Tooltip text="Help">
            <span
              className="material-symbols-outlined last-icon"
              onClick={() => setShowSupportModal(true)} // Open the modal
            >
              contact_support
            </span>
          </Tooltip>
        </li>
      </ul>

      {/* Render the SupportModal if showSupportModal is true */}
      {showSupportModal && (
        <SupportModal
          onClose={() => setShowSupportModal(false)}
          onSubmit={submitSupportTicket}
        />
      )}
    </aside>
  );
};

export default Sidebar;
