export async function fetchServiceCheckCounts(userId, startDate, endDate) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/services/service-check-counts?userId=${userId}&startDate=${startDate}&endDate=${endDate}`);
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Error fetching service check counts');
    }

    return data.data; // Return the data
  } catch (error) {
    console.error('Error fetching service check counts:', error);
    throw error;
  }
}
