import React from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./SystemErrors.css";

const dummyErrors = [
  {
    error: "404 Not Found",
    description: "The requested resource was not found on the server.",
    dateTime: "2024-04-24 14:35:00",
    user: "johndoe",
    functionName: "fetchResource",
  },
  {
    error: "500 Internal Server Error",
    description: "The server encountered an unexpected condition.",
    dateTime: "2024-04-24 15:00:00",
    user: "janedoe",
    functionName: "updateDatabase",
  },
  {
    error: "401 Unauthorized",
    description:
      "The request has not been applied because it lacks valid authentication credentials for the target resource.",
    dateTime: "2024-04-24 16:20:00",
    user: "alice",
    functionName: "accessSecureArea",
  },
  {
    error: "403 Forbidden",
    description:
      "The server understood the request but refuses to authorize it.",
    dateTime: "2024-04-24 16:45:00",
    user: "bob",
    functionName: "deleteUser",
  },
  {
    error: "408 Request Timeout",
    description:
      "The server did not receive a complete request message within the time that it was prepared to wait.",
    dateTime: "2024-04-24 17:05:00",
    user: "charlie",
    functionName: "saveChanges",
  },
  {
    error: "502 Bad Gateway",
    description:
      "The server, while acting as a gateway or proxy, received an invalid response from the upstream server.",
    dateTime: "2024-04-24 17:30:00",
    user: "david",
    functionName: "fetchExternalApi",
  },
  {
    error: "504 Gateway Timeout",
    description:
      "The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server.",
    dateTime: "2024-04-24 18:00:00",
    user: "eva",
    functionName: "updateExternalData",
  },
  {
    error: "507 Insufficient Storage",
    description:
      "The method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.",
    dateTime: "2024-04-24 18:30:00",
    user: "frank",
    functionName: "uploadFile",
  },
];

const SystemErrors = () => {
  return (
    <div className="systemErrors">
      <Header />
      <div className="systemErrors-content">
        <Sidebar />
        <main className="systemErrors-main">
          <h1>System Error Logs</h1>
          <table className="systemErrors-table">
            <thead>
              <tr>
                <th>Error</th>
                <th>Description</th>
                <th>Date and Time</th>
                <th>User</th>
                <th>Function Name</th>
              </tr>
            </thead>
            <tbody>
              {dummyErrors.map((error, index) => (
                <tr key={index}>
                  <td>{error.error}</td>
                  <td>{error.description}</td>
                  <td>{error.dateTime}</td>
                  <td>{error.user}</td>
                  <td>{error.functionName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
      </div>
    </div>
  );
};

export default SystemErrors;
