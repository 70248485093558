// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pipeline-main-content {
  margin: 90px 20px 40px 100px;
  display: flex;
  flex-direction: column;
}

.view-toggle-container {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 40px;
}

.view-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.view-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-toggle-button:hover {
  background-color: var(--light-background-color);
}

.material-symbols-outlined {
  font-feature-settings: "liga";
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pipeline/Pipeline.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".pipeline-main-content {\r\n  margin: 90px 20px 40px 100px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.view-toggle-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  margin: 0 0 0 40px;\r\n}\r\n\r\n.view-container {\r\n  display: flex;\r\n  flex-grow: 1;\r\n  width: 100%;\r\n}\r\n\r\n.view-toggle-button {\r\n  background: none;\r\n  border: none;\r\n  cursor: pointer;\r\n  padding: 8px;\r\n  font-size: 24px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.view-toggle-button:hover {\r\n  background-color: var(--light-background-color);\r\n}\r\n\r\n.material-symbols-outlined {\r\n  font-feature-settings: \"liga\";\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
