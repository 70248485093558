import React from "react";
import PropTypes from "prop-types";
import "./FormModal.css";

const FormModal = ({
  closeModal,
  heading,
  fields,
  handleChange,
  handleSubmit,
  formData = {},
}) => {
  return (
    <div className="modal" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{heading}</h2>
        <form onSubmit={handleSubmit} className="form-modal">
          <table className="input-table">
            <thead>
              <tr>
                {fields.map((field, index) => (
                  <th key={index}>{field.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {fields.map((field, index) => (
                  <td key={index}>
                    {field.type === "checkbox" ? (
                      <input
                        type="checkbox"
                        id={field.name}
                        name={field.name}
                        checked={!!formData[field.name]}
                        onChange={handleChange}
                      />
                    ) : field.type === "select" ? (
                      <select
                        id={field.name}
                        name={field.name}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                      >
                        {field.options.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          {formData.discounts && (
            <div className="discount-table">
              <h3>Discounts</h3>
              <table>
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Base Price</th>
                    <th>Discount Percentage</th>
                    <th>Discounted Price</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.discounts.map((discount, index) => (
                    <tr key={index}>
                      <td>{discount.service_name}</td>
                      <td>{discount.retail_price_excl}</td>
                      <td>
                        <input
                          type="number"
                          name={`discount_${discount.service_id}`}
                          value={discount.discount_percentage}
                          onChange={handleChange}
                          min="0"
                          max="100"
                        />
                      </td>
                      <td>
                        {(
                          (1 - discount.discount_percentage / 100) *
                          discount.retail_price_excl
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="button">
            <button type="submit">Submit</button>
            <button type="button" onClick={closeModal}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

FormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.array,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
};

export default FormModal;
