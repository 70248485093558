import React, { useState, useEffect } from "react";
import { FaTrash, FaPlusCircle } from "react-icons/fa";

const SpecialPricingView = ({
  dealershipPricing,
  onEdit,
  onDelete,
  dealerships,
  selectedDealershipId,
  searchText,
  onSearchChange,
  onSelectDealership,
  noSpecialPricingMessage,
  onClearDealership,
  onAddSpecialPricing,
  services,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [formData, setFormData] = useState({
    dealership_id: "",
    service_id: "",
    service_name: "",
    trade_price_excl: "",
    trade_price_incl: "",
    retail_price_excl: "",
    retail_price_incl: "",
    gross_profit_excl: "",
  });

  const filteredDealerships = dealerships.filter((dealership) =>
    dealership.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDealershipClick = (dealership) => {
    onSelectDealership(dealership.dealership_id);
    setFormData((prev) => ({
      ...prev,
      dealership_id: dealership.dealership_id, // Sync with formData
    }));
  };

  const clearSearchText = () => {
    onSearchChange({ target: { value: "" } });
    onClearDealership();
  };

  const calculateValues = (tradePriceExcl, retailPriceExcl) => {
    const VAT_RATE = 0.15;
    const tradePriceIncl = tradePriceExcl * (1 + VAT_RATE);
    const retailPriceIncl = retailPriceExcl * (1 + VAT_RATE);
    const grossProfitExcl = retailPriceExcl - tradePriceExcl;
    return {
      trade_price_incl: tradePriceIncl.toFixed(2),
      retail_price_incl: retailPriceIncl.toFixed(2),
      gross_profit_excl: grossProfitExcl.toFixed(2),
    };
  };

  const handleAddSpecialPricing = () => {
    const { trade_price_incl, retail_price_incl, gross_profit_excl } =
      calculateValues(
        parseFloat(formData.trade_price_excl || 0),
        parseFloat(formData.retail_price_excl || 0)
      );

    const newSpecialPricing = {
      ...formData,
      trade_price_incl,
      retail_price_incl,
      gross_profit_excl,
    };

    onAddSpecialPricing(selectedDealershipId, newSpecialPricing);
    setShowAddModal(false);
    setFormData({
      dealership_id: "",
      service_id: "",
      service_name: "",
      trade_price_excl: "",
      trade_price_incl: "",
      retail_price_excl: "",
      retail_price_incl: "",
      gross_profit_excl: "",
    });
  };

  useEffect(() => {
    console.log("Current selectedDealershipId:", selectedDealershipId);
  }, [selectedDealershipId]);

  return (
    <div>
      {/* Dealership Selector */}
      <div className="dealership-selector">
        <label>Select Dealership</label>
        <div className="dealership-input-container">
          <input
            type="text"
            placeholder="Type to search dealership"
            value={searchText}
            onChange={onSearchChange}
            onFocus={() => {
              const dropdown = document.querySelector(
                ".dealership-name-dropdown-menu"
              );
              if (dropdown) dropdown.style.display = "block";
            }}
            onBlur={() => {
              setTimeout(() => {
                const dropdown = document.querySelector(
                  ".dealership-name-dropdown-menu"
                );
                if (dropdown) dropdown.style.display = "none";
              }, 200);
            }}
          />
          {searchText && (
            <button
              className="clear-input-button"
              onClick={clearSearchText}
              title="Clear"
            >
              &times;
            </button>
          )}
        </div>
        <div className="dealership-name-dropdown-menu">
          {filteredDealerships.length > 0 ? (
            filteredDealerships.map((dealership, index) => (
              <div
                key={`${dealership.dealership_id}-${index}`}
                className="dealership-name-dropdown-item"
                onMouseDown={() => handleDealershipClick(dealership)}
              >
                {dealership.name}
              </div>
            ))
          ) : (
            <div className="dealership-name-dropdown-item no-results">
              No Dealerships Found
            </div>
          )}
        </div>
      </div>
      <div className="icon-container" onClick={() => setShowAddModal(true)}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>

      {/* Display a message if no special pricing is found */}
      {noSpecialPricingMessage ? (
        <div className="no-special-pricing-message">
          {noSpecialPricingMessage}
        </div>
      ) : dealershipPricing && dealershipPricing.length > 0 ? (
        // Special Pricing Table
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Trade Price Excl.</th>
              <th>Trade Price Incl.</th>
              <th>Retail Price Excl.</th>
              <th>Retail Price Incl.</th>
              <th>Gross Profit Excl.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dealershipPricing.map((pricingItem) => (
              <tr
                key={pricingItem.service_id}
                onClick={() => onEdit(pricingItem)}
              >
                <td>{pricingItem.service_name}</td>
                <td>{pricingItem.trade_price_excl}</td>
                <td>{pricingItem.trade_price_incl}</td>
                <td>{pricingItem.retail_price_excl}</td>
                <td>{pricingItem.retail_price_incl}</td>
                <td>{pricingItem.gross_profit_excl}</td>
                <td className="actions-cell">
                  <FaTrash
                    className="trash-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(pricingItem.service_id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-special-pricing-message">
          Please Select A Dealership to Display the Special Pricing
        </div>
      )}

      {/* Add Special Pricing Modal */}
      {showAddModal && (
        <div
          className="special-pricing-modal"
          onClick={() => setShowAddModal(false)}
        >
          <div
            className="special-pricing-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="special-pricing-title">Add Special Pricing</h2>
            <form
              className="special-pricing-form"
              onSubmit={(e) => {
                e.preventDefault();

                // Ensure dealership_id is selected
                if (!formData.dealership_id) {
                  alert("Please select a dealership before submitting.");
                  console.error("Dealership ID is missing from formData!");
                  return;
                }

                // Prepare special pricing data
                const specialPricingData = services.map((service) => {
                  const serviceData = formData[service.service_id] || {};
                  return {
                    service_id: service.service_id,
                    service_name: service.service_name,
                    trade_price_excl: parseFloat(
                      serviceData.trade_price_excl || 0
                    ),
                    trade_price_incl: parseFloat(
                      serviceData.trade_price_incl || 0
                    ),
                    retail_price_excl: parseFloat(
                      serviceData.retail_price_excl || 0
                    ),
                    retail_price_incl: parseFloat(
                      serviceData.retail_price_incl || 0
                    ),
                    gross_profit_excl: parseFloat(
                      serviceData.gross_profit || 0
                    ),
                  };
                });

                // Debugging
                console.log("Final dealership_id:", formData.dealership_id);
                console.log("Final specialPricingData:", specialPricingData);

                // Call the handler to submit the data
                handleAddSpecialPricing(
                  formData.dealership_id,
                  specialPricingData
                );

                // Reset the form and close the modal
                setShowAddModal(false);
                setFormData({});
              }}
            >
              {/* Dealership Dropdown */}
              <div className="special-pricing-dealership-dropdown">
                <label
                  htmlFor="special-pricing-dealership-select"
                  className="special-pricing-label"
                >
                  Select Dealership:
                </label>
                <select
                  id="special-pricing-dealership-select"
                  className="special-pricing-dealership-select"
                  name="dealership_id"
                  value={formData.dealership_id}
                  onChange={(e) => {
                    const dealershipId = e.target.value;
                    setFormData((prev) => ({
                      ...prev,
                      dealership_id: dealershipId, // Set dealership_id in formData
                    }));
                    console.log(
                      "Updated dealership_id in formData:",
                      dealershipId
                    );
                  }}
                  required
                >
                  <option value="">Select Dealership</option>
                  {dealerships.map((dealership) => (
                    <option
                      key={dealership.dealership_id}
                      value={dealership.dealership_id}
                    >
                      {dealership.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Pricing Table */}
              <table className="special-pricing-table">
                <thead>
                  <tr>
                    <th className="special-pricing-header">Service Name</th>
                    <th className="special-pricing-header">
                      Trade Price Excl.
                    </th>
                    <th className="special-pricing-header">
                      Retail Price Excl.
                    </th>
                    <th className="special-pricing-header">
                      Trade Price Incl.
                    </th>
                    <th className="special-pricing-header">
                      Retail Price Incl.
                    </th>
                    <th className="special-pricing-header">VAT</th>
                    <th className="special-pricing-header">Gross Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {services.map((service) => {
                    const serviceData = formData[service.service_id] || {
                      trade_price_excl: "",
                      retail_price_excl: "",
                      trade_price_incl: "0.00",
                      retail_price_incl: "0.00",
                      vat: "0.00",
                      gross_profit: "0.00",
                    };

                    return (
                      <tr
                        key={service.service_id}
                        className="special-pricing-row"
                      >
                        <td className="special-pricing-cell">
                          {service.service_name}
                        </td>
                        <td className="special-pricing-cell">
                          <input
                            type="number"
                            className="special-pricing-input"
                            name="trade_price_excl"
                            value={serviceData.trade_price_excl}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value || 0);
                              setFormData((prev) => ({
                                ...prev,
                                [service.service_id]: {
                                  ...serviceData,
                                  trade_price_excl: value,
                                  trade_price_incl: (value * 1.15).toFixed(2),
                                  vat: (value * 0.15).toFixed(2),
                                  gross_profit: (
                                    (serviceData.retail_price_excl || 0) - value
                                  ).toFixed(2),
                                },
                              }));
                            }}
                            required
                          />
                        </td>
                        <td className="special-pricing-cell">
                          <input
                            type="number"
                            className="special-pricing-input"
                            name="retail_price_excl"
                            value={serviceData.retail_price_excl}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value || 0);
                              setFormData((prev) => ({
                                ...prev,
                                [service.service_id]: {
                                  ...serviceData,
                                  retail_price_excl: value,
                                  retail_price_incl: (value * 1.15).toFixed(2),
                                  gross_profit: (
                                    value - (serviceData.trade_price_excl || 0)
                                  ).toFixed(2),
                                },
                              }));
                            }}
                            required
                          />
                        </td>
                        <td className="special-pricing-cell">
                          {serviceData.trade_price_incl}
                        </td>
                        <td className="special-pricing-cell">
                          {serviceData.retail_price_incl}
                        </td>
                        <td className="special-pricing-cell">
                          {serviceData.vat}
                        </td>
                        <td className="special-pricing-cell">
                          {serviceData.gross_profit}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="special-pricing-modal-buttons">
                <button type="submit" className="special-pricing-button">
                  Add Pricing
                </button>
                <button
                  className="special-pricing-button cancel"
                  onClick={() => setShowAddModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialPricingView;
