import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import FormModal from "../FormModal/FormModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import axios from 'axios';
import "./ManageVehicleTypes.css";

const ManageVehicleTypes = () => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [formData, setFormData] = useState({
    type: "",
    make: "",
    model: "",
    year: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editingVehicleType, setEditingVehicleType] = useState(null);
  const [deletingVehicleTypeId, setDeletingVehicleTypeId] = useState(null);

  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setVehicleTypes(response.data);
      } catch (error) {
        console.error("Error fetching vehicle types:", error);
      }
    };

    fetchVehicleTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingVehicleType) {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types/${editingVehicleType.vehicle_type_id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
      }

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVehicleTypes(response.data);
    } catch (error) {
      console.error("Error creating/updating vehicle type:", error);
    }
    setShowModal(false);
    setEditingVehicleType(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types/${deletingVehicleTypeId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVehicleTypes(vehicleTypes.filter(vehicleType => vehicleType.vehicle_type_id !== deletingVehicleTypeId));
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting vehicle type:", error);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingVehicleType(null);
  };

  const handleOpenConfirmationModal = (vehicle_type_id) => {
    setDeletingVehicleTypeId(vehicle_type_id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingVehicleTypeId(null);
  };

  const handleRowClick = (vehicleType) => {
    setEditingVehicleType(vehicleType);
    setFormData(vehicleType);
    setShowModal(true);
  };

  const formFields = [
    { name: "type", label: "Vehicle Type", type: "text" },
    { name: "make", label: "Vehicle Make", type: "text" },
    { name: "model", label: "Vehicle Model", type: "text" },
    { name: "year", label: "Vehicle Year", type: "text" },
  ];

  return (
    <div className="ManageVehicleTypes">
      <h2>Manage Vehicle Types</h2>
      <div className="icon-container" onClick={handleOpenModal}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>
      {showModal && (
        <FormModal
          closeModal={handleCloseModal}
          heading={editingVehicleType ? "Edit Vehicle Type" : "Add New Vehicle Type"}
          fields={formFields}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          message="Are you sure you want to delete this vehicle type?"
          onConfirm={handleDelete}
          onCancel={handleCloseConfirmationModal}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>Vehicle Type</th>
            <th>Vehicle Make</th>
            <th>Vehicle Model</th>
            <th>Vehicle Year</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vehicleTypes.map((vehicleType) => (
            <tr key={vehicleType.vehicle_type_id}>
              <td onClick={() => handleRowClick(vehicleType)}>{vehicleType.type}</td>
              <td onClick={() => handleRowClick(vehicleType)}>{vehicleType.make}</td>
              <td onClick={() => handleRowClick(vehicleType)}>{vehicleType.model}</td>
              <td onClick={() => handleRowClick(vehicleType)}>{vehicleType.year}</td>
              <td className="actions-cell">
                <button onClick={() => handleOpenConfirmationModal(vehicleType.vehicle_type_id)} className="delete-button">
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageVehicleTypes;
