import axios from 'axios';

export const fetchImageGallery = async (registrationNumber) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/jobs/${registrationNumber}/images`,
      {
        withCredentials: true,
      }
    );

    // Process image paths to handle potential long paths
    const processedImages = response.data
      .filter(image => !image.image_name.includes('windscreen'))
      .map(image => ({
        ...image,
        image_path: processImagePath(image.image_path),
      }));

    console.log('Image gallery: ', processedImages);
    return processedImages; // Return the processed image data
  } catch (error) {
    console.error('Error fetching vehicle images:', error);
    return []; // Return an empty array if an error occurs
  }
};

// Helper function to process the image path
const processImagePath = (path) => {
  const prefixToRemove = 'E:\\Apps\\starbreak\\starbreak-backend\\src';
  if (path.startsWith(prefixToRemove)) {
    return path.replace(prefixToRemove, '').replace(/\\/g, '/'); // Normalize to forward slashes
  }
  return path; // Return the original path if no match
};
