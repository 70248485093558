import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { searchJobs } from "../../utils/searchJobs";
import { searchInsuranceCompanies } from "../../utils/searchInsuranceCompanies";
import { fetchNotifications } from "../../utils/fetchNotifications";
import { handleDNDResponse } from "../../utils/handleDNDResponse";
import { updateNotificationStatus } from "../../utils/updateNotifications";
import { handleLogout } from "../../utils/logout"; // Import the logout function
import "./Header.css";

const Header = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ firstName: "", lastName: "", id: null });
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [showChatDropdown, setShowChatDropdown] = useState(false);
  const [showDNDModal, setShowDNDModal] = useState(false);
  const [showAllNotificationsModal, setShowAllNotificationsModal] =
    useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const modalRef = useRef(null);
  const chatBubbleRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser({
        firstName: userData.firstName,
        lastName: userData.lastName,
        id: userData.id,
      });

      fetchUserNotifications(userData.id);

      const intervalId = setInterval(() => {
        fetchUserNotifications(userData.id);
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const fetchUserNotifications = async (userId) => {
    try {
      const notificationsData = await fetchNotifications(userId);
      const unreadNotifications = notificationsData.filter(
        (notification) => notification.notification_status === "unread"
      );
      setNotifications(unreadNotifications);
      setNotificationCount(unreadNotifications.length);
      setAllNotifications(notificationsData); // Store all notifications for "View All"
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.notification_title === "DND Approval Required") {
      setSelectedNotification(notification);
      setShowDNDModal(true);
    } else {
      updateNotificationStatus(notification.notification_id);
    }
  };

  const handleSearch = async () => {
    if (!searchTerm) return;
    setLoading(true);

    try {
      const jobs = await searchJobs(searchTerm);
      const insuranceCompanies = await searchInsuranceCompanies(searchTerm);
      const combinedResults = [...jobs, ...insuranceCompanies];
      setSearchResults(combinedResults);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const debounceTimeout = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  // Updated handleLogout function
  const handleLogoutClick = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.id) {
      await handleLogout(userData.id); // Call the handleLogout function from utils
    }
    navigate("/login");
  };

  const toggleChatDropdown = () => {
    setShowChatDropdown(!showChatDropdown);
  };

  const toggleAllNotificationsModal = () => {
    setShowAllNotificationsModal(!showAllNotificationsModal);
  };

  useEffect(() => {
    const handleClickOutsideChat = (event) => {
      if (
        chatBubbleRef.current &&
        !chatBubbleRef.current.contains(event.target)
      ) {
        setShowChatDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideChat);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideChat);
    };
  }, []);

  return (
    <header className="header-custom">
      <div className="header-logo-custom"></div>
      <div className="search-and-profile-custom">
        <div className="header-search-custom">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchResults.length > 0 && (
            <div ref={modalRef} className="search-results-modal-custom">
              {loading ? (
                <p>Loading...</p>
              ) : (
                searchResults.map((result, index) => (
                  <div key={index} className="search-result-item-custom">
                    <Link to={`/details/${result.job_id || result.company_id}`}>
                      {result.job_id ? (
                        <>
                          <strong>Job:</strong> <br />
                          <strong>Registration:</strong>{" "}
                          {result.registration_number || "N/A"} <br />
                          <strong>Customer Name:</strong>{" "}
                          {result.first_name
                            ? `${result.first_name} ${result.last_name}`
                            : "N/A"}{" "}
                          <br />
                          <strong>Email:</strong> {result.emailAddress || "N/A"}{" "}
                          <br />
                        </>
                      ) : result.company_id ? (
                        <>
                          <strong>Insurance Company:</strong> <br />
                          <strong>Company Name:</strong>{" "}
                          {result.company_name || "N/A"} <br />
                          <strong>Email:</strong>{" "}
                          {result.email_address || "N/A"} <br />
                          <strong>Status:</strong> {result.status || "N/A"}
                        </>
                      ) : null}
                    </Link>
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <div className="user-details-custom">
          <span>{`${user.firstName} ${user.lastName}`}</span>
        </div>

        <div className="chat-bubble-custom" ref={chatBubbleRef}>
          <Badge badgeContent={notificationCount} color="secondary">
            <ChatBubbleOutlineIcon
              fontSize="large"
              onClick={toggleChatDropdown}
            />
          </Badge>

          {showChatDropdown && (
            <div className="chat-dropdown-custom">
              {notifications.length > 0 ? (
                <>
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="chat-dropdown-item-custom"
                      onClick={() => handleNotificationClick(notification)}
                    >
                      {new Date(notification.created_at).toLocaleTimeString()} |{" "}
                      {notification.notification_title}
                    </div>
                  ))}
                  <div
                    className="view-all-custom"
                    onClick={toggleAllNotificationsModal}
                  >
                    View All
                  </div>
                </>
              ) : (
                <div className="chat-dropdown-item-custom">No new messages</div>
              )}
            </div>
          )}
        </div>

        <div className="logout-button-custom">
          <button onClick={handleLogoutClick} className="logout-icon-button">
            <LogoutIcon />
          </button>
        </div>
      </div>

      {showDNDModal && (
        <div className="modal-custom">
          <div className="modal-content-custom">
            <h3>DND Approval Required</h3>
            <p>{selectedNotification?.notification_content}</p>
            <div className="modal-actions-custom">
              <button
                onClick={() =>
                  handleDNDResponse(
                    "approve",
                    selectedNotification,
                    fetchUserNotifications,
                    () => setShowDNDModal(false) // Close modal after approval
                  )
                }
              >
                Approve
              </button>
              <button
                onClick={() =>
                  handleDNDResponse(
                    "decline",
                    selectedNotification,
                    fetchUserNotifications,
                    () => setShowDNDModal(false) // Close modal after decline
                  )
                }
              >
                Decline
              </button>

              <button onClick={() => setShowDNDModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {showAllNotificationsModal && (
        <div className="modal-custom">
          <div className="modal-content-custom all-notifications-modal-custom">
            <h3>All Notifications</h3>
            <div className="all-notifications-list-custom">
              {allNotifications.map((notification, index) => (
                <div
                  key={index}
                  className={`notification-item-custom ${
                    notification.notification_status === "unread"
                      ? "unread-notification-custom"
                      : ""
                  }`}
                >
                  <p>
                    <strong>
                      {new Date(notification.created_at).toLocaleString()}
                    </strong>{" "}
                    ({notification.notification_status})
                  </p>
                  <p>
                    <strong>{notification.notification_title}</strong>
                  </p>
                  <p>{notification.notification_content}</p>

                  {notification.notification_title ===
                    "DND Approval Required" &&
                    notification.notification_status === "unread" && (
                      <div className="notification-action-buttons">
                        <button
                          className="notification-approve-btn"
                          onClick={() =>
                            handleDNDResponse(
                              "approve",
                              notification,
                              fetchUserNotifications
                            )
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="notification-decline-btn"
                          onClick={() =>
                            handleDNDResponse(
                              "decline",
                              notification,
                              fetchUserNotifications
                            )
                          }
                        >
                          Decline
                        </button>
                      </div>
                    )}
                </div>
              ))}
            </div>
            <button
              className="close-btn-custom"
              onClick={toggleAllNotificationsModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
