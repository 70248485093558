import axios from 'axios';

// Function to fetch user activity status
export const fetchUsersActivityStatus = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/activity-status`);
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching user activity status:', error);
    throw error;
  }
};
