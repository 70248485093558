import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./CallCabinetSetup.css";

export const CallCabinetSetup = () => {
  const [apiKey, setApiKey] = useState("");
  const [domain, setDomain] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="callCabinetSetup">
      <Header />
      <div className="callCabinetSetup-content">
        <Sidebar />
        <main className="callCabinetSetup-main">
          <h1>Call Cabinet API Setup</h1>
          <p>
            Please enter your unique API key and the domain to connect to the
            Call Cabinet API. <br />
            These settings enable you to manage and integrate your call
            analytics seamlessly.
          </p>
          <form onSubmit={handleSubmit} className="callCabinetSetup-form">
            <div className="callCabinetSetup-inputGroup">
              <label htmlFor="apiKey" className="callCabinetSetup-label">
                API Key:
              </label>
              <input
                id="apiKey"
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="callCabinetSetup-input"
                required
              />
            </div>
            <div className="callCabinetSetup-inputGroup">
              <label htmlFor="domain" className="callCabinetSetup-label">
                Domain:
              </label>
              <input
                id="domain"
                type="text"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                className="callCabinetSetup-input"
                required
              />
            </div>
            <button type="submit" className="callCabinetSetup-button">
              Save Settings
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default CallCabinetSetup;
