// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-page-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.how-am-i-doing{
  height: 40px;
  width: 200px;
  margin: 90px 0 0 0;
  border: none;
  background-color: var(--primary-color);
  color: var(--text-light-color);
}

.dashboard-content {
  display: flex;
  flex: 1 1;
  overflow-y: hidden;
}

aside {
  width: 200px;
}

main {
  flex: 1 1;
  padding: 20px;
  z-index: 1;
}

.dashboard-main-content {
  margin: 0px 0px 0px 90px;
  overflow-y: auto ;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAO;EACP,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".dashboard {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n}\r\n\r\n.dashboard-page-header{\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 0 30px;\r\n}\r\n\r\n.how-am-i-doing{\r\n  height: 40px;\r\n  width: 200px;\r\n  margin: 90px 0 0 0;\r\n  border: none;\r\n  background-color: var(--primary-color);\r\n  color: var(--text-light-color);\r\n}\r\n\r\n.dashboard-content {\r\n  display: flex;\r\n  flex: 1;\r\n  overflow-y: hidden;\r\n}\r\n\r\naside {\r\n  width: 200px;\r\n}\r\n\r\nmain {\r\n  flex: 1;\r\n  padding: 20px;\r\n  z-index: 1;\r\n}\r\n\r\n.dashboard-main-content {\r\n  margin: 0px 0px 0px 90px;\r\n  overflow-y: auto ;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
