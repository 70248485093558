import axios from 'axios';

// Function to update notification status to 'read'
export async function updateNotificationStatus(notificationId) {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/notifications/${notificationId}/read`);
        return response.data; // Return the response from the server
    } catch (error) {
        console.error('Error updating notification status:', error);
        throw error;
    }
}
