// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activities-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 16px;
  color: #4d4d4d;
}

.activities-table-container {
  width: 93%;
  max-height: 220px; /* Adjust height to fit 4 rows + header */
  overflow-y: auto;
  margin: 0 20px;
}

.activities-table {
  width: 100%;
  border-collapse: collapse;
}

.activities-table th,
.activities-table td {
  border: 1px solid var(--grey-background-color);
  padding: 10px;
  text-align: left;
}

.activities-table th {
  background-color: var(--primary-color);
  color: var(--text-light-color);
  position: sticky;
  top: 0;
  z-index: 1;
}

.activities-table tbody tr:nth-child(even) {
  background-color: var(--table-row-even-background-color);
}

.activities-table tbody tr:nth-child(odd) {
  background-color: var(--table-row-odd-background-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/Activities/Activities.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,iBAAiB,EAAE,yCAAyC;EAC5D,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,8CAA8C;EAC9C,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,8BAA8B;EAC9B,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,uDAAuD;AACzD","sourcesContent":[".activities-container {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\nh2 {\r\n  margin-bottom: 20px;\r\n  font-size: 16px;\r\n  color: #4d4d4d;\r\n}\r\n\r\n.activities-table-container {\r\n  width: 93%;\r\n  max-height: 220px; /* Adjust height to fit 4 rows + header */\r\n  overflow-y: auto;\r\n  margin: 0 20px;\r\n}\r\n\r\n.activities-table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\n.activities-table th,\r\n.activities-table td {\r\n  border: 1px solid var(--grey-background-color);\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\n.activities-table th {\r\n  background-color: var(--primary-color);\r\n  color: var(--text-light-color);\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 1;\r\n}\r\n\r\n.activities-table tbody tr:nth-child(even) {\r\n  background-color: var(--table-row-even-background-color);\r\n}\r\n\r\n.activities-table tbody tr:nth-child(odd) {\r\n  background-color: var(--table-row-odd-background-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
