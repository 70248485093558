import React, { useState, useEffect } from "react";
import axios from "axios";
import fetchDealerships from "../../utils/fetchDealerships";
import fetchSpecialPricing from "../../utils/fetchSpecialPricing"; // Import the refactored function
import BasePricingView from "./BasePricingView"; // Import Base Pricing Component
import SpecialPricingView from "./SpecialPricingView"; // Import Special Pricing Component
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import fetchServices from "../../utils/fetchServices";
import handleAddSpecialPricing from "../../utils/createSpecialPricing";

import "./ManagePricingTables.css";

const ManagePricingTables = () => {
  const [activeTab, setActiveTab] = useState("base");
  const [pricing, setPricing] = useState([]);
  const [dealershipPricing, setDealershipPricing] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [selectedDealershipId, setSelectedDealershipId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [formData, setFormData] = useState({
    service_name: "",
    trade_price_excl: "",
    retail_price_excl: "",
  });
  const [editingPricing, setEditingPricing] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletingServiceId, setDeletingServiceId] = useState(null);
  const [noSpecialPricingMessage, setNoSpecialPricingMessage] = useState("");
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const VAT_RATE = 0.15;

  useEffect(() => {
    fetchPricing();
    fetchDealershipsData();
    initializeServices();
  }, []);

  // Fetch services during initialization
  const initializeServices = async () => {
    try {
      const fetchedServices = await fetchServices();
      setServices(fetchedServices); // Store the fetched services
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setServicesLoading(false); // Ensure loading state is cleared
    }
  };

  const fetchPricing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/base-pricing`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setPricing(response.data.data);
    } catch (error) {
      console.error("Error fetching pricing:", error);
    }
  };

  const fetchDealershipsData = async () => {
    try {
      const data = await fetchDealerships();
      setDealerships(data);
    } catch (error) {
      console.error("Error fetching dealerships:", error);
    }
  };

  const handleSelectDealership = async (dealershipId) => {
    try {
      setSelectedDealershipId(dealershipId);
      const { data, message } = await fetchSpecialPricing(dealershipId);
      setDealershipPricing(data);
      setNoSpecialPricingMessage(message || ""); // Handle backend message
    } catch (error) {
      console.error(
        "Error selecting dealership or fetching special pricing:",
        error
      );
    }
  };

  const calculateValues = (tradePriceExcl, retailPriceExcl) => {
    const tradePriceIncl = tradePriceExcl * (1 + VAT_RATE);
    const retailPriceIncl = retailPriceExcl * (1 + VAT_RATE);
    const grossProfitExcl = retailPriceExcl - tradePriceExcl;
    return {
      trade_price_incl: tradePriceIncl.toFixed(2),
      retail_price_incl: retailPriceIncl.toFixed(2),
      gross_profit_excl: grossProfitExcl.toFixed(2),
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    const { trade_price_incl, retail_price_incl, gross_profit_excl } =
      calculateValues(
        parseFloat(newFormData.trade_price_excl || 0),
        parseFloat(newFormData.retail_price_excl || 0)
      );
    setFormData({
      ...newFormData,
      trade_price_incl,
      retail_price_incl,
      gross_profit_excl,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { dealership_id } = formData;

    if (!dealership_id) {
      alert("Please select a dealership.");
      console.error("Dealership ID is missing!");
      return;
    }
    
    console.log('Dealership ID: ', dealership_id)

    // Transform data for specialPricing
    const specialPricingArray = Object.keys(formData)
      .filter((key) => !isNaN(key)) // Only include numeric keys (service IDs)
      .map((serviceId) => {
        const serviceData = formData[serviceId];
        const serviceName =
          services.find(
            (service) => service.service_id.toString() === serviceId
          )?.service_name || "";

        return {
          service_id: parseInt(serviceId, 10),
          service_name: serviceName,
          trade_price_excl: parseFloat(serviceData?.trade_price_excl || 0),
          trade_price_incl: parseFloat(serviceData?.trade_price_incl || 0),
          retail_price_excl: parseFloat(serviceData?.retail_price_excl || 0),
          retail_price_incl: parseFloat(serviceData?.retail_price_incl || 0),
          gross_profit_excl: parseFloat(serviceData?.gross_profit || 0),
        };
      });

    console.log("Final dealership_id:", dealership_id); // Log to verify dealership_id
    console.log("Final specialPricingArray:", specialPricingArray); // Log the final payload

    // Call the API function with the transformed array
    handleAddSpecialPricing(dealership_id, specialPricingArray);

    handleCloseModal();
  };

  const handleEdit = (pricingItem) => {
    setEditingPricing(pricingItem);
    setFormData(pricingItem);
    setShowModal(true);
  };

  const handleDelete = async (service_id) => {
    try {
      const endpoint =
        activeTab === "base"
          ? `${process.env.REACT_APP_API_BASE_URL}/base-pricing/${service_id}`
          : `${process.env.REACT_APP_API_BASE_URL}/dealership-pricing/${selectedDealershipId}/${service_id}`;
      await axios.delete(endpoint, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (activeTab === "base") {
        fetchPricing();
      } else {
        handleSelectDealership(selectedDealershipId);
      }
    } catch (error) {
      console.error("Error deleting pricing:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearDealership = () => {
    setSearchText("");
    setSelectedDealershipId("");
    setDealershipPricing([]);
    setNoSpecialPricingMessage("");
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingPricing(null);
    setFormData({
      service_name: "",
      trade_price_excl: "",
      retail_price_excl: "",
    });
  };

  const handleOpenConfirmationModal = (service_id) => {
    setDeletingServiceId(service_id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingServiceId(null);
  };

  const handleConfirmDelete = () => {
    handleDelete(deletingServiceId);
    handleCloseConfirmationModal();
  };

  return (
    <div className="ManagePricingTables">
      <h2>Manage Pricing Tables</h2>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={`tab ${activeTab === "base" ? "active" : ""}`}
          onClick={() => setActiveTab("base")}
        >
          Base Pricing
        </button>
        <button
          className={`tab ${activeTab === "dealership" ? "active" : ""}`}
          onClick={() => setActiveTab("dealership")}
        >
          Dealership Pricing
        </button>
      </div>

      {activeTab === "base" ? (
        <BasePricingView
          pricing={pricing}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : (
        <SpecialPricingView
          dealershipPricing={dealershipPricing}
          onEdit={handleEdit}
          onDelete={handleDelete}
          dealerships={dealerships}
          selectedDealershipId={selectedDealershipId}
          searchText={searchText}
          onSearchChange={handleSearchChange}
          onSelectDealership={handleSelectDealership}
          onClearDealership={handleClearDealership}
          noSpecialPricingMessage={noSpecialPricingMessage}
          onAddSpecialPricing={handleAddSpecialPricing}
          services={services}
        />
      )}

      {showModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{editingPricing ? "Edit Pricing" : "Add New Pricing"}</h2>
            <form onSubmit={handleSubmit}>
              <label>Service Name</label>
              <input
                type="text"
                name="service_name"
                value={formData.service_name}
                onChange={handleChange}
                required
              />
              <label>Trade Price Excl.</label>
              <input
                type="number"
                name="trade_price_excl"
                value={formData.trade_price_excl}
                onChange={handleChange}
                required
              />
              <label>Retail Price Excl.</label>
              <input
                type="number"
                name="retail_price_excl"
                value={formData.retail_price_excl}
                onChange={handleChange}
                required
              />
              <table>
                <tbody>
                  <tr>
                    <td>Trade Price Incl.</td>
                    <td>{formData.trade_price_incl}</td>
                  </tr>
                  <tr>
                    <td>Retail Price Incl.</td>
                    <td>{formData.retail_price_incl}</td>
                  </tr>
                  <tr>
                    <td>Gross Profit Excl.</td>
                    <td>{formData.gross_profit_excl}</td>
                  </tr>
                </tbody>
              </table>
              <div className="modal-buttons">
                <button type="submit">Submit</button>
                <button type="button" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          message="Are you sure you want to delete this pricing?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCloseConfirmationModal}
        />
      )}
    </div>
  );
};

export default ManagePricingTables;
