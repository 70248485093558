// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ActivitiesModal/ActivitiesModal.css */
.activities-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .activities-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
  }
  
  .activities-actions-container {
    margin: 15px 0 0 27px;
  }
  
  .activities-close-button {
    margin: 0;
    padding: 12px 24px;
    background-color: blueviolet;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Limit the height of the activities table and make it scrollable */
  .activities-table-container {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 15px;
  }
  
  .activities-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .activities-table th,
  .activities-table td {
    border: 1px solid #ddd;
    padding: 8px;
    color: #000;
  }
  
  .activities-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ActivitiesModal/ActivitiesModal.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,SAAS;IACT,kBAAkB;IAClB,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;EACpB;;EAEA,oEAAoE;EACpE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":["/* src/components/ActivitiesModal/ActivitiesModal.css */\r\n.activities-modal {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .activities-modal-content {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    max-width: 800px;\r\n    width: 100%;\r\n  }\r\n  \r\n  .activities-actions-container {\r\n    margin: 15px 0 0 27px;\r\n  }\r\n  \r\n  .activities-close-button {\r\n    margin: 0;\r\n    padding: 12px 24px;\r\n    background-color: blueviolet;\r\n    color: white;\r\n    border: none;\r\n    cursor: pointer;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  /* Limit the height of the activities table and make it scrollable */\r\n  .activities-table-container {\r\n    max-height: 250px;\r\n    overflow-y: auto;\r\n    margin-top: 15px;\r\n  }\r\n  \r\n  .activities-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n  }\r\n  \r\n  .activities-table th,\r\n  .activities-table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    color: #000;\r\n  }\r\n  \r\n  .activities-table th {\r\n    background-color: #f2f2f2;\r\n    text-align: left;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
