import React, { useState } from "react";
import ModalPortal from "../ModalPortal/ModalPortal";
import "./PrompterModal.css";

const PrompterModal = ({ show, onClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState("scripts"); // Tabs state
  const [activeAccordion, setActiveAccordion] = useState(null); // Accordion state

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Match the duration of the slideOut animation
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (!show && !isClosing) return null;

  return (
    <ModalPortal>
      <div className="overlay" onClick={handleClose}></div>
      <div className={`prompter-modal ${isClosing ? "slide-out" : ""}`}>
        <button onClick={handleClose} className="close-button">
          X
        </button>
        <h2>Call Prompters & FAQs</h2>
        
        {/* Tabs */}
        <div className="tabs">
          <button
            className={activeTab === "scripts" ? "active" : ""}
            onClick={() => handleTabChange("scripts")}
          >
            Scripts
          </button>
          <button
            className={activeTab === "faqs" ? "active" : ""}
            onClick={() => handleTabChange("faqs")}
          >
            FAQs
          </button>
        </div>

        <div className="tab-content">
          {/* Scripts Tab */}
          {activeTab === "scripts" && (
            <div>
              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(1)}>
                  Windscreen Script
                  <span>{activeAccordion === 1 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 1 && (
                  <div className="accordion-content">
                    <p>
                      <strong>Greeting</strong><br />
                      Introduce yourself. I am phoning regarding your car that is booked in at -DEALER NAME-
                    </p>
                    <p>
                      <strong>Verify</strong><br />
                      Confirm registration.
                    </p>
                    <p>
                      <strong>Advise</strong><br />
                      Pre-inspection: Number of chips identified and where on windscreen. Suggest repair to prevent chips from spreading further.
                    </p>
                    <p>
                      <strong>Provide Information</strong><br />
                      Insurance offer (no excess), Fleet car, Account offer, We repair the 3rd and 4th chip at no extra cost.
                    </p>
                    <p>
                      <strong>Closure</strong><br />
                      Cosmetic: The chip mark will not disappear working on glass. Guarantee: 30-day money back guarantee, not a replacement guarantee.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(2)}>
                  Headlight Script
                  <span>{activeAccordion === 2 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 2 && (
                  <div className="accordion-content">
                    <p>
                      <strong>Greeting</strong><br />
                      Introduce yourself. I am phoning regarding your car that is booked in at -DEALER NAME-.
                    </p>
                    <p>
                      <strong>Verify</strong><br />
                      Confirm registration.
                    </p>
                    <p>
                      <strong>Advise</strong><br />
                      Pre-inspection: Suggest a 3-step headlight restoration based on the pre-inspection. Scratches can cause further oxidization.
                    </p>
                    <p>
                      <strong>Provide Information</strong><br />
                      Account offer, Fleet car.
                    </p>
                    <p>
                      <strong>Closure</strong><br />
                      Cosmetic: 80% optical clarity restoration. Guarantee: 1-year touch-up guarantee.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(3)}>
                  Paint Protection & Sealant Script
                  <span>{activeAccordion === 3 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 3 && (
                  <div className="accordion-content">
                    <p>
                      <strong>Greeting</strong><br />
                      Introduce yourself. I am phoning regarding your car that is booked in at -DEALER NAME-.
                    </p>
                    <p>
                      <strong>Verify</strong><br />
                      Confirm registration.
                    </p>
                    <p>
                      <strong>Advise</strong><br />
                      Promotion available. Paint protection & anti-static sealant.
                    </p>
                    <p>
                      <strong>Provide Information</strong><br />
                      UV and rust protection. 2-phase (no clay bar) or 3-phase (clay bar – hand application).
                    </p>
                    <p>
                      <strong>Closure</strong><br />
                      Cosmetic: Will not remove existing scratches. Guarantee: 2-phase 3-month / 3-phase 6-month touch-up guarantee.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* FAQs Tab */}
          {activeTab === "faqs" && (
            <div>
              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(4)}>
                  Where are you calling me from? / Where did you get my number?
                  <span>{activeAccordion === 4 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 4 && (
                  <div className="accordion-content">
                    <p>
                      I am calling remotely so I am not based at -Dealer Name-. These services are done at -Dealer Name-. Your SA will give you an invoice payable to -Dealer Name- on collection.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(5)}>
                  These chips have already been repaired
                  <span>{activeAccordion === 5 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 5 && (
                  <div className="accordion-content">
                    <p>
                      Verify old chips were done. Confirm new chips. Explain where on the windscreen and if not in direct vision. Can send photos (before/after).
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(6)}>
                  The chips are too small to repair
                  <span>{activeAccordion === 6 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 6 && (
                  <div className="accordion-content">
                    <p>
                      Educate surface chips (Stress zone). Assess depth (20%). Can send pamphlet.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(7)}>
                  I will rather replace my windscreen
                  <span>{activeAccordion === 7 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 7 && (
                  <div className="accordion-content">
                    <p>
                      Educate that a replacement comes at a high cost or an excess. A repair restores the strength of a windscreen.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(8)}>
                  Can I get a discount?
                  <span>{activeAccordion === 8 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 8 && (
                  <div className="accordion-content">
                    <p>
                      Unfortunately, this is a set price at the dealership, and no further discount can be offered.
                    </p>
                  </div>
                )}
              </div>

              <div className="accordion-section">
                <h3 onClick={() => toggleAccordion(9)}>
                  What is the 3-step headlight restoration process?
                  <span>{activeAccordion === 9 ? "-" : "+"}</span>
                </h3>
                {activeAccordion === 9 && (
                  <div className="accordion-content">
                    <p>
                      (1) Buff out scratches, (2) Apply polish to remove oxidization/cloudiness, (3) Apply sealant with UV protection.
                    </p>
                  </div>
                )}
              </div>

              {/* Add more FAQ sections here as needed */}
            </div>
          )}
        </div>
      </div>
    </ModalPortal>
  );
};

export default PrompterModal;
