// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-modal-content img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
    object-fit: contain;
}

.image-dimensions {
    position: absolute;
    bottom: 15px;
    color: white;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

.image-modal-close-button {
    position: absolute;
    top: 100px;
    right: 30px;
    background: #fff !important;
    color: #000;
    font-size: 30px;
    cursor: pointer;
    z-index: 1001;
    padding: 8px 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageModal/ImageModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,yCAAyC;IACzC,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,oCAAoC;IACpC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,2BAA2B;IAC3B,WAAW;IACX,eAAe;IACf,eAAe;IACf,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".image-modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.8);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n}\r\n\r\n.image-modal-content {\r\n    position: relative;\r\n    max-width: 90%;\r\n    max-height: 90%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.image-modal-content img {\r\n    max-width: 90%;\r\n    max-height: 90%;\r\n    border-radius: 10px;\r\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);\r\n    object-fit: contain;\r\n}\r\n\r\n.image-dimensions {\r\n    position: absolute;\r\n    bottom: 15px;\r\n    color: white;\r\n    font-size: 12px;\r\n    background-color: rgba(0, 0, 0, 0.6);\r\n    padding: 5px 10px;\r\n    border-radius: 5px;\r\n}\r\n\r\n.image-modal-close-button {\r\n    position: absolute;\r\n    top: 100px;\r\n    right: 30px;\r\n    background: #fff !important;\r\n    color: #000;\r\n    font-size: 30px;\r\n    cursor: pointer;\r\n    z-index: 1001;\r\n    padding: 8px 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
