import axios from "axios";

export const fetchTags = async (setAvailableTags) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tags`);
    setAvailableTags(response.data);  // This must be a valid function
  } catch (error) {
    console.error("Error fetching tags:", error);
  }
};
