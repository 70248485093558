import axios from 'axios';

export const completeTask = async (task) => {
  try {
    const token = localStorage.getItem('token'); // Assuming token-based authentication
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/tasks/${task.task_id}`, // Use the base URL from env variables
      {
        ...task, // Spread the entire task object, including the updated status
        task_status: 'complete', // Overwrite the task_status to be 'complete'
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('Complete task response: ', response)
    return response.data;
  } catch (error) {
    console.error('Error completing the task:', error);
    throw error;
  }
};
