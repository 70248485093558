import React, { useState } from "react";
import "./ActionFilter.css";
import InvoiceHistoryModal from "../InvoiceHistoryModal/InvoiceHistoryModal";
import HistoryModal from "../HistoryModal/HistoryModal";
import PrompterModal from "../PrompterModal/PrompterModal";
import TaskModal from "../TaskModal/TaskModal"; // Import the TaskModal

const ActionsFilter = ({ registrationNumber }) => {
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false); // Task modal state
  const [selectedTask, setSelectedTask] = useState(null); // For editing or creating a task

  // Open/Close handlers for modals
  const handleOpenInvoiceModal = () => setIsInvoiceModalOpen(true);
  const handleCloseInvoiceModal = () => setIsInvoiceModalOpen(false);

  const handleOpenHistoryModal = () => setIsHistoryModalOpen(true);
  const handleCloseHistoryModal = () => setIsHistoryModalOpen(false);

  const handleOpenPrompterModal = () => setIsPromptModalOpen(true);
  const handleClosePrompterModal = () => setIsPromptModalOpen(false);

  // Open Task Modal for creating a new task
  const handleOpenTaskModal = () => {
    setSelectedTask(null); // Ensure it's a new task
    setIsTaskModalOpen(true);
  };

  // Close Task Modal
  const handleCloseTaskModal = () => setIsTaskModalOpen(false);

  // Handle task save (for both creating and editing tasks)
  const handleTaskSave = () => {
    // Implement any necessary logic for task saving here.
    // You can trigger a data refresh or update state as needed.
    setIsTaskModalOpen(false);
  };

  return (
    <div className="action-filter">
      <h2>Actions</h2>
      <div className="action-filters">
        <a
          href="#"
          className="action-filter-link"
          onClick={handleOpenPrompterModal}
        >
          Prompters
        </a>
        <a
          href="#"
          className="action-filter-link"
          onClick={handleOpenHistoryModal}
        >
          Access History
        </a>
        <a
          href="#"
          className="action-filter-link"
          onClick={handleOpenInvoiceModal}
        >
          Invoice History
        </a>
        <a href="#" className="action-filter-link">
          SMS
        </a>
        <a
          href="#"
          className="action-filter-link"
          onClick={handleOpenTaskModal}
        >
          Add Task
        </a>
      </div>

      {/* Modals */}
      {isInvoiceModalOpen && (
        <InvoiceHistoryModal
          onClose={handleCloseInvoiceModal}
          registrationNumber={registrationNumber}
        />
      )}
      {isHistoryModalOpen && (
        <HistoryModal
          show={isHistoryModalOpen}
          onClose={handleCloseHistoryModal}
          registrationNumber={registrationNumber}
        />
      )}
      {isPromptModalOpen && (
        <PrompterModal
          show={isPromptModalOpen}
          onClose={handleClosePrompterModal}
        />
      )}
      {isTaskModalOpen && (
        <TaskModal
          onClose={handleCloseTaskModal}
          onSave={handleTaskSave}
          task={selectedTask}
          isNewTask={!selectedTask}
          registrationNumber={registrationNumber}
        />
      )}
    </div>
  );
};

export default ActionsFilter;
