// utils/fetchDealershipGroups.js

import axios from "axios";

export const fetchDealershipGroups = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dealership-groups`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data.data || []; // Return an empty array if no data
  } catch (error) {
    console.error("Error fetching dealership groups:", error);
    return []; // Return an empty array on error
  }
};
