import axios from 'axios';

export const updateTask = async (taskId, taskData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`, taskData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating task:", error);
    throw error; // Propagate the error to be handled in the calling code
  }
};
