import React, { useState, useEffect } from "react";

const ServiceAdvisorSelector = ({
  serviceAdvisors = [],
  selectedServiceAdvisors = "[]",
  handleServiceAdvisorChange,
  showServiceAdvisorDropdown,
  handleServiceAdvisorDropdownToggle,
  handleAddServiceAdvisor,
}) => {
  // Parse the `selectedServiceAdvisors` to ensure it's an array of IDs
  const parseSelectedAdvisors = (agents) => {
    if (Array.isArray(agents)) return agents;
    if (typeof agents === "string") {
      try {
        return JSON.parse(agents); // Parse JSON if valid
      } catch {
        // Convert comma-separated string (e.g., "4,2") to array
        return agents.split(",").map((id) => parseInt(id.trim(), 10));
      }
    }
    return []; // Default to empty array
  };

  const parsedSelectedAdvisors = parseSelectedAdvisors(selectedServiceAdvisors);

  // State for matched selected advisors
  const [matchedSelectedAdvisors, setMatchedSelectedAdvisors] = useState([]);

  // Initial setup to sync matched advisors once serviceAdvisors and selectedServiceAdvisors are available
  useEffect(() => {
    if (serviceAdvisors.length > 0 && parsedSelectedAdvisors.length > 0) {
      const initialMatchedAdvisors = parsedSelectedAdvisors
        .map((id) =>
          serviceAdvisors.find((advisor) => advisor.service_advisor_id === id)
        )
        .filter(Boolean); // Remove null values if an ID is not found

      setMatchedSelectedAdvisors(initialMatchedAdvisors);
    }
  }, [serviceAdvisors]); // Only sync on initial serviceAdvisors load

  // Handle checkbox toggle for advisors
  const toggleAdvisorSelection = (advisorId) => {
    const advisor = serviceAdvisors.find(
      (adv) => adv.service_advisor_id === advisorId
    );
  
    const updatedMatchedAdvisors = matchedSelectedAdvisors.some(
      (adv) => adv.service_advisor_id === advisorId
    )
      ? matchedSelectedAdvisors.filter((adv) => adv.service_advisor_id !== advisorId)
      : [...matchedSelectedAdvisors, advisor];
  
    setMatchedSelectedAdvisors(updatedMatchedAdvisors);
  
    // Pass the updated data to parent
    handleServiceAdvisorChange(
      updatedMatchedAdvisors.map((advisor) => ({
        service_advisor_id: advisor.service_advisor_id,
        service_advisor_name: advisor.service_advisor_name,
      }))
    );
  };
  
  

  return (
    <div className="service-advisor-selector" style={{ position: "relative" }}>
      {/* Dropdown Toggle */}
      <div
        onClick={handleServiceAdvisorDropdownToggle}
        className="service-advisor-dropdown-select"
      >
        {matchedSelectedAdvisors.length > 0
          ? matchedSelectedAdvisors
              .map((advisor) => advisor.service_advisor_name)
              .join(", ")
          : "Select Service Advisors"}
      </div>

      {/* Dropdown Menu */}
      {showServiceAdvisorDropdown && (
        <div
          className="service-advisor-dropdown-menu"
          onClick={(e) => e.stopPropagation()}
        >
          {serviceAdvisors.length > 0 ? (
            serviceAdvisors.map((advisor) => {
              const isChecked = matchedSelectedAdvisors.some(
                (selected) =>
                  selected.service_advisor_id === advisor.service_advisor_id
              );

              return (
                <div
                  key={advisor.service_advisor_id}
                  className="service-advisor-option"
                >
                  <input
                    type="checkbox"
                    id={`advisor-${advisor.service_advisor_id}`}
                    checked={isChecked}
                    onChange={() =>
                      toggleAdvisorSelection(advisor.service_advisor_id)
                    }
                  />
                  <label htmlFor={`advisor-${advisor.service_advisor_id}`}>
                    {advisor.service_advisor_name}
                  </label>
                </div>
              );
            })
          ) : (
            <div className="service-advisor-no-options">
              No Service Advisors Available
            </div>
          )}
        </div>
      )}

      {/* Add Advisor Button */}
      <button
        className="add-service-advisor-button"
        onClick={handleAddServiceAdvisor}
      >
        Add Service Advisor
      </button>
    </div>
  );
};

export default ServiceAdvisorSelector;
