// src/pages/Login/Login.jsx
import React, { useContext, useState } from "react";
import axios from "axios";
import "./Login.css";
import logo from "../../assets/images/starbreak-logo.png";
import { AuthContext } from "../../context/AuthContext";
import { fetchAssignedDealerships } from "../../utils/fetchAssignedDealerships";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        { username, password }
      );

      const userData = {
        username,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        id: response.data.id,
        role: response.data.role,
      };

      // Store both tokens in localStorage
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);

      // Fetch assigned dealerships and their goals
      const { dealershipIds, goals } = await fetchAssignedDealerships(userData.id);
      console.log(dealershipIds)
      console.log(goals)

      // Store dealership IDs and goals separately in localStorage
      localStorage.setItem("assignedDealershipIds", JSON.stringify(dealershipIds));
      localStorage.setItem("dealershipGoals", JSON.stringify(goals));

      login(userData); // Set user data in context
      window.location.href = "/dashboard"; // Redirect to dashboard after login
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="logo-container">
          <img src={logo} alt="Logo" />
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <label className="input-label" htmlFor="username">
            Username:
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <label className="input-label" htmlFor="password">
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <div className="remember-me-forgot-password">
            <div className="remember-me">
              <input type="checkbox" id="remember" name="remember" />
              <label htmlFor="remember">Remember me</label>
            </div>
            <div className="forgot-password">
              <a href="Forgot Password?">Forgot Password?</a>
            </div>
          </div>
          <button className="login-button" type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
