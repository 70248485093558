// utils/updateJobDealStatus.js

import axios from "axios";

/**
 * Updates the deal status and job status of a job in the jobs table.
 * @param {number} jobId - The ID of the job to update.
 * @param {string} newDealStatus - The new deal status to set for the job.
 * @param {string} newJobStatus - The new job status to set for the job (e.g., "lost").
 * @returns {Promise<void>} A promise that resolves when the update is successful.
 */
export const updateJobDealStatus = async (jobId, newDealStatus, newJobStatus) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/jobs/update-deal-status`, {
        jobId,
        deal_status: newDealStatus,  // Pass the new deal status
        job_status: newJobStatus     // Pass the new job status
      });
      if (response.status === 200) {
        console.log("Job deal and job status updated successfully.");
      } else {
        console.error("Failed to update job deal and job status.");
      }
    } catch (error) {
      console.error("Error updating job deal and job status:", error);
    }
  };
  