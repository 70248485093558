import axios from 'axios';

export const fetchWindscreenImage = async (registrationNumber) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/jobs/${registrationNumber}/images`,
      {
        withCredentials: true,
      }
    );

    // Process windscreen image path if found
    const windscreen = response.data.find(image => image.image_name.includes('windscreen'));
    if (windscreen) {
      windscreen.image_path = processImagePath(windscreen.image_path);
    }

    console.log('Windscreen image: ', windscreen);
    return windscreen;
  } catch (error) {
    console.error('Error fetching windscreen image:', error);
    return null; // Return null if an error occurs
  }
};

// Helper function to process the image path
const processImagePath = (path) => {
  const prefixToRemove = 'E:\\Apps\\starbreak\\starbreak-backend\\src';
  if (path.startsWith(prefixToRemove)) {
    return path.replace(prefixToRemove, '').replace(/\\/g, '/'); // Normalize to forward slashes
  }
  return path; // Return the original path if no match
};
