import React from "react";

const GroupSelector = ({
  dealershipGroups,
  dealershipGroupValue,
  handleGroupChange,
  handleAddGroupClick,
}) => {

  return (
    <div className="dealership-form-group group-selection-container">
      {/* Dropdown for Dealership Groups */}
      <div className="styled-dropdown dealership-group-select">
        <select
          name="dealership_group"
          value={dealershipGroupValue}
          onChange={handleGroupChange}
          className="styled-input styled-select"
        >
          <option value="">Select a group</option>
          {Array.isArray(dealershipGroups) && dealershipGroups.length > 0 ? (
            dealershipGroups.map((group) => (
              <option key={group.group_id} value={group.group_id}>
                {group.group_name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No groups available
            </option>
          )}
        </select>
      </div>
      {/* Button to Add/Edit Groups */}
      <div className="add-dealership-group">
        <button
          type="button"
          className="add-group-button"
          onClick={handleAddGroupClick}
        >
          Add/Edit Group
        </button>
      </div>
    </div>
  );
};

export default GroupSelector;
