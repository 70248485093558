// src/context/SessionProvider.jsx
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SessionExpiredModal from '../components/SessionExpiredModal/SessionExpiredModal';
import { getUserFromToken, isTokenExpired, refreshToken } from '../utils/auth';

export const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const token = localStorage.getItem('token');
      const refreshTokenValue = localStorage.getItem('refreshToken');

      if (!token) return;

      if (isTokenExpired(token)) {
        try {
          await refreshToken();
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleSessionExpired();
        }
      } else if (!refreshTokenValue || isTokenExpired(refreshTokenValue)) {
        handleSessionExpired();
      }
    };

    const interval = setInterval(checkSession, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSessionExpired = () => {
    setIsSessionExpired(true);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
  };

  return (
    <SessionContext.Provider value={{ isSessionExpired, setIsSessionExpired }}>
      {children}
      <SessionExpiredModal
        isOpen={isSessionExpired}
        onConfirm={handleSessionExpired}
      />
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionProvider;
