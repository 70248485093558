import axios from 'axios';

export const createTask = async (taskData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/tasks`, taskData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating task:", error);
    throw error; // Propagate the error to be handled in the calling code
  }
};
