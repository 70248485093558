import React, { useState, useEffect } from "react";
import { fetchUsersActivityStatus } from "../../utils/fetchUsersActivityStatus"; // Assuming this is your fetch utility
import "./ManageReports.css";

const ManageReports = () => {
  const [activeTab, setActiveTab] = useState("Technicians");
  const [technicianActivityStatus, setTechnicianActivityStatus] = useState([]);
  const [callCenterActivityStatus, setCallCenterActivityStatus] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null); // State to store selected card
  const [filteredData, setFilteredData] = useState([]); // State for the filtered data

  useEffect(() => {
    const fetchActivityStatus = async () => {
      try {
        const activityData = await fetchUsersActivityStatus();

        // Filter based on user_role
        const technicians = activityData.filter(
          (user) => user.user_role === "technician" // Matching role correctly
        );
        const callCenterAgents = activityData.filter(
          (user) => user.user_role === "call center agent" // Matching role correctly
        );

        setTechnicianActivityStatus(technicians);
        setCallCenterActivityStatus(callCenterAgents);
      } catch (error) {
        console.error("Error fetching users activity status:", error);
      }
    };

    fetchActivityStatus();
  }, []);

  const technicianCards = {
    generalPerformance: [
      { title: "Surveyed Cars", number: 10 },
      { title: "Pending Jobs", number: 8 },
      { title: "Approved Jobs", number: 5 },
      { title: "Lost Jobs", number: 5 },
      { title: "Won Jobs", number: 6 },
      { title: "Completed Jobs", number: 15 },
    ],
    services: [
      { title: "Chips", number: 12 },
      { title: "WS Replacement", number: 7 },
      { title: "HLR", number: 4 },
      { title: "Polish", number: 4 },
    ],
  };

  const callCenterCards = {
    generalPerformance: [
      { title: "New", number: 100 },
      { title: "Pending", number: 10 },
      { title: "Lost", number: 10 },
      { title: "Won", number: 10 },
      { title: "% Conversion", number: 30 },
    ],
    services: [
      { title: "Calls Made", number: 12 },
      { title: "STC", number: 7 },
      { title: "N/A", number: 4 },
    ],
    additionalServices: [
      { title: "Chips", number: "12/40" },
      { title: "WS Replacement", number: "7/10" },
      { title: "HLR", number: "4/10" },
      { title: "Polish", number: "10/10" },
    ],
  };

  // Placeholder for filter options
  const filters = {
    date: ["Today", "Last Week", "Last Month"],
    user: ["John Doe", "Jane Smith", "Bob Johnson"],
    dealership: ["Dealership A", "Dealership B", "Dealership C"],
  };

  // Handle card click to display relevant data
  const handleCardClick = (card) => {
    setSelectedCard(card);
    // Simulate setting filtered data (just placeholder values for now)
    setFilteredData([
      { name: "Item 1", detail: "Detail A" },
      { name: "Item 2", detail: "Detail B" },
    ]);
  };

  const renderCards = (cardSection) => {
    return cardSection.map((card, index) => (
      <div
        key={index}
        className="report-card"
        onClick={() => handleCardClick(card)}
      >
        <div className="card-number">{card.number}</div>
        <div className="card-title">{card.title}</div>
      </div>
    ));
  };

  const renderSection = (sectionTitle, cardSection) => {
    if (cardSection && cardSection.length > 0) {
      return (
        <div className="card-section">
          <h3 className="section-title">{sectionTitle}</h3>
          <div className="cards-wrapper">{renderCards(cardSection)}</div>
        </div>
      );
    }
    return null; // Don't render section if cardSection is empty or undefined
  };

  const renderContentForTab = () => {
    const cards =
      activeTab === "Technicians" ? technicianCards : callCenterCards;

    return (
      <>
        {renderSection("General Performance", cards.generalPerformance)}
        {renderSection("Services", cards.services)}
        {/* Conditionally render additional sections if they exist */}
        {renderSection("Additional Services", cards.additionalServices)}
      </>
    );
  };

  // Function to render the appropriate activity status based on the active tab
  const renderActivityStatus = () => {
    const currentActivityStatus =
      activeTab === "Technicians"
        ? technicianActivityStatus
        : callCenterActivityStatus;
    return currentActivityStatus.map((user, index) => (
      <div key={index} className={`user-status ${user.activity_status.toLowerCase()}`}>
        <span className={`status-dot ${user.activity_status.toLowerCase()}`}></span>
        <span className="user-name">{`${user.user_first_name} ${user.user_last_name}`}</span>
        <span className="status-text">{user.activity_status}</span>
      </div>
    ));
  };

  // Rendering the table with filtered data after a card click
  const renderTable = () => {
    if (!selectedCard) return null;

    return (
      <div className="data-table">
        <h3>{`Data for: ${selectedCard.title}`}</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.detail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="ManageReports">
      <div className="main-reports-content">
        {/* Left Section: Tabs and Report Cards */}
        <div className="left-content">
          <h2 className="reports-heading">Reports</h2>
          <div className="tabs">
            <button
              className={`tab ${activeTab === "Technicians" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("Technicians")}
            >
              Technicians
            </button>
            <button
              className={`tab ${activeTab === "Call Center Agents" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("Call Center Agents")}
            >
              Call Center Agents
            </button>
          </div>

          {/* Filters */}
          <div className="filters">
            <select>
              <option>Date</option>
              {filters.date.map((filter, index) => (
                <option key={index} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
            <select>
              <option>By User</option>
              {filters.user.map((filter, index) => (
                <option key={index} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
            <select>
              <option>By Dealership</option>
              {filters.dealership.map((filter, index) => (
                <option key={index} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
          </div>

          <div className="cards-container">{renderContentForTab()}</div>

          {/* Render the table if a card is selected */}
          {renderTable()}
        </div>

        {/* Right Section: Activity Status */}
        <div className="activity-status-section">
          <h2 className="activity-status-heading">Activity Status</h2>
          <div className="activity-status-card">
            <div className="activity-status-container">
              {renderActivityStatus()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReports;
