import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import CallModal from "../../components/CallModal/CallModal";
import { fetchClaimDetails } from "../../utils/fetchClaimDetails";
import { updateClaimStatus } from "../../utils/updateClaimStatus";
import { createNotification } from "../../utils/createNotification";
import { fetchAssignedAgent } from "../../utils/fetchAssignedAgent";
import { createTask } from "../../utils/createTask"; // Import the createTask function
import "./Insurance.css";

const Insurance = () => {
  const [insuranceData, setInsuranceData] = useState([]);
  const [activeTab, setActiveTab] = useState("Pending Authorization");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [selectedVehicleReg, setSelectedVehicleReg] = useState(null);
  const [selectedJob, setSelectedJob] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Open modal and set the selected insurance
  const openModal = (insurance) => {
    setSelectedInsurance(insurance);
    setIsModalOpen(true);
  };

  // Open CallModal and set the selected vehicle registration
  const openCallModal = (vehicleReg, jobId) => {
    setSelectedVehicleReg(vehicleReg);
    setSelectedJob(jobId);
    setIsCallModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInsurance(null);
    setSelectedJob("");
  };

  // Close CallModal
  const closeCallModal = () => {
    setIsCallModalOpen(false);
    setSelectedVehicleReg(null);
    setSelectedJob("");
  };

  // Fetch insurance claim data from the backend API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchClaimDetails();
        setInsuranceData(data);
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };
  
    // Fetch data immediately on mount
    fetchData();
  
    // Set up polling to fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 30000);
  
    // Clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Filter data based on the active tab
  const filterInsuranceData = () => {
    if (activeTab === "Pending Authorization") {
      return insuranceData.filter(
        (insurance) => !insurance.authorized && !insurance.declined
      );
    } else if (activeTab === "Authorized") {
      return insuranceData.filter((insurance) => insurance.authorized);
    } else if (activeTab === "Declined") {
      return insuranceData.filter((insurance) => insurance.declined);
    }
    return insuranceData;
  };

  // Helper function to create a notification and a task
  const createNotificationHandler = async (
    claimId,
    status,
    assignedAgent,
    vehicleReg,
    jobId // Ensure jobId is passed
  ) => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);

    if (!user || !user.id) {
      console.error("Sender ID is missing.");
      return;
    }

    const senderId = user.id;
    const recipientId = assignedAgent;

    if (!recipientId) {
      console.error("Recipient ID is missing for the insurance claim.");
      return;
    }

    const notificationData = {
      sender_id: senderId,
      recipient_id: assignedAgent?.agent || recipientId,
      notification_title: `Claim ${status} - ${vehicleReg}`,
      notification_content: `Your insurance claim for ${vehicleReg} has been ${status}.`,
      notification_status: "unread",
    };

    try {
      await createNotification(notificationData);

      // After notification, create a task for the assigned agent
      const taskData = {
        task_title: `Contact Client Regarding Insurance Claim ${status}`,
        task_description: `Please contact the client and notify them that their insurance claim for vehicle ${vehicleReg} has been ${status}.`,
        task_registration_number: vehicleReg, // Pass the vehicle registration number
        task_created_at: new Date(),
        task_user_assigned: assignedAgent?.agent || recipientId,
        task_status: "incomplete",
        task_tags: [
          {
            id: 4, // Assuming the ID for the Insurance tag
            name: "TCB",
            color: "#8ACCFF", // Example color
            type: "system",
            variants: [
              {
                tag_id: 4,
                variant_name: status === "authorized" ? "Approved" : "Declined",
                color: status === "authorized" ? "#4CAF50" : "#F44336", // Green for approved, red for declined
              },
            ],
          },
        ], // Correct task tag format
        task_due_date: new Date(Date.now() + 24 * 60 * 60 * 1000), // Set due date to 1 day later
        task_due_time: "17:00", // Example time, adjust as needed
        job_id: jobId, // Include the job_id in the task data
      };

      await createTask(taskData);
    } catch (error) {
      console.error(
        `Error sending ${status} notification or creating task:`,
        error
      );
    }
  };

  // Handle 'Authorized' action
  const handleAuthorize = async (insuranceId, jobId, vehicleReg) => {
    try {
      const assignedAgent = await fetchAssignedAgent(jobId);

      await updateClaimStatus(insuranceId, {
        authorized: true,
        declined: false,
      });

      setInsuranceData((prevData) =>
        prevData.filter((insurance) => insurance.claim_id !== insuranceId)
      );

      // Pass jobId to createNotificationHandler
      await createNotificationHandler(
        insuranceId,
        "authorized",
        assignedAgent,
        vehicleReg,
        jobId // Ensure jobId is passed
      );
    } catch (error) {
      console.error("Error updating authorized status:", error);
    }
  };

  // Handle 'Declined' action
  const handleDecline = async (insuranceId, jobId, vehicleReg) => {
    try {
      const assignedAgent = await fetchAssignedAgent(jobId);

      await updateClaimStatus(insuranceId, {
        authorized: false,
        declined: true,
      });

      setInsuranceData((prevData) =>
        prevData.filter((insurance) => insurance.claim_id !== insuranceId)
      );

      // Pass jobId to createNotificationHandler
      await createNotificationHandler(
        insuranceId,
        "declined",
        assignedAgent,
        vehicleReg,
        jobId // Ensure jobId is passed
      );
    } catch (error) {
      console.error("Error updating declined status:", error);
    }
  };

  const capitalizeServiceKey = (key) => {
    const keyMap = {
      headlight: "Headlight",
      windscreen: "Windscreen",
      polish: "Paint Protection",
    };
    return keyMap[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  const fetchData = async () => {
    try {
      const data = await fetchClaimDetails();
  
      // Merge new data with existing data if necessary
      setInsuranceData((prevData) => {
        const existingIds = prevData.map((claim) => claim.claim_id);
        const newClaims = data.filter((claim) => !existingIds.includes(claim.claim_id));
        return [...newClaims, ...prevData];
      });
    } catch (error) {
      console.error("Error fetching insurance data:", error);
    }
  };
  

  return (
    <div className="insurance-container">
      <Header />
      <div className="insurance-page-content">
        <Sidebar />
        <main className="insurance-main-content">
          <h2>Insurance</h2>

          {/* Tabs */}
          <div className="tabs">
            <button
              className={`tab ${
                activeTab === "Pending Authorization" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("Pending Authorization")}
            >
              Pending Authorization
            </button>
            <button
              className={`tab ${
                activeTab === "Authorized" ? "active-tab" : ""
              }`}
              onClick={() => handleTabChange("Authorized")}
            >
              Authorized
            </button>
            <button
              className={`tab ${activeTab === "Declined" ? "active-tab" : ""}`}
              onClick={() => handleTabChange("Declined")}
            >
              Declined
            </button>
            <button
              className={`tab ${activeTab === "All" ? "active-tab" : ""}`}
              onClick={() => handleTabChange("All")}
            >
              All
            </button>
          </div>

          {/* Table */}
          <div className="insurance-table-container">
            <table>
              <thead>
                <tr>
                  <th>Job #</th>
                  <th>Vehicle Reg</th>
                  <th>Name</th>
                  <th>Last Name</th>
                  <th>Contact Number</th>
                  <th>Insurance Broker</th>
                  <th>Policy Number</th>
                  <th>Fleet</th>
                  <th>Services</th>
                  <th>Information</th>
                  <th>JC Details</th>
                  <th>Authorized</th>
                  <th>Declined</th>
                </tr>
              </thead>
              <tbody>
                {filterInsuranceData().map((insurance, index) => (
                  <tr key={index}>
                    <td>{insurance.job_id}</td>
                    <td>{insurance.vehicle_registration}</td>
                    <td>{insurance.owner_name}</td>
                    <td>{insurance.owner_last_name}</td>
                    <td>{insurance.contact_number}</td>
                    <td>{insurance.insurance_company}</td>
                    <td>{insurance.policy_number}</td>
                    <td>{insurance.is_fleet}</td>
                    <td>
                      {(() => {
                        try {
                          const services = JSON.parse(
                            insurance.services_required || "[]"
                          );

                          if (Array.isArray(services)) {
                            // Filter and map to render services as individual lines
                            const formattedServices = services
                              .filter(({ selected }) => selected) // Only include selected services
                              .map(({ key, option }) => {
                                const label = capitalizeServiceKey(key);
                                return (
                                  <div key={key}>
                                    {label}
                                    {option ? ` - ${option}` : ""}
                                  </div>
                                );
                              });

                            return formattedServices.length > 0 ? (
                              formattedServices
                            ) : (
                              <div>No services selected</div>
                            );
                          }

                          return <div>Invalid services data</div>;
                        } catch (error) {
                          console.error("Error parsing services:", error);
                          return <div>Invalid services data</div>;
                        }
                      })()}
                    </td>
                    <td>
                      <button
                        className="insurance-info-icon"
                        onClick={() => openModal(insurance)}
                      >
                        <span className="material-symbols-outlined">info</span>
                      </button>
                    </td>
                    <td>
                      <button
                        className="insurance-info-icon"
                        onClick={() =>
                          openCallModal(
                            insurance.vehicle_registration,
                            insurance.job_id
                          )
                        }
                      >
                        <span className="material-symbols-outlined">call</span>
                      </button>
                    </td>
                    <td>
                      <button
                        className="action-button authorize"
                        onClick={() =>
                          handleAuthorize(
                            insurance.claim_id,
                            insurance.job_id,
                            insurance.vehicle_registration
                          )
                        }
                      >
                        Authorize
                      </button>
                    </td>
                    <td>
                      <button
                        className="action-button decline"
                        onClick={() =>
                          handleDecline(
                            insurance.claim_id,
                            insurance.job_id,
                            insurance.vehicle_registration
                          )
                        }
                      >
                        Decline
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Modal for complete information */}
          {isModalOpen && (
            <div className="insurance-modal">
              <div className="insurance-modal-content">
                <h3>Complete Information</h3>
                <form>
                  <div className="insurance-form-group">
                    <label>Does the client have an active policy?</label>
                    <input type="text" placeholder="Enter policy number" />
                  </div>
                  <div className="insurance-form-group">
                    <label>Is the car on the policy?</label>
                    <select>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="insurance-form-group">
                    <label>
                      Does the policy cover chip repairs with no excess?
                    </label>
                    <select>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="insurance-form-group">
                    <label>Are premiums up to date?</label>
                    <select>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="insurance-form-group">
                    <label>Are all inspections done?</label>
                    <select>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="insurance-modal-buttons">
                    <button className="insurance-button" type="submit">
                      Save
                    </button>
                    <button
                      type="button"
                      className="insurance-button close-modal"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* CallModal for JC Details */}
          {isCallModalOpen && (
            <CallModal
              show={isCallModalOpen}
              onClose={closeCallModal}
              contact={{ vehicleReg: selectedVehicleReg }}
              job={{ jobId: selectedJob }}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default Insurance;
