import React, { useEffect, useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard";
import CallCabinetSetup from "./pages/CallCabinetSetup/CallCabinetSetup";
import SystemErrors from "./pages/SystemErrors/SystemErrors";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import { Pipeline } from "./pages/Pipeline/Pipeline";
import { Contacts } from "./pages/Contacts/Contacts";
import { Administration } from "./pages/Administration/Administration";
import SessionProvider from "./context/SessionProvider";
import TaskReminder from "./components/TaskReminderModal/TaskReminderModal";
import { AuthContext } from "./context/AuthContext";
import { refreshToken, isTokenExpired } from "./utils/auth";
import Invoicing from "./pages/Accounting/Invoicing";
import Insurance from "./pages/Insurance/Insurance";

const AppContent = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation(); // Now safely using useLocation inside the Router context

  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshTokenValue = localStorage.getItem("refreshToken");

    // Only refresh the token if the user is logged in and not on the login page
    if (token && location.pathname !== "/login") {
      if (!isTokenExpired(token)) {
        refreshToken(token);
      } else if (!refreshTokenValue || isTokenExpired(refreshTokenValue)) {
        console.error("Refresh token is either expired or not found.");
      }
    }
  }, [user, location]);

  return (
    <div className="App">
      {/* Conditionally render TaskReminder only when user is logged in */}
      {user && location.pathname !== "/login" && <TaskReminder />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/call-cabinet-setup" element={<CallCabinetSetup />} />
          <Route path="/admin-system-errors" element={<SystemErrors />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/pipeline" element={<Pipeline />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/admin" element={<Administration />} />
          <Route path="/invoicing" element={<Invoicing />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => (
  <SessionProvider>
    <AppContent />
  </SessionProvider>
);

export default App;
