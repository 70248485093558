import axios from "axios";

/**
 * Fetches the base pricing from the backend API.
 * @returns {Promise<Object>} Resolves to the base pricing data or throws an error.
 */
export const fetchBasePricing = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/base-pricing`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch base pricing.");
    }
  } catch (error) {
    console.error("Error fetching base pricing:", error);
    throw error;
  }
};
