import axios from "axios";

export const deleteDealershipGroup = async (groupId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/dealership-groups/${groupId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting dealership group:", error);
    throw error;
  }
};

