import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import FormModal from "../FormModal/FormModal";
import "./AdminDashboard.css";

const formConfigs = {
  newUser: {
    heading: "Add New User",
    fields: [
      { name: "firstName", label: "First Name", type: "text" },
      { name: "lastName", label: "Last Name", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "tel" },
      { name: "milti-branch", label: "Branch Access", type: "text" },
      { name: "role", label: "Role", type: "text" },
      { name: "group", label: "Group", type: "text" },
    ],
  },
  newInsuranceCompany: {
    heading: "Add New Insurance Company",
    fields: [
      { name: "companyName", label: "Company Name", type: "text" },
      { name: "contactNumber", label: "Contact Number", type: "tel" },
      { name: "emailAddress", label: "Email Address", type: "email" },
    ],
  },
  newDealership: {
    heading: "Add New Dealership",
    fields: [
      { name: "dealershipName", label: "Dealership Name", type: "text" },
      { name: "serviceAgent", label: "Service Agent", type: "text" },
      { name: "contactNumber", label: "Contact Number", type: "tel" },
      { name: "technicianAssigned", label: "Technician Assigned", type: "text" },
    ],
  },
  newVehicleType: {
    heading: "Add New Vehicle Type",
    fields: [
      { name: "vehicleType", label: "Vehicle Type", type: "text" },
      { name: "vehicleMake", label: "Vehicle Make", type: "text" },
      { name: "vehicleModel", label: "Vehicle Model", type: "text" },
      { name: "vehicleYear", label: "Vehicle Year", type: "number" },
    ],
  },
};

export { formConfigs };

const AdminDashboard = () => {
  const [modalConfig, setModalConfig] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const openModal = (configKey) => {
    setModalConfig(formConfigs[configKey]);
    setActiveButton(configKey);
  };

  const closeModal = () => {
    setModalConfig(null);
    setActiveButton(null);
  };

  return (
    <div>
      <div className="icon-buttons-container">
        <div onClick={() => openModal("newUser")}>
          <FaPlusCircle
            className={`icon-button ${activeButton === "newUser" ? "active" : ""}`}
            title="Add New User"
          />
        </div>
        <div onClick={() => openModal("newInsuranceCompany")}>
          <FaPlusCircle
            className={`icon-button ${activeButton === "newInsuranceCompany" ? "active" : ""}`}
            title="Add New Insurance Company"
          />
        </div>
        <div onClick={() => openModal("newDealership")}>
          <FaPlusCircle
            className={`icon-button ${activeButton === "newDealership" ? "active" : ""}`}
            title="Add New Dealership"
          />
        </div>
        <div onClick={() => openModal("newVehicleType")}>
          <FaPlusCircle
            className={`icon-button ${activeButton === "newVehicleType" ? "active" : ""}`}
            title="Add New Vehicle Type"
          />
        </div>
      </div>

      {modalConfig && <FormModal closeModal={closeModal} {...modalConfig} />}
    </div>
  );
};

export default AdminDashboard;
