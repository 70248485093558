// utils/fetchCallCenterAgents.js

import axios from 'axios';

export const fetchCallCenterAgents = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users/call-center-agents`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching call center agents:", error);
    return [];
  }
};
