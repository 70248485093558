import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import ManageUsersModal from "./ManageUsersModal/ManageUsersModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import axios from "axios";
import "./ManageUsers.css";

const ManageUsers = () => {
  const initialUsers = [];

  const [users, setUsers] = useState(initialUsers);
  const [formData, setFormData] = useState({
    user_id: "",
    username: "",
    user_first_name: "",
    user_last_name: "",
    user_email_address: "",
    user_role: "user",
    multi_branch: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [deletingUserId, setDeletingUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        );
        console.log(response);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (data) => {
    try {
      if (editingUser) {
        // Update existing user
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/users`, data, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      } else {
        // Create new user
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users`, data, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error creating/updating user:", error);
    }
    setShowModal(false);
    setEditingUser(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/users/${deletingUserId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setUsers(users.filter((user) => user.user_id !== deletingUserId));
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const handleOpenModal = () => {
    setFormData({
      user_id: "",
      username: "",
      user_first_name: "",
      user_last_name: "",
      user_email_address: "",
      user_role: "technician",
      multi_branch: false,
    });
    setEditingUser(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  const handleOpenConfirmationModal = (user_id) => {
    setDeletingUserId(user_id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingUserId(null);
  };

  const handleRowClick = (user) => {
    setFormData(user);
    setEditingUser(user);
    setShowModal(true);
  };

  return (
    <div className="ManageUsers">
      <h2>Manage Users</h2>
      <div className="icon-container" onClick={handleOpenModal}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>
      {showModal && (
        <ManageUsersModal
          isOpen={showModal}
          onClose={handleCloseModal}
          initialFormData={formData}
          onChange={handleChange}
          onSubmit={handleSubmit}
          heading={editingUser ? "Edit User" : "Add New User"}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          message="Are you sure you want to delete this user?"
          onConfirm={handleDelete}
          onCancel={handleCloseConfirmationModal}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Created At</th>
            <th>Last Login</th>
            <th>Role</th>
            <th>Multi Branch</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.user_id}>
              <td onClick={() => handleRowClick(user)}>{user.username}</td>
              <td onClick={() => handleRowClick(user)}>{user.user_first_name}</td>
              <td onClick={() => handleRowClick(user)}>{user.user_last_name}</td>
              <td onClick={() => handleRowClick(user)}>{user.user_email_address}</td>
              <td onClick={() => handleRowClick(user)}>
                {new Date(user.user_created_at).toLocaleString()}
              </td>
              <td onClick={() => handleRowClick(user)}>
                {new Date(user.user_last_login).toLocaleString()}
              </td>
              <td onClick={() => handleRowClick(user)}>{user.user_role}</td>
              <td onClick={() => handleRowClick(user)}>
                {user.multi_branch ? "Yes" : "No"}
              </td>
              <td className="actions-cell">
                <button
                  onClick={() => handleOpenConfirmationModal(user.user_id)}
                  className="delete-button"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUsers;
