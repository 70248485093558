import axios from 'axios';

export const searchInsuranceCompanies = async (query) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/insurance-companies`, {
      params: { q: query },
    });
    return response.data; // The list of insurance companies matching the query
  } catch (error) {
    console.error("Error searching insurance companies:", error);
    return [];
  }
};
