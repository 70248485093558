import React, { useState } from "react";
import "./SupportModal.css";

const SupportModal = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate inputs
    if (!email || !message) {
      alert("Please fill in both the email and message fields.");
      setIsSubmitting(false);
      return;
    }

    // Call the submit function passed from the parent
    await onSubmit({ email, message });

    // Close the modal after submitting
    setIsSubmitting(false);
    onClose();
  };

  return (
    <>
      <div className="overlay" onClick={onClose} /> {/* Overlay */}
      <div className="support-modal">
        <h3>Contact Support</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email address"
              required
            />
          </div>
          <div className="form-group">
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Describe your issue"
              required
            />
          </div>
          <div className="form-actions">
            <button
              className="send-support-btn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send"}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="support-cancel-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SupportModal;
