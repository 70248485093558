import axios from "axios";

/**
 * Fetch the dealership group based on the dealership name.
 * @param {string} dealershipName - The name of the dealership.
 * @returns {Promise<string>} - Returns the dealership group.
 */
export const fetchDealershipGroup = async (dealershipName) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/dealership-group`,
      {
        params: { dealership: dealershipName },
        withCredentials: true, // Include credentials if required
      }
    );

    if (response.data && response.data.dealershipGroup) {
      return response.data.dealershipGroup;
    } else {
      console.error("Dealership group not found in response.");
      return "Unknown Group";
    }
  } catch (error) {
    console.error("Error fetching dealership group:", error);
    return "Unknown Group"; // Fallback value on error
  }
};
