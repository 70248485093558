import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import "./Notes.css";

const Notes = ({ dealId, registrationNumber }) => {
  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState("");
  const { user } = useContext(AuthContext);

  // Fetch comments when component mounts
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/comments/${dealId}`);
        const fetchedNotes = response.data.data.map((comment) => {
          const date = new Date(comment.created_at);
          const formattedDate = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
            .format(date)
            .replace(/\//g, "-");
          const formattedTime = date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          return {
            text: comment.comment,
            user: comment.comment_user,
            date: formattedDate,
            time: formattedTime,
          };
        });
        setNotes(fetchedNotes);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [dealId]);

  const handleAddNote = async () => {
    if (noteText.trim() !== "") {
      const currentTime = new Date();
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
        .format(currentTime)
        .replace(/\//g, "-");
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const newNote = {
        text: noteText,
        user: `${user.username} ${user.lastName}`,
        date: formattedDate,
        time: formattedTime,
      };
      setNotes([newNote, ...notes]);
      setNoteText("");

      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/comments`, {
          comment: noteText,
          comment_type: "deal",
          registration_number: registrationNumber,
          comment_user: `${user.username} ${user.lastName}`, // User's full name
          deal_id: dealId,
          user_id: user.id, // User ID
        });
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddNote();
    }
  };

  return (
    <div className="notes-container">
      <div className="notes">
        {notes.map((note, index) => (
          <div key={index} className="note">
            <div className="note-header">
              <span className="note-time">{note.time} |</span>
              <strong>{note.user}</strong>
              <span className="note-date">| {note.date}</span>
            </div>
            <div className="note-text">{note.text}</div>
          </div>
        ))}
      </div>
      <div className="notes-footer">
        <input
          type="text"
          placeholder="Add a note ...."
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="notes-button" onClick={handleAddNote}>
          Add
        </button>
      </div>
    </div>
  );
};

export default Notes;
