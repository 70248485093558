import React, { useState, useEffect } from "react";
import updateUserPassword from "../../../utils/updateUserPassword";
import "./ManageUsersModal.css";

const ManageUsersModal = ({
  isOpen,
  onClose,
  initialFormData,
  onChange,
  onSubmit,
  heading,
}) => {
  const [formData, setFormData] = useState(initialFormData);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData); // Sync form data with initialFormData when modal opens
    }
  }, [isOpen, initialFormData]);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;
    const updatedFormData = { ...formData, [name]: updatedValue };
    setFormData(updatedFormData); // Update local formData state
    onChange(e); // Notify the parent component of changes
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formData); // Pass updated formData to parent
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error("Failed to save user:", error);
    }
  };

  const handleCancel = () => {
    onClose(); // Simply close the modal
  };

  const handleResetPassword = async () => {
    try {
      await updateUserPassword(formData.user_id, newPassword);
      setShowResetPasswordModal(false);
      setNewPassword("");
    } catch (error) {
      console.error("Failed to reset password:", error);
    }
  };

  return (
    <div className="manage-users-modal-overlay" onClick={handleCancel}>
      <div className="manage-users-modal" onClick={(e) => e.stopPropagation()}>
        <div className="manage-users-modal-header">
          <h2>{heading}</h2>
          <button className="close-button" onClick={handleCancel}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSave} className="manage-users-modal-form">
          <div className="user-modal-form-group">
            <label className="manage-users-form-label" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="user-modal-form-group">
            <label
              className="manage-users-form-label"
              htmlFor="user_first_name"
            >
              First Name
            </label>
            <input
              type="text"
              id="user_first_name"
              name="user_first_name"
              value={formData.user_first_name || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="user-modal-form-group">
            <label className="manage-users-form-label" htmlFor="user_last_name">
              Last Name
            </label>
            <input
              type="text"
              id="user_last_name"
              name="user_last_name"
              value={formData.user_last_name || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="user-modal-form-group">
            <label
              className="manage-users-form-label"
              htmlFor="user_email_address"
            >
              Email
            </label>
            <input
              type="email"
              id="user_email_address"
              name="user_email_address"
              value={formData.user_email_address || ""}
              onChange={handleInputChange}
            />
          </div>
          {!formData.user_id && (
            <div className="user-modal-form-group">
              <label
                className="manage-users-form-label"
                htmlFor="user_password"
              >
                Password
              </label>
              <input
                type="password"
                id="user_password"
                name="user_password"
                value={formData.user_password || ""}
                onChange={handleInputChange}
                required
              />
            </div>
          )}

          {formData.user_id && (
            <div className="user-modal-form-group">
              <label className="manage-users-form-label">Password</label>
              <button
                type="button"
                className="reset-password-button"
                onClick={() => setShowResetPasswordModal(true)}
              >
                Reset Password
              </button>
            </div>
          )}
          <div className="user-modal-form-group">
            <label className="manage-users-form-label" htmlFor="user_role">
              Role
            </label>
            <select
              id="user_role"
              name="user_role"
              value={formData.user_role || "user"}
              onChange={handleInputChange}
            >
              <option value="admin">Admin</option>
              <option value="technician">Technician</option>
              <option value="call center agent">Call Center Agent</option>
            </select>
          </div>
          <div className="user-modal-form-group">
            <label className="manage-users-form-label" htmlFor="multi_branch">
              Multi Branch
            </label>
            <input
              type="checkbox"
              id="multi_branch"
              name="multi_branch"
              checked={!!formData.multi_branch}
              onChange={handleInputChange}
            />
          </div>

          <div className="user-modal-modal-buttons">
            <button type="submit" className="user-modal-save-button">
              Save
            </button>
            <button
              type="button"
              className="user-modal-cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>

        {showResetPasswordModal && (
          <div
            className="reset-password-modal-overlay"
            onClick={() => setShowResetPasswordModal(false)}
          >
            <div
              className="reset-password-modal"
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Reset Password</h3>
              <input
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div className="user-modal-modal-buttons">
                <button
                  type="button"
                  className="user-modal-save-button"
                  onClick={handleResetPassword}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="user-modal-cancel-button"
                  onClick={() => setShowResetPasswordModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUsersModal;
