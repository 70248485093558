import axios from "axios";

/**
 * Create a new dealership group in the database.
 * @param {Object} groupData - The data for the new group.
 * @param {string} groupData.group_name - The name of the dealership group.
 * @param {string} groupData.description - The description of the dealership group.
 * @returns {Promise<Object>} - Returns the API response.
 */
export const createDealershipGroup = async (groupData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/dealership-groups`,
      groupData,
      { withCredentials: true } // Include credentials if required
    );

    if (response.data && response.data.success) {
      return response.data;
    } else {
      throw new Error("Failed to create dealership group.");
    }
  } catch (error) {
    console.error("Error creating dealership group:", error);
    throw error; // Propagate error to the caller
  }
};
