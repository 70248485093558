import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import axios from 'axios';
import FormModal from "../FormModal/FormModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import "./ManageInsuranceCompanies.css";

const ManageInsuranceCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useState({
    company_name: "",
    telephone_number: "",
    email_address: "",
    status: "unknown",
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [deletingCompanyId, setDeletingCompanyId] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/insurance-companies`);
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure no undefined values in formData
    const cleanedFormData = { ...formData };
    Object.keys(cleanedFormData).forEach(key => {
      if (cleanedFormData[key] === undefined) {
        cleanedFormData[key] = null;
      }
    });

    try {
      if (editingCompany) {
        const updatedCompanies = companies.map((company) =>
          company.company_id === editingCompany.company_id
            ? { ...company, ...cleanedFormData }
            : company
        );
        setCompanies(updatedCompanies);
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/insurance-companies/${editingCompany.company_id}`, cleanedFormData);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/insurance-companies`, cleanedFormData);
        const newCompany = { ...cleanedFormData, company_id: response.data.insertId };
        setCompanies([...companies, newCompany]);
      }
      setFormData({
        company_name: "",
        telephone_number: "",
        email_address: "",
        status: "unknown",
      });
      setShowModal(false);
      setEditingCompany(null);
    } catch (error) {
      console.error("Error submitting form: ", error); // Log the error
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/insurance-companies/${deletingCompanyId}`);
      setCompanies(companies.filter(company => company.company_id !== deletingCompanyId));
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting company: ", error); // Log the error
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCompany(null);
  };

  const handleOpenConfirmationModal = (company_id) => {
    setDeletingCompanyId(company_id);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeletingCompanyId(null);
  };

  const handleRowClick = (company) => {
    setEditingCompany(company);
    setFormData(company);
    setShowModal(true);
  };

  const formFields = [
    { name: "company_name", label: "Company Name", type: "text" },
    { name: "telephone_number", label: "Telephone Number", type: "text" },
    { name: "email_address", label: "Email Address", type: "email" },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        { label: "Work With", value: "work_with" },
        { label: "Don't Work With", value: "do_not_work_with" },
        { label: "Unknown", value: "unknown" },
      ],
    },
  ];

  const getStatusLabel = (status) => {
    switch (status) {
      case 'work_with':
        return 'Work With';
      case 'do_not_work_with':
        return "Don't Work With";
      case 'unknown':
      default:
        return 'Unknown';
    }
  };

  return (
    <div className="ManageInsuranceCompanies">
      <h2>Manage Insurance Companies</h2>
      <div className="icon-container" onClick={handleOpenModal}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>
      {showModal && (
        <FormModal
          closeModal={handleCloseModal}
          heading={editingCompany ? "Edit Insurance Company" : "Add New Insurance Company"}
          fields={formFields}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          message="Are you sure you want to delete this insurance company?"
          onConfirm={handleDelete}
          onCancel={handleCloseConfirmationModal}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Telephone Number</th>
            <th>Email Address</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.company_id}>
              <td onClick={() => handleRowClick(company)}>{company.company_name}</td>
              <td onClick={() => handleRowClick(company)}>{company.telephone_number}</td>
              <td onClick={() => handleRowClick(company)}>{company.email_address}</td>
              <td onClick={() => handleRowClick(company)}>{getStatusLabel(company.status)}</td>
              <td className="actions-cell">
                <button onClick={() => handleOpenConfirmationModal(company.company_id)} className="delete-button">
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageInsuranceCompanies;
