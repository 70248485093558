import React from "react";
import "./ImageModal.css";

const ImageModal = ({ show, onClose, imageUrl }) => {
  if (!show) return null;

  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageUrl} alt="Enlarged view" crossOrigin="anonymous" />
      </div>
      <button className="image-modal-close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default ImageModal;
