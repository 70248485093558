import axios from "axios";

/**
 * Create a new dealership.
 * @param {Object} dealershipData - Data for the new dealership.
 * @returns {Promise<Object>} - The response from the API.
 */
const createDealership = async (dealershipData) => {
  console.log('Dealership data: ', dealershipData)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/dealerships`,
      dealershipData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating dealership:", error);
    throw error;
  }
};

export default createDealership;
