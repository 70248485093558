// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-status-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sub-status-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
  }
  
  .sub-status-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
.sub-status-submit-btn{
  background-color: var(--primary-color);
  color: var(--text-light-color);
}

.sub-status-cancel-btn{
  background-color: blueviolet;
  color: var(--text-light-color);
}

.sub-status-select{
  border: 1px solid lightgray;
}`, "",{"version":3,"sources":["webpack://./src/components/SubStatusModal/SubStatusModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;AAEF;EACE,sCAAsC;EACtC,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".sub-status-modal-backdrop {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    z-index: 999;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .sub-status-modal-content {\r\n    background: #fff;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n    min-width: 300px;\r\n  }\r\n  \r\n  .sub-status-modal-actions {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-top: 10px;\r\n  }\r\n  \r\n.sub-status-submit-btn{\r\n  background-color: var(--primary-color);\r\n  color: var(--text-light-color);\r\n}\r\n\r\n.sub-status-cancel-btn{\r\n  background-color: blueviolet;\r\n  color: var(--text-light-color);\r\n}\r\n\r\n.sub-status-select{\r\n  border: 1px solid lightgray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
