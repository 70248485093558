import axios from "axios";

/**
 * Updates the password for a specific user.
 * 
 * @param {number} userId - The ID of the user whose password is being reset.
 * @param {string} newPassword - The new password for the user.
 * @returns {Promise} - A promise that resolves when the password has been updated.
 */
const updateUserPassword = async (userId, newPassword) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/password`,
      { password: newPassword },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Use token for authenticated request
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user password:", error);
    throw error;
  }
};

export default updateUserPassword;
