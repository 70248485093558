import axios from 'axios';

// Function to insert an insurance claim
export const insertInsuranceClaim = async (insuranceDetails) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/insurance-claims`,
      insuranceDetails
    );
  } catch (error) {
    console.error('Error inserting the insurance claim:', error);
  }
};
