import axios from 'axios';

export const deleteTask = async (taskId) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`);
    return response.data; // Return success message
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};
