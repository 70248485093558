// src/components/ActivitiesModal/ActivitiesModal.jsx
import React from "react";
import Activities from "../../components/Activities/Activities";
import ModalPortal from "../ModalPortal/ModalPortal";
import "./ActivitiesModal.css"; // You can add specific styles for this modal here

const ActivitiesModal = ({ show, onClose, registrationNumber }) => {
  if (!show) return null;

  return (
    <ModalPortal>
      <div className="activities-modal" onClick={onClose}>
        <div
          className="activities-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <h2>Vehicle Activities</h2>
          <Activities registrationNumber={registrationNumber} />
          <div className="activities-actions-container">
            <button className="activities-close-button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default ActivitiesModal;
