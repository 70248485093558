import axios from "axios";
import fetchSpecialPricing from "./fetchSpecialPricing";

const handleAddSpecialPricing = (dealershipId, specialPricing) => {
  console.log("Adding special pricing for dealership:", dealershipId);
  console.log("Special Pricing Data:", specialPricing);

  axios
    .post(
      `${process.env.REACT_APP_API_BASE_URL}/dealership-pricing/${dealershipId}`,
      specialPricing,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((response) => {
      console.log("Special pricing added successfully:", response.data);
      // Re-fetch the dealership pricing if necessary
      fetchSpecialPricing(dealershipId);
    })
    .catch((error) => {
      console.error("Error adding special pricing:", error);
    });
};

export default handleAddSpecialPricing;
