import axios from "axios";

const fetchSpecialPricing = async (dealershipId) => {
    console.log("Fetching special pricing for dealership ID:", dealershipId);
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/dealership-pricing/${dealershipId}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        return { data: response.data.data, message: null }; // Return data with no error message
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn("No special pricing found for this dealership.");
            return { data: [], message: "No special pricing found for this dealership." }; // Return empty data with a message
        }
        console.error("Error fetching special pricing:", error);
        throw error; // Rethrow for other errors
    }
};

export default fetchSpecialPricing;
