import React from 'react';
import ReactDOM from 'react-dom';
import './GoalsModal.css'; // Import the CSS for styling

const GoalsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Goals and Compensation</h2>
        <p>Coming Soon</p>
        {/* Add more content here as needed */}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default GoalsModal;
