// /src/utils/fetchInvoiceDetails.js
export const fetchInvoiceDetails = async (invoiceId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`);
      const data = await response.json();
      
      if (data.success) {
        return data.data;  // Assuming 'data.data' holds the invoice details
      } else {
        throw new Error(data.message || 'Failed to fetch invoice details');
      }
    } catch (error) {
      console.error('Error fetching invoice details:', error);
      throw error;
    }
};
