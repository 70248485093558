// src/utils/activityStatus.js
import axios from 'axios';

// Function to update user activity status
export const updateActivityStatus = async (userId, status) => {
  try {
    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}/activity-status`, {
      activity_status: status,
    });
    console.log(`User activity status updated to: ${status}`);
  } catch (error) {
    console.error('Error updating user activity status:', error);
  }
};
