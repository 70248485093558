import { useState } from "react";

export const useTechnicians = (technicians, formData, handleChange) => {
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);

  // Function to initialize selectedTechnicians based on formData
  const initializeSelectedTechnicians = () => {
    if (formData.technician && technicians.length > 0) {
      const technicianNames = formData.technician.split(", ").map((name) => {
        const tech = technicians.find(
          (t) => `${t.user_first_name} ${t.user_last_name}` === name
        );
        return tech ? { id: tech.user_id, name } : null;
      });

      setSelectedTechnicians(technicianNames.filter(Boolean));
    }
  };

  // Function to synchronize selectedTechnicians back to formData
  const syncSelectedTechniciansToFormData = () => {
    const technicianNames = selectedTechnicians.map((tech) => tech.name).join(", ");
    if (technicianNames !== formData.technician) {
      handleChange({
        target: { name: "technician", value: technicianNames },
      });
    }
  };

  // Expose methods to manually trigger these operations
  return {
    selectedTechnicians,
    setSelectedTechnicians,
    initializeSelectedTechnicians,
    syncSelectedTechniciansToFormData,
  };
};
