import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./ProfilePage.css"; // Ensure the CSS is correctly referenced

export const ProfilePage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [role, setRole] = useState("");
  const [group, setGroup] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSaveProfile = () => {
    // Placeholder function for saving profile settings
    console.log("Profile settings saved");
  };

  const handleSaveSecurity = () => {
    // Placeholder function for saving security settings
    console.log("Security settings saved");
  };

  return (
    <div className="profile-page-layout">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <main className="profile-main-content">
          <section>
            <h2>Personalisation</h2>
            <div className="profile-personalisation">
              <img src="#" alt="Profile" className="profile-picture" />
              <button className="upload-button">Upload Picture</button>
            </div>
          </section>

          <section>
            <h2>Profile/Settings</h2>
            <div className="form-group">
              <div className="profile-left-column">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                />
              </div>
              <div className="profile-right-column">
                <input
                  type="text"
                  value={extension}
                  onChange={(e) => setExtension(e.target.value)}
                  placeholder="Extension"
                />
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                  <option value="">Select Role</option>
                  <option value="admin">Technician</option>
                  <option value="user">Call Center Agent</option>
                  <option value="user">Management</option>
                </select>
                <select
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option value="">Select Group</option>
                  <option value="group1">Technician</option>
                  <option value="group2">Call Center Agent</option>
                </select>
              </div>
            </div>
            <button className="save-button" onClick={handleSaveProfile}>
              Save Profile
            </button>
          </section>

          <section>
            <h2>Security</h2>
            <div className="form-group">
              <div className="profile-right-column">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>

              <div className="profile-right-column">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <button className="save-button" onClick={handleSaveSecurity}>
              Save Security
            </button>
          </section>
        </main>
      </div>
    </div>
  );
};
