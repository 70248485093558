// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery {
  border: 1px solid var(--border-dark-color);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  max-height: 120px;
  margin-top: 20px;
  overflow-y: auto;
}

.image-gallery > img {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33% - 10px);
  max-width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageGallery/ImageGallery.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,aAAa;EACb,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B;EAC1B,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".image-gallery {\r\n  border: 1px solid var(--border-dark-color);\r\n  padding: 20px;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 10px;\r\n  width: 100%;\r\n  max-width: 600px;\r\n  max-height: 120px;\r\n  margin-top: 20px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.image-gallery > img {\r\n  flex: 1 1 calc(33% - 10px);\r\n  max-width: 100px;\r\n  height: 100px;\r\n  object-fit: cover;\r\n  border-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
