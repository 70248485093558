// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.administration-container{
    margin: 100px 40px 40px 40px;
}

.administration-main {
    margin: 0 0 0 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Administration/Administration.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".administration-container{\r\n    margin: 100px 40px 40px 40px;\r\n}\r\n\r\n.administration-main {\r\n    margin: 0 0 0 60px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
