import axios from "axios";

/**
 * Fetch invoices for a specific registration number.
 * @param {string} registrationNumber - The vehicle's registration number.
 * @returns {Promise<Array>} - List of invoices related to the registration number.
 */
const fetchClientInvoices = async (registrationNumber) => {
  try {
    // Ensure registrationNumber is provided
    if (!registrationNumber) {
      throw new Error("Registration number is required.");
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/invoices/${encodeURIComponent(
        registrationNumber
      )}`, // Ensure the registration number is URL-safe
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data.data; // Return data
  } catch (error) {
    // Check for backend-specific error response
    if (error.response) {
      const { status, data } = error.response;

      if (status === 404) {
        throw new Error(data.message || "No invoices found for the given registration number.");
      } else if (status === 500) {
        throw new Error(data.message || "Failed to fetch invoices due to a server error.");
      }
    }

    // General error message
    console.error("Error fetching invoices:", error.message || error);
    throw new Error("An error occurred while fetching invoices.");
  }
};

export default fetchClientInvoices;
