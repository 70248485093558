import React, { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import "./ManageCompanyDetails.css";

const ManageCompanyDetails = () => {
  const initialDetails = {
    companyName: "",
    registrationNumber: "",
    vatNumber: "",
    systemEmail: "",
    address: "",
    telephoneNumber: "",
    logo: "",
  };

  const [details, setDetails] = useState(initialDetails);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/company-details`
        );
        setDetails(response.data);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    fetchCompanyDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(false);
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/company-details`, details);
    } catch (error) {
      console.error("Error updating company details:", error);
    }
  };

  const handleRowClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="ManageCompanyDetails">
      <h2>Manage Company Details</h2>
      <table className="company-details-table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Registration Number</th>
            <th>VAT Number</th>
            <th>System Email</th>
            <th>Address</th>
            <th>Telephone Number</th>
            <th>Logo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={handleRowClick}>
            <td>{details.companyName}</td>
            <td>{details.registrationNumber}</td>
            <td>{details.vatNumber}</td>
            <td>{details.systemEmail}</td>
            <td>{details.address}</td>
            <td>{details.telephoneNumber}</td>
            <td>{details.logo}</td>
            <td className="actions-cell">
              <FaEdit className="edit-icon" />
            </td>
          </tr>
        </tbody>
      </table>

      {showModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Edit Company Details</h2>
            <form onSubmit={handleSubmit} className="company-details-form">
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={details.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="registrationNumber">Registration Number</label>
                <input
                  type="text"
                  id="registrationNumber"
                  name="registrationNumber"
                  value={details.registrationNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="vatNumber">VAT Number</label>
                <input
                  type="text"
                  id="vatNumber"
                  name="vatNumber"
                  value={details.vatNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="systemEmail">System Email</label>
                <input
                  type="email"
                  id="systemEmail"
                  name="systemEmail"
                  value={details.systemEmail}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={details.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="telephoneNumber">Telephone Number</label>
                <input
                  type="text"
                  id="telephoneNumber"
                  name="telephoneNumber"
                  value={details.telephoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="logo">Logo</label>
                <input
                  type="text"
                  id="logo"
                  name="logo"
                  value={details.logo}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="modal-buttons">
                <button type="submit" className="save-button">
                  Save
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageCompanyDetails;
