import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDueTasks } from '../../utils/getDueTasks';
import './TaskReminderModal.css';
import TaskModal from '../TaskModal/TaskModal'; // Import TaskModal

const TaskReminderModal = () => {
  const [dueTasks, setDueTasks] = useState([]);
  const [showReminder, setShowReminder] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null); // To hold the clicked task for TaskModal
  const [showTaskModal, setShowTaskModal] = useState(false); // To control TaskModal visibility

  // Function to fetch due tasks for the logged-in user
  const checkForDueTasks = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user")); // Retrieve logged-in user data
      if (user && user.id) {
        const tasks = await getDueTasks(user.id); // Pass userId to getDueTasks function
        if (tasks.length > 0) {
          // Ensure task_tags is parsed properly (if it's a string, parse it)
          const formattedTasks = tasks.map(task => ({
            ...task,
            task_tags: typeof task.task_tags === "string" ? JSON.parse(task.task_tags) : task.task_tags
          }));
          setDueTasks(formattedTasks);
          setShowReminder(true); // Trigger reminder modal
        }
      }
    } catch (error) {
      console.error("Error fetching due tasks:", error);
    }
  };

  // Use setInterval to poll the backend every 5 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkForDueTasks();
    }, 300000);

    return () => clearInterval(intervalId); // Clean up the interval
  }, []);

  // Function to close reminder modal
  const closeReminder = () => {
    setShowReminder(false);
    setDueTasks([]);
  };

  // Function to open TaskModal with the selected task
  const openTaskModal = (task) => {
    setSelectedTask(task);
    setShowTaskModal(true); // Show TaskModal
  };

  // Function to close TaskModal
  const closeTaskModal = () => {
    setSelectedTask(null);
    setShowTaskModal(false);
  };

  // Function to handle save and close both modals
  const handleSaveAndClose = () => {
    closeTaskModal(); // Close TaskModal
    closeReminder();  // Close Reminder Modal
  };

  return (
    <div>
      <ToastContainer />
      {showReminder && (
        <>
          <div className="overlay" /> {/* Add overlay */}
          <div className="reminder-modal">
            <div className="reminder-content">
              <h4>Upcoming Task Reminders:</h4>
              <ul>
                {dueTasks.map((task) => (
                  <li key={task.task_id} onClick={() => openTaskModal(task)}>
                    <span className="task-title">{task.task_title}</span>
                    <span className="task-details">
                      <p className='task-due-details'><strong>Due Date:</strong> {task.task_due_date}</p>
                      <p className='task-due-details'><strong> At:</strong> {task.task_due_time}</p>
                      <p className='task-due-details'><strong>Description:</strong> {task.task_description}</p>
                    </span>
                  </li>
                ))}
              </ul>
              <button onClick={closeReminder} className="close-reminder-btn">
                Close
              </button>
            </div>
          </div>
        </>
      )}

      {/* TaskModal opens if showTaskModal is true */}
      {showTaskModal && (
        <TaskModal
          task={selectedTask}
          onClose={closeTaskModal}
          onSave={handleSaveAndClose} // Close both modals on save
          isNewTask={false}
        />
      )}
    </div>
  );
};

export default TaskReminderModal;
