// components/SessionExpiredModal/SessionExpiredModal.jsx
import React from 'react';
import PropTypes from 'prop-types';

const SessionExpiredModal = ({ isOpen, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Session Expired</h2>
        <p>Your session has expired. Please log in again.</p>
        <button onClick={onConfirm}>Log In</button>
      </div>
    </div>
  );
};

SessionExpiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SessionExpiredModal;
