import React, { useEffect, useState } from "react";
import { fetchDealershipGroups } from "../../utils/fetchDealershipGroups";
import { createDealershipGroup } from "../../utils/createDealershipGroup";
import { updateDealershipGroup } from "../../utils/updateDealershipGroup";
import { deleteDealershipGroup } from "../../utils/deleteDealershipGroup";
import "./ModalDealershipGroup.css";

const ModalDealershipGroup = ({ isOpen, closeModal, onSubmit }) => {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [dealershipGroups, setDealershipGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groups = await fetchDealershipGroups();
        setDealershipGroups(groups || []);
      } catch (err) {
        console.error("Error fetching dealership groups:", err);
      }
    };

    if (isOpen) fetchGroups();
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!groupName.trim() || !description.trim()) {
      setError("Both fields are required.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      if (selectedGroupId) {
        // Update existing group
        await updateDealershipGroup({
          group_id: selectedGroupId,
          group_name: groupName,
          description,
        });

        // Update the local dealershipGroups array
        setDealershipGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === selectedGroupId
              ? { ...group, group_name: groupName, description }
              : group
          )
        );
      } else {
        // Create new group
        const newGroup = await createDealershipGroup({
          group_name: groupName,
          description,
        });

        // Add the new group to the local array
        setDealershipGroups((prevGroups) => [...prevGroups, newGroup.data]);
        onSubmit(newGroup.data);
      }

      setGroupName("");
      setDescription("");
      setSelectedGroupId("");
      closeModal();
    } catch (err) {
      setError("Failed to save the group. Please try again.");
      console.error("Error saving dealership group:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedGroupId) {
      setError("Please select a group to delete.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      await deleteDealershipGroup(selectedGroupId);

      // Remove the deleted group from the local array
      setDealershipGroups((prevGroups) =>
        prevGroups.filter((group) => group.group_id !== selectedGroupId)
      );

      setGroupName("");
      setDescription("");
      setSelectedGroupId("");
    } catch (err) {
      setError("Failed to delete the group. Please try again.");
      console.error("Error deleting dealership group:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleGroupSelect = (groupId) => {
    const numericGroupId = Number(groupId); // Convert groupId to a number
    const selectedGroup = dealershipGroups.find(
      (group) => group.group_id === numericGroupId
    );

    if (selectedGroup) {
      setSelectedGroupId(numericGroupId);
      setGroupName(selectedGroup.group_name);
      setDescription(selectedGroup.description);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-dealership-overlay" onClick={closeModal}>
      <div
        className="modal-dealership-group-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2>{selectedGroupId ? "Edit Group" : "Add New Group"}</h2>
        <div className="dealership-group-modal-form-group">
          <label htmlFor="groupDropdown">Select Group</label>
          <select
            id="groupDropdown"
            value={selectedGroupId}
            onChange={(e) => handleGroupSelect(e.target.value)}
            className="styled-input styled-select"
          >
            <option value="">Select a group</option>
            {dealershipGroups.map((group) => (
              <option key={group.group_id} value={group.group_id}>
                {group.group_name}
              </option>
            ))}
          </select>
        </div>
        <div className="dealership-group-modal-form-group">
          <label htmlFor="groupName">Group Name</label>
          <input
            id="groupName"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
            disabled={loading}
          />
        </div>
        <div className="dealership-group-modal-form-group">
          <label htmlFor="description">Description</label>
          <input
            id="description"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
            disabled={loading}
          />
        </div>
        {error && <p className="dealership-group-modal-error">{error}</p>}
        <div className="dealership-group-modal-footer">
          <button
            className="dealership-group-modal-btn-confirm"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            className="dealership-group-modal-btn-delete"
            onClick={handleDelete}
            disabled={loading}
          >
            Delete
          </button>
          <button
            className="dealership-group-modal-btn-cancel"
            onClick={closeModal}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalDealershipGroup;
