// fetchTechnicians.js
import axios from "axios";

export const fetchTechnicians = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/technicians`);
    return response.data; // Return the technician data directly
  } catch (error) {
    console.error("Error fetching technicians:", error);
    return []; // Return an empty array in case of an error to avoid undefined issues
  }
};
