// In src/utils/updateClaimStatus.js
import axios from "axios";

// Function to update the status of an insurance claim (authorize/decline)
export const updateClaimStatus = async (id, updateData) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/insurance-claims/${id}`,
      updateData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating claim status:", error);
    throw error;
  }
};
