export const getPricing = (basePricing, selectedOptions) => {
    const pricing = {};
    console.log("Selected Options:", selectedOptions);
    console.log("Base Pricing Data:", basePricing);

    // Windscreen logic: Price is 0 if not selected
    if (selectedOptions.windscreen.package1 || selectedOptions.windscreen.package2) {
        const windscreenPrices = basePricing.filter((item) =>
            item.service_name.toLowerCase().includes("windscreen")
        );

        if (selectedOptions.windscreen.package1) {
            pricing.windscreen = windscreenPrices.find((item) =>
                item.service_name.toLowerCase().includes("repair 1")
            )?.retail_price_incl;
        } else if (selectedOptions.windscreen.package2) {
            pricing.windscreen = windscreenPrices.find((item) =>
                item.service_name.toLowerCase().includes("repair 2")
            )?.retail_price_incl;
        }
    } else {
        pricing.windscreen = "0.00";
    }

    // Headlight logic: Price is 0 if not selected
    pricing.headlight = selectedOptions.headlight.selected
        ? basePricing.find((item) =>
              item.service_name.toLowerCase().includes("headlight")
          )?.retail_price_incl || "0.00"
        : "0.00";

    // Paint Protection logic: Price is 0 if not selected
    if (selectedOptions.polish.package1 || selectedOptions.polish.package2) {
        const paintPrices = basePricing.filter((item) =>
            item.service_name.toLowerCase().includes("package")
        );
        console.log("Filtered Paint Prices:", paintPrices);

        const isSUV = selectedOptions.polish.option.toLowerCase() === "suv";
        const isPS = selectedOptions.polish.option.toLowerCase() === "ps";
        const isSilver = selectedOptions.polish.package1;
        const isPlatinum = selectedOptions.polish.package2;

        if (isSilver) {
            pricing.polish = paintPrices.find((item) =>
                item.service_name.toLowerCase() === `silver package (${isSUV ? "suv" : "ps"})`
            )?.retail_price_incl;
        } else if (isPlatinum) {
            pricing.polish = paintPrices.find((item) =>
                item.service_name.toLowerCase() === `platinum package (${isSUV ? "suv" : "ps"})`
            )?.retail_price_incl;
        } else {
            pricing.polish = "0.00";
        }
    } else {
        pricing.polish = "0.00";
    }

    console.log("Calculated Pricing:", pricing);
    return pricing;
};
