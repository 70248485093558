import { jsPDF } from "jspdf";
import "jspdf-autotable";

// Function to generate invoice HTML for viewing
export const generateInvoiceHTML = (invoice, companyDetails) => {
  console.log("Invoice data:", invoice);
  console.log("Company details:", companyDetails);
  const logoPath = `${process.env.PUBLIC_URL}/${companyDetails.logo}`;

  // Ensure that invoice.items is defined and is an array
  const items = Array.isArray(invoice.items) ? invoice.items : [];

  const invoiceWindow = window.open("", "Invoice", `width=800,height=850`);
  invoiceWindow.document.write(`
    <html>
      <head>
        <title>Invoice</title>
      </head>
      <body>
        <div style="padding: 20px; font-family: Arial, sans-serif;">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <img src="${logoPath}" alt="Logo" style="height: 80px;"/>
            <h1>Tax Invoice</h1>
          </div>
          <div style="display: flex; justify-content: space-between; margin-top: 20px;">
            <!-- Client Information on the Left -->
            <div style="flex: 1;">
              <p><strong>Client Information</strong></p>
              <p>${invoice.client_name}</p>
              <p>${invoice.client_contact}</p>
            </div>
            <!-- Company Information on the Right -->
            <div style="flex: 1; text-align: right;">
              <p><strong>Company Information</strong></p>
              <p>${companyDetails.companyName}</p>
              <p>Registration No: ${companyDetails.registrationNumber}</p>
              <p>VAT Registration: ${companyDetails.vatNumber}</p>
              <p>Tel: ${companyDetails.telephoneNumber}</p>
              <p>Email: ${companyDetails.systemEmail}</p>
              <p>${companyDetails.address}</p>
            </div>
          </div>
          <div style="margin-top: 20px;">
            <!-- Invoice Information -->
            <p><strong>Invoice Information</strong></p>
            <p><strong>Invoice ID:</strong> ${invoice.invoice_id}</p>
            <p><strong>Job ID:</strong> ${invoice.job_id}</p>
            <p><strong>Registration Number:</strong> ${invoice.vehicle_registration}</p>
            <p><strong>Date:</strong> ${invoice.invoice_date}</p>
          </div>
          <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
            <thead>
              <tr>
                <th style="border: 1px solid #ddd; padding: 8px;">Description</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Price</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Total</th>
              </tr>
            </thead>
            <tbody>
              ${items
                .map(
                  (item) => `
                  <tr>
                    <td style="border: 1px solid #ddd; padding: 8px;">${item.description}</td>
                    <td style="border: 1px solid #ddd; padding: 8px;">${item.quantity}</td>
                    <td style="border: 1px solid #ddd; padding: 8px;">R${item.price}</td>
                    <td style="border: 1px solid #ddd; padding: 8px;">R${item.quantity * item.price}</td>
                  </tr>`
                )
                .join("")}
            </tbody>
          </table>
          <div style="margin-top: 20px; text-align: right;">
            <p><strong>Subtotal:</strong> R${items.reduce(
              (sum, item) => sum + item.price * item.quantity,
              0
            )}</p>
            <p><strong>VAT (15%):</strong> R${(
              items.reduce((sum, item) => sum + item.price * item.quantity, 0) *
              0.15
            ).toFixed(2)}</p>
            <p><strong>Total:</strong> R${(
              items.reduce((sum, item) => sum + item.price * item.quantity, 0) *
              1.15
            ).toFixed(2)}</p>
          </div>
          <div style="margin-top: 40px; font-size: 12px;">
            <p>This invoice is automatically generated. By using our services, you agree to our terms and conditions. Any discrepancies should be reported immediately.</p>
            <p>This invoice is subject to VAT and other relevant regulations. Personal information is protected under the Protection of Personal Information Act (POPIA).</p>
          </div>
        </div>
      </body>
    </html>
  `);
};


export const downloadInvoicePDF = (invoice, companyDetails) => {
    const doc = new jsPDF({ unit: "pt", format: "a4" });
    const margin = 40;
    const startX = margin;
    const startY = margin;
    const logoWidth = 140;
    const logoHeight = 80;
    const pageWidth = doc.internal.pageSize.width;
  
    doc.setFontSize(10);
  
    // Ensure that invoice.items is defined and is an array
    const items = Array.isArray(invoice.items) ? invoice.items : [];
  
    // Add company logo and info
    doc.addImage(`${process.env.PUBLIC_URL}/${companyDetails.logo}`, "PNG", startX, startY, logoWidth, logoHeight);
    doc.setFontSize(18);
    doc.text("Tax Invoice", pageWidth - startX - 40, startY + 40, { align: "right" });
  
    let currentY = startY + logoHeight + margin;
    doc.setFontSize(12);
  
    // Align Client info on the left and Company info on the right
    doc.text(`Client: ${invoice.client_name}`, startX, currentY);
    doc.text(`Company: ${companyDetails.companyName}`, pageWidth - startX - 200, currentY);
    currentY += 20;
    doc.text(`Contact: ${invoice.client_contact}`, startX, currentY);
    doc.text(`Registration No: ${companyDetails.registrationNumber}`, pageWidth - startX - 200, currentY);
    currentY += 20;
    doc.text(`Invoice ID: ${invoice.invoice_id}`, startX, currentY);
    doc.text(`VAT No: ${companyDetails.vatNumber}`, pageWidth - startX - 200, currentY);
    currentY += 20;
    doc.text(`Job ID: ${invoice.job_id}`, startX, currentY);
    doc.text(`Tel: ${companyDetails.telephoneNumber}`, pageWidth - startX - 200, currentY);
    currentY += 20;
    doc.text(`Registration: ${invoice.vehicle_registration}`, startX, currentY);
    doc.text(`Email: ${companyDetails.systemEmail}`, pageWidth - startX - 200, currentY);
    currentY += 20;
    doc.text(`Date: ${invoice.invoice_date}`, startX, currentY);
    doc.text(`Address: ${companyDetails.address}`, pageWidth - startX - 200, currentY);
  
    // Add extra space before starting the Invoice Information section
    currentY += 40;  // This adds extra space before the invoice details
  
    // Invoice items
    doc.autoTable({
      startY: currentY,
      head: [["Description", "Quantity", "Price", "Total"]],
      body: [
        ...items.map((item) => [
          item.description,
          item.quantity,
          `R${item.price}`,
          `R${item.quantity * item.price}`,
        ]),
        [
          { content: "", colSpan: 2 },
          { content: "Subtotal", styles: { halign: "left", fontStyle: "bold" } },
          {
            content: `R${items.reduce(
              (sum, item) => sum + item.price * item.quantity,
              0
            )}`,
          },
        ],
        [
          { content: "", colSpan: 2 },
          { content: "VAT (15%)", styles: { halign: "left", fontStyle: "bold" } },
          {
            content: `R${(
              items.reduce((sum, item) => sum + item.price * item.quantity, 0) * 0.15
            ).toFixed(2)}`,
          },
        ],
        [
          { content: "", colSpan: 2 },
          { content: "Total", styles: { halign: "left", fontStyle: "bold" } },
          {
            content: `R${(
              items.reduce((sum, item) => sum + item.price * item.quantity, 0) * 1.15
            ).toFixed(2)}`,
          },
        ],
      ],
    });
  
    currentY = doc.lastAutoTable.finalY + 40;
  
    // Disclaimer (matching the HTML version)
    doc.setFontSize(10);
    doc.text(
      "This invoice is automatically generated. By using our services, you agree to our terms and conditions.",
      startX,
      currentY
    );
    currentY += 12;
    doc.text(
      "Ensure all details are correct. Any discrepancies should be reported immediately.",
      startX,
      currentY
    );
    currentY += 12;
    doc.text(
      "This invoice is subject to VAT and other relevant regulations. Personal information is protected under the Protection of Personal Information Act (POPIA).",
      startX,
      currentY
    );
  
    const filename = `${invoice.vehicle_registration}-${new Date()
      .toISOString()
      .replace(/[-T:.Z]/g, "")}.pdf`;
  
    doc.save(filename);
  };
  