import axios from 'axios';

export const submitSupportTicket = async ({ email, message }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/support`, {
      email,
      message
    });
    if (response.status === 200) {
      alert("Your support ticket has been submitted successfully.");
    }
  } catch (error) {
    console.error("Error submitting support ticket:", error);
    alert("There was an error submitting your ticket. Please try again.");
  }
};
