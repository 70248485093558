import { useCallback } from "react";
import createDealership from "./createDealership";
import updateDealership from "./updateDealership";
import createDealershipGoals from "./createDealershipGoals";

const useHandleDealershipSubmit = () => {
  const handleDealershipSubmit = useCallback(
    async ({
      dealership_id,
      name,
      selectedServiceAdvisors,
      contact_number,
      technician,
      address,
      assigned_agent,
      dealership_group,
      vat_number,
      registration_number,
      localGoals,
      closeModal,
    }) => {
      try {
        console.log("Initial dealership_id:", dealership_id);
        let currentDealershipId = dealership_id;

        const dealershipData = {
          name,
          service_agents: selectedServiceAdvisors,
          contact_number,
          technician,
          address,
          assigned_agent,
          dealership_group,
          vat_number,
          registration_number,
        };

        if (!currentDealershipId) {
          console.log("Creating a new dealership with data:", dealershipData);
          const createdDealership = await createDealership(dealershipData);

          console.log("Create dealership response:", createdDealership);

          currentDealershipId = createdDealership?.dealership_id;

          if (!currentDealershipId) {
            console.error("Failed to create dealership. Response:", createdDealership);
            throw new Error("Failed to create dealership.");
          }

          console.log("New dealership created with ID:", currentDealershipId);
        } else {
          console.log("Updating existing dealership with ID:", currentDealershipId);
          await updateDealership(currentDealershipId, dealershipData);
        }

        const goalsData = {
          dealership_id: currentDealershipId,
          ...localGoals,
        };

        console.log("Saving dealership goals with data:", goalsData);

        await createDealershipGoals(goalsData);

        console.log("Dealership goals saved successfully.");

        if (closeModal) closeModal();
      } catch (error) {
        console.error("Error saving dealership or goals:", error);
      }
    },
    []
  );

  return { handleDealershipSubmit };
};

export default useHandleDealershipSubmit;
