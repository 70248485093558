import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { PipelineGridView } from "../../components/PipelineGridView/PipelineGridView";
import { PipelineCardView } from "../../components/PipelineCardView/PipelineCardView";
import CallModal from "../../components/CallModal/CallModal";
import HistoryModal from "../../components/HistoryModal/HistoryModal"; // Add HistoryModal
import Tooltip from "../../components/Tooltip/Tooltip";
import "./Pipeline.css";

const categorizeItems = (items) => {
  console.log("Items: ", items);
  const columns = {
    new: { name: "New", items: [] },
    pendingOther: { name: "Pending Other", items: [] },
    pendingInsurance: { name: "Pending Insurance", items: [] },
    won: { name: "Won", items: [] },
    lost: { name: "Lost", items: [] },
  };

  items.forEach((item) => {
    const updatedItem = {
      ...item,
      is_express_service: item.is_express_service, // Ensure it is included
    };

    if (item.dealStatus === "Pending") {
      if (
        item.insurance &&
        item.subStatus !== "NA" &&
        item.subStatus !== "TCB" &&
        item.subStatus !== "WN" &&
        item.subStatus !== "NULL"
      ) {
        columns.pendingInsurance.items.push(updatedItem);
      } else {
        columns.pendingOther.items.push(updatedItem);
      }
    } else {
      switch (item.dealStatus) {
        case "New":
          columns.new.items.push(updatedItem);
          break;
        case "Won":
          columns.won.items.push(updatedItem);
          break;
        case "Lost":
          columns.lost.items.push(updatedItem);
          break;
        default:
          break;
      }
    }
  });

  return columns;
};

export const Pipeline = () => {
  const [view, setView] = useState("card");
  const [columns, setColumns] = useState({
    new: { name: "New", items: [] },
    pendingOther: { name: "Pending Other", items: [] },
    pendingInsurance: { name: "Pending Insurance", items: [] },
    won: { name: "Won", items: [] },
    lost: { name: "Lost", items: [] },
  });
  const [showCallModal, setShowCallModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false); // History modal state
  const [selectedRegistration, setSelectedRegistration] = useState(null); // Selected registration number for history

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user")); // Get the logged-in user from localStorage
        if (user && user.id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/jobs`,
            {
              params: { user_id: user.id }, // Send user_id as a query parameter
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming you are using token-based authentication
              },
            }
          );

          const jobs = response.data
            .filter(
              (job) =>
                job.job_status !== "Completed" && job.job_status !== "Open"
            )
            .map((job) => ({
              id: job.job_id,
              registration: job.registration_number,
              customerType: job.customerType,
              subStatus: job.subStatus,
              type: job.type,
              vehicleMake: job.vehicleMake,
              vehicleModel: job.vehicleModel,
              year: job.year,
              vin_number: job.vin_number,
              service_checks: job.service_checks,
              services_headlight_option: job.services_headlight_option,
              services_polish_option: job.services_polish_option,
              is_express_service: job.is_express_service,
              firstName: job.first_name,
              lastName: job.last_name,
              contactNumber: job.contact_number,
              alt_contactNumber: job.alt_contact_number,
              emailAddress: job.emailAddress,
              insurance_broker: job.insurance_broker,
              insurance: job.insurance,
              subscription: job.subscription,
              dealership: job.dealership,
              dealStatus: job.dealStatus,
              wip: job.wip,
              service_advisor: job.service_advisor,
              comments: job.comments,
              services_headlight_selected: job.services_headlight_selected,
              assigned_agent: job.assigned_agent,
              services_windscreen_option: job.services_windscreen_option,
              created_by: job.created_by,
              created_at: job.created_at,
              job_status: job.job_status,
              total_chips: job.number_chips,
            }));

          const categorizedItems = categorizeItems(jobs);
          setColumns(categorizedItems);
        } else {
          console.error("User not found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5000); // fetch data every 5 seconds
    return () => clearInterval(intervalId); // cleanup interval on component unmount
  }, []);

  const handleRowClick = (contact) => {
    setSelectedContact(contact);
    setShowCallModal(true);
  };

  const handleHistoryClick = (registration) => {
    setSelectedRegistration(registration);
    setShowHistoryModal(true); // Show the history modal when clicked
  };

  return (
    <div className="pipeline-container">
      <Header />
      <div className="pipeline-content">
        <Sidebar />
        <main className="pipeline-main-content">
          <div className="view-toggle-container">
            <Tooltip text="Switch to Grid View">
              <button
                onClick={() => setView("grid")}
                className="view-toggle-button"
              >
                <span className="material-symbols-outlined">view_list</span>
              </button>
            </Tooltip>
            <Tooltip text="Switch to Card View">
              <button
                onClick={() => setView("card")}
                className="view-toggle-button"
              >
                <span className="material-symbols-outlined">view_module</span>
              </button>
            </Tooltip>
          </div>
          <div className="view-container">
            {view === "grid" ? (
              <PipelineGridView columns={columns} onRowClick={handleRowClick} />
            ) : (
              <PipelineCardView columns={columns} setColumns={setColumns} />
            )}
          </div>
        </main>
      </div>
      <CallModal
        show={showCallModal}
        onClose={() => setShowCallModal(false)}
        contact={selectedContact}
      />
      <HistoryModal
        show={showHistoryModal}
        onClose={() => setShowHistoryModal(false)}
        registrationNumber={selectedRegistration} // Pass the registration number
        columns={columns} // Pass all columns to filter history data
      />
    </div>
  );
};
