import { updateJobDealStatus } from "./updateJobDealStatus";
import { createVehicleLogEntry } from "./createVehicleLogEntry";
import { createNotification } from "./createNotification";
import { updateNotificationStatus } from "./updateNotifications";

/**
 * Handles the approval or decline of a DND request.
 * @param {string} response - Either 'approve' or 'decline'.
 * @param {object} notification - The notification object.
 * @param {function} fetchUserNotifications - Function to refetch user notifications.
 * @param {function} closeModal - Function to close the modal after the response is handled.
 */
export const handleDNDResponse = async (response, notification, fetchUserNotifications, closeModal) => {
  const jobId = extractJobIdFromContent(notification.notification_content);
  const userData = JSON.parse(localStorage.getItem("user"));
  const registrationNumber = extractRegistrationNumberFromContent(notification.notification_content);

  try {
    if (response === "decline" && jobId) {
      // Update the deal and job status to New and Pending
      await updateJobDealStatus(jobId, "New", "Pending");

      // Log the decline action
      await createVehicleLogEntry({
        registration_number: registrationNumber,
        user_id: userData.id,
        activity_title: "DND Declined",
        activity_description: `Job ID ${jobId} DND status was declined and the deal status was set to 'New' and job status was set to 'Pending'.`,
      });

      // Notify the sender about the decline
      await createNotification({
        sender_id: userData.id,
        recipient_id: notification.sender_id,
        notification_title: "DND Request Declined",
        notification_content: `Your DND request for Job ID ${jobId} has been declined.`,
        notification_status: "unread",
      });
    } else if (response === "approve" && jobId) {
      // Update the deal and job status to DND and Lost
      await updateJobDealStatus(jobId, "DND", "Lost");

      // Log the approval action
      await createVehicleLogEntry({
        registration_number: registrationNumber,
        user_id: userData.id,
        activity_title: "DND Approved",
        activity_description: `Job ID ${jobId} DND status was approved and marked as 'Lost'.`,
      });

      // Notify the sender about the approval
      await createNotification({
        sender_id: userData.id,
        recipient_id: notification.sender_id,
        notification_title: "DND Request Approved",
        notification_content: `Your DND request for Job ID ${jobId} has been approved.`,
        notification_status: "unread",
      });
    }

    // Update notification status
    await updateNotificationStatus(notification.notification_id);

    // Refetch the user's notifications
    fetchUserNotifications(userData.id);

    // Close the modal after the response is handled
    if (closeModal) {
      closeModal();
    }
  } catch (error) {
    console.error("Error handling DND response:", error);
  }
};

// Helper functions to extract job ID and registration number from notification content
const extractJobIdFromContent = (content) => {
  const match = content.match(/Job ID: (\d+)/);
  return match ? match[1] : null;
};

const extractRegistrationNumberFromContent = (content) => {
  const match = content.match(/Registration number: (\w+)/);
  return match ? match[1] : null;
};
