import React from 'react';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import AdminOptions from '../../components/AdminOptions/AdminOptions';
import './Administration.css';
import '../../components/AdminOptions/AdminOptions.css';

export const Administration = () => {
  return (
    <div className="administration-container">
      <Header />
      <div className="administration-content">
        <Sidebar />
        <main className="administration-main">
          <AdminOptions />
        </main>
      </div>
    </div>
  );
}
