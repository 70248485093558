import axios from "axios";
import { createVehicleLogEntry } from "./createVehicleLogEntry"; // Adjust path as needed

/**
 * Handles the service change and updates relevant job details.
 * @param {Object} params - The function parameters.
 * @param {Object} params.contact - Current contact details.
 * @param {Function} params.setContact - Function to update the contact state.
 * @param {string} params.service - The service being updated.
 * @param {string} params.updatedService - The updated value for the service.
 * @param {string} params.userId - The user ID performing the operation.
 */
export const handleServiceChange = async ({
  contact,
  setContact,
  service,
  updatedService,
  userId,
}) => {
  if (!userId) {
    console.error("User ID is required to perform this action.");
    return;
  }

  const serviceChecks = contact.service_checks
    ? JSON.parse(contact.service_checks)
    : {};

  const newServiceChecks = {
    ...serviceChecks,
    [service]: updatedService,
  };

  setContact((prevContact) => ({
    ...prevContact,
    service_checks: JSON.stringify(newServiceChecks),
  }));

  try {
    // Update service checks via API
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/jobs/update-service-checks`, {
      job_id: contact.id,
      service_checks: JSON.stringify(newServiceChecks),
    });

    // Log service change activity
    await createVehicleLogEntry({
      registration_number: contact.registration,
      user_id: userId,
      activity_title: "Service Option Updated",
      activity_description: `Updated ${service} to ${updatedService}`,
    });
  } catch (error) {
    console.error("Error updating service checks:", error);
  }
};
