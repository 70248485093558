import axios from 'axios';

export const fetchVehicleActivityLog = async (registrationNumber) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle-logs/${registrationNumber}`,
      {
        withCredentials: true, // Include credentials if needed
      }
    );
    return response.data; // Return the fetched activity logs
  } catch (error) {
    console.error("Error fetching vehicle activity log:", error);
    return []; // Return an empty array in case of an error
  }
};
