import React from "react";

const DealershipGoalsForm = ({ goals, handleGoalsChange }) => {
  return (
    <div className="dealership-form-group">
      <div className="goal-input-group">
        <div className="goal-input">
          <input
            id="surveyed_cars"
            name="surveyed_cars"
            type="number"
            value={goals.surveyed_cars || ""}
            onChange={handleGoalsChange}
            className="styled-input"
            placeholder="Cars"
          />
        </div>
        <div className="goal-input">
          <input
            id="chips-goal"
            name="chips_goal"
            type="number"
            value={goals.chips_goal || ""}
            onChange={handleGoalsChange}
            className="styled-input"
            placeholder="Chips"
          />
        </div>
        <div className="goal-input">
          <input
            id="hlr-goal"
            name="hlr_goal"
            type="number"
            value={goals.hlr_goal || ""}
            onChange={handleGoalsChange}
            className="styled-input"
            placeholder="HLR"
          />
        </div>
        <div className="goal-input">
          <input
            id="pp-goal"
            name="pp_goal"
            type="number"
            value={goals.pp_goal || ""}
            onChange={handleGoalsChange}
            className="styled-input"
            placeholder="PP"
          />
        </div>
      </div>
    </div>
  );
};

export default DealershipGoalsForm;
