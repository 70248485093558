import React, { useState, useEffect } from "react";
import { fetchVehicleActivityLog } from "../../utils/fetchVehicleActivityLog";
import "./Activities.css";

const Activities = ({ registrationNumber }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      const activityLogs = await fetchVehicleActivityLog(registrationNumber);
      setActivities(activityLogs);
    };

    if (registrationNumber) {
      fetchActivities();
    }
  }, [registrationNumber]);

  return (
    <div className="activities-container">
      <div className="activities-table-container">
        <table className="activities-table">
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th>Date</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            {activities.length > 0 ? (
              activities.map((activity, index) => (
                <tr key={index}>
                  <td>{activity.activity_title}</td>
                  <td>{activity.activity_description}</td>
                  <td>{new Date(activity.date).toLocaleDateString()}</td>
                  <td>{activity.user_id}</td> {/* Replace with actual user name if available */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No activity logs found for this vehicle.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Activities;
