import axios from 'axios';

export async function fetchNotifications(recipientId) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/notifications/${recipientId}`);
        return response.data.data; // Adjust based on API response structure
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
}
