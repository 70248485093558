import React, { useState } from "react";
import { formConfigs } from '../AdminDashboard/AdminDashboard'; 
import ManageUsers from "../../components/ManageUsers/ManageUsers";
import ManageInsuranceCompanies from "../../components/ManageInsuranceCompanies/ManageInsuranceCompanies";
import ManageDealerships from "../../components/ManageDealerships/ManageDealerships";
import ManageVehicleTypes from "../../components/ManageVehicleTypes/ManageVehicleTypes";
import ManagePricingTables from "../../components/ManagePricingTables/ManagePricingTables";
import ManageCompanyDetails from "../../components/ManageCompanyDetails/ManageCompanyDetails";
import ManageCompanyTargets from "../../components/ManageCompanyTargets/ManageCompanyTargets";
import ManageReports from "../../components/ManageReports/ManageReports";
import "./AdminOptions.css"; 

const AdminOptions = () => {
  const [activeOptionId, setActiveOptionId] = useState(null);
  const [,setModalConfig] = useState(null);

  const options = [
    { id: 1, title: "Users", Component: ManageUsers, configKey: "newUser" },
    {
      id: 2,
      title: "Insurance",
      Component: ManageInsuranceCompanies,
      configKey: "newInsuranceCompany",
    },
    { id: 3, title: "Dealerships", Component: ManageDealerships, configKey: "newDealership" },
    { id: 4, title: "Vehicle Types", Component: ManageVehicleTypes, configKey: "newVehicleType" },
    { id: 5, title: "Pricing", Component: ManagePricingTables, configKey: "pricingTable" },
    { id: 6, title: "Company Details", Component: ManageCompanyDetails, configKey: "companyDetails" },
    { id: 7, title: "Reports", Component: ManageReports, configKey: "Reports" },
  ];

  const getActiveComponent = () => {
    const activeOption = options.find((option) => option.id === activeOptionId);
    return activeOption ? <activeOption.Component /> : null;
  };

  const openModal = (configKey) => {
    setModalConfig(formConfigs[configKey]);
  };


  return (
    <div>
      <div className="admin-options">
        {options.map((option) => (
          <div
            key={option.id}
            className={`admin-card ${activeOptionId === option.id ? "active" : ""}`}
            onClick={() => {
              setActiveOptionId(option.id);
              openModal(option.configKey); 
            }}
          >
            <h3>{option.title}</h3>
          </div>
        ))}
      </div>
      {getActiveComponent()}
      {/* Render FormModal with modalConfig if it exists */}
    </div>
  );
};

export default AdminOptions;
