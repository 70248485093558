import { jwtDecode } from 'jwt-decode'; // Ensure the correct import

// Function to check if the token is expired
export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime; // Check if the token has expired
  } catch (error) {
    console.error("Error checking token expiration:", error);
    return true; // Return true if there's an issue decoding the token
  }
};

// Function to get the user from the token
export const getUserFromToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    if (isTokenExpired(token)) {
      return null; // Token has expired
    }
    const decodedToken = jwtDecode(token);
    return decodedToken; // Return the decoded token (user info)
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

// Function to refresh the token (e.g., extending expiration time)
export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken"); // Use refreshToken for refreshing
    if (!refreshToken) {
      throw new Error("No refresh token found for refreshing.");
    }

    // Make a request to refresh the token, sending the refresh token in the Authorization header
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/refresh-token`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${refreshToken}`, // Send refresh token in Authorization header
        "Content-Type": "application/json", // Content type is JSON
      }
    });

    if (!response.ok) {
      throw new Error("Failed to refresh token.");
    }

    const { accessToken, refreshToken: newRefreshToken } = await response.json(); // Assuming your API returns both
    localStorage.setItem("token", accessToken); // Store new access token
    localStorage.setItem("refreshToken", newRefreshToken); // Store new refresh token
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
};

