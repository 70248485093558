// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Administration.css */
.administration-container {
  display: flex;
  flex-direction: column;
}

.administration-content {
  display: flex;
}

.administration-main {
  flex-grow: 1;
  padding: 20px;
}

.admin-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  font-size: 14px;
}

.admin-card {
  background-color: var(--primary-color);
  border: 1px solid var(--admin-card-border-color);
  color: var(--text-light-color);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s, color 0.3s;
  flex: 1 1 100px;
  max-width: calc(25% - 20px);
}

.admin-card:hover {
  background-color: var(--secondary-color);
  color: var(--text-light-color);
  transform: scale(1.1);
}

.active{
  background-color: var(--secondary-color);
  color: var(--text-light-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminOptions/AdminOptions.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,gDAAgD;EAChD,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,kEAAkE;EAClE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,wCAAwC;EACxC,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,wCAAwC;EACxC,8BAA8B;AAChC","sourcesContent":["/* Administration.css */\r\n.administration-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.administration-content {\r\n  display: flex;\r\n}\r\n\r\n.administration-main {\r\n  flex-grow: 1;\r\n  padding: 20px;\r\n}\r\n\r\n.admin-options {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  flex-wrap: wrap;\r\n  gap: 10px;\r\n  align-items: center;\r\n  font-size: 14px;\r\n}\r\n\r\n.admin-card {\r\n  background-color: var(--primary-color);\r\n  border: 1px solid var(--admin-card-border-color);\r\n  color: var(--text-light-color);\r\n  border-radius: 8px;\r\n  padding: 10px;\r\n  text-align: center;\r\n  cursor: pointer;\r\n  transition: transform 0.3s ease, background-color 0.3s, color 0.3s;\r\n  flex: 1 1 100px;\r\n  max-width: calc(25% - 20px);\r\n}\r\n\r\n.admin-card:hover {\r\n  background-color: var(--secondary-color);\r\n  color: var(--text-light-color);\r\n  transform: scale(1.1);\r\n}\r\n\r\n.active{\r\n  background-color: var(--secondary-color);\r\n  color: var(--text-light-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
