import axios from "axios";

/**
 * Delete a dealership by ID.
 * @param {number} dealershipId - The ID of the dealership to delete.
 * @returns {Promise<Object>} - The response from the API.
 */
const deleteDealership = async (dealershipId) => {
  console.log("Deleting Dealership with ID:", dealershipId);

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/dealerships/${dealershipId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log("Delete Dealership Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting dealership:", error);
    throw error;
  }
};

export default deleteDealership;
