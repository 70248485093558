import React, { useState, useEffect } from "react";
import { fetchWindscreenImage } from "../../utils/fetchWindscreenImage"; // Import the fetch function
import ImageModal from "../ImageModal/ImageModal"; // Import the ImageModal component
import "./Windscreen.css";

const Windscreen = ({ registrationNumber }) => {
  const [windscreenImage, setWindscreenImage] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedImage, setSelectedImage] = useState(null); // Selected image URL

  useEffect(() => {
    const fetchImage = async () => {
      const image = await fetchWindscreenImage(registrationNumber); // Use the fetch function
      setWindscreenImage(image);
    };

    if (registrationNumber) {
      fetchImage();
    }
  }, [registrationNumber]);

  const handleImageClick = () => {
    setSelectedImage(`${process.env.REACT_APP_MEDIA_BASE_URL}${windscreenImage.image_path}`);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <div className="windscreen">
      {windscreenImage ? (
        <>
          <img
            src={`${process.env.REACT_APP_MEDIA_BASE_URL}${windscreenImage.image_path}`}
            alt="Windscreen"
            crossOrigin="anonymous"
            onClick={handleImageClick} // Trigger modal on image click
          />
          {/* Modal for viewing full-sized windscreen image */}
          <ImageModal show={showModal} onClose={handleCloseModal} imageUrl={selectedImage} />
        </>
      ) : (
        <p>No windscreen image found</p>
      )}
    </div>
  );
};

export default Windscreen;
