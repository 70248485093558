import axios from "axios";

// Fetch all insurance claims from the backend API
export const fetchClaimDetails = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/insurance-claims`);
    return response.data; // Return the data fetched from the backend
  } catch (error) {
    console.error("Error fetching insurance claims:", error);
    throw error;
  }
};
