// components/InvoiceHistoryModal/InvoiceHistoryModal.jsx
import React, { useState, useEffect } from "react";
import "./InvoiceHistoryModal.css";
import logo from "../../assets/images/starbreak-logo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ModalPortal from "../ModalPortal/ModalPortal";
import fetchClientInvoices from "../../utils/fetchClientInvoices";

const companyInfo = {
  name: "Starbreak Car Care Solutions",
  registrationNo: "98/064087/23",
  vatNo: "4730183771",
  tel: "+27 (11) 391 2800",
  email: "accounts@starbreak.co.za",
  website: "www.starbreak.co.za",
};

const InvoiceHistoryModal = ({ onClose, registrationNumber }) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (registrationNumber) {
      const fetchInvoiceData = async () => {
        try {
          setLoading(true);
          setError("");
          const fetchedInvoices = await fetchClientInvoices(registrationNumber);
          if (fetchedInvoices.length === 0) {
            setError(
              `No invoices found for registration number: ${registrationNumber}.`
            );
          }
          setInvoices(fetchedInvoices);
        } catch (err) {
          console.error("Error fetching invoices:", err.message);
          setError("No invoices found.");
        } finally {
          setLoading(false);
        }
      };

      fetchInvoiceData();
    } else {
      setInvoices([]);
      setError("Registration number is required.");
    }
  }, [registrationNumber]);

  const handleOpenInvoice = (invoiceId) => {
    const invoice = invoices.find((inv) => inv.id === invoiceId);
    if (invoice) {
      const width = 800;
      const height = 850;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;

      const invoiceWindow = window.open(
        "",
        "Invoice",
        `width=${width},height=${height},left=${left},top=${top}`
      );
      invoiceWindow.document.write(`
        <html>
          <head>
            <title>Invoice</title>
          </head>
          <body>
            <div style="padding: 20px; font-family: Arial, sans-serif;">
              <div style="display: flex; justify-content: space-between; align-items: center;">
                <img src="${logo}" alt="Logo" style="height: 80px;"/>
                <h1>Tax Invoice</h1>
              </div>
              <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                <div>
                  <p><strong>Client Information</strong></p>
                  <p>${invoice.client_name}</p>
                  <p>${invoice.client_contact}</p>
                </div>
                <div>
                  <p><strong>Company Information</strong></p>
                  <p>${companyInfo.name}</p>
                  <p>Registration No: ${companyInfo.registrationNo}</p>
                  <p>VAT Registration: ${companyInfo.vatNo}</p>
                  <p>Tel: ${companyInfo.tel}</p>
                  <p>Email: ${companyInfo.email}</p>
                  <p>${companyInfo.website}</p>
                </div>
              </div>
              <div style="margin-top: 20px;">
                <p><strong>Invoice ID:</strong> ${invoice.id}</p>
                <p><strong>Job ID:</strong> ${invoice.job_id}</p>
                <p><strong>Registration Number:</strong> ${
                  invoice.vehicle_registration
                }</p>
                <p><strong>Date:</strong> ${invoice.date}</p>
              </div>
              <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                <thead>
                  <tr>
                    <th style="border: 1px solid #ddd; padding: 8px;">Description</th>
                    <th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
                    <th style="border: 1px solid #ddd; padding: 8px;">Price</th>
                    <th style="border: 1px solid #ddd; padding: 8px;">Total</th>
                  </tr>
                </thead>
                <tbody>
                  ${invoice.items
                    .map(
                      (item) => `
                    <tr>
                      <td style="border: 1px solid #ddd; padding: 8px;">${
                        item.description
                      }</td>
                      <td style="border: 1px solid #ddd; padding: 8px;">${
                        item.quantity
                      }</td>
                      <td style="border: 1px solid #ddd; padding: 8px;">R${
                        item.price
                      }</td>
                      <td style="border: 1px solid #ddd; padding: 8px;">R${
                        item.quantity * item.price
                      }</td>
                    </tr>
                  `
                    )
                    .join("")}
                </tbody>
              </table>
              <div style="margin-top: 20px; text-align: right;">
                <p><strong>Subtotal:</strong> R${invoice.items.reduce(
                  (sum, item) => sum + item.price * item.quantity,
                  0
                )}</p>
                <p><strong>VAT (15%):</strong> R${(
                  invoice.items.reduce(
                    (sum, item) => sum + item.price * item.quantity,
                    0
                  ) * 0.15
                ).toFixed(2)}</p>
                <p><strong>Total:</strong> R${(
                  invoice.items.reduce(
                    (sum, item) => sum + item.price * item.quantity,
                    0
                  ) * 1.15
                ).toFixed(2)}</p>
              </div>
            </div>
          </body>
        </html>
      `);
    }
  };

  const downloadInvoicePDF = (invoiceId) => {
    const invoice = invoices.find((inv) => inv.id === invoiceId);
    if (invoice) {
      const doc = new jsPDF({
        unit: "pt",
        format: "a4",
      });
      const margin = 40;
      const startX = margin;
      const startY = margin;

      doc.setFontSize(10);
      doc.addImage(logo, "PNG", startX, startY, 140, 80);
      doc.text("Tax Invoice", 400, startY + 40);

      let currentY = startY + 100;
      doc.text(`Client: ${invoice.client_name}`, startX, currentY);
      currentY += 20;
      doc.text(`Contact: ${invoice.client_contact}`, startX, currentY);

      currentY += 40;
      doc.text(`Invoice ID: ${invoice.id}`, startX, currentY);
      currentY += 20;
      doc.text(`Date: ${invoice.date}`, startX, currentY);

      currentY += 40;
      doc.autoTable({
        startY: currentY,
        head: [["Description", "Quantity", "Price", "Total"]],
        body: invoice.items.map((item) => [
          item.description,
          item.quantity,
          `R${item.price}`,
          `R${item.quantity * item.price}`,
        ]),
      });

      doc.save(`Invoice_${invoice.id}.pdf`);
    }
  };

  if (loading) {
    return <div className="modal-message">Loading...</div>;
  }

  return (
    <ModalPortal>
      <div className="overlay" onClick={onClose}></div>
      <div
        className="invoice-history-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Invoice History</h2>
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Registration Number</th>
                <th>Client Name</th>
                <th>Date</th>
                <th>View</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td>{invoice.job_id}</td>
                  <td>{invoice.vehicle_registration}</td>
                  <td>{invoice.client_name}</td>
                  <td>{invoice.date}</td>
                  <td>
                    <button
                      className="view-invoice-btn"
                      onClick={() => handleOpenInvoice(invoice.id)}
                    >
                      View Invoice
                    </button>
                  </td>
                  <td>
                    <button
                      className="download-invoice-btn"
                      onClick={() => downloadInvoicePDF(invoice.id)}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </ModalPortal>
  );
};

export default InvoiceHistoryModal;
