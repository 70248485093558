// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.windscreen {
  border: 1px solid var(--border-dark-color);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  max-height: 120px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.windscreen img {
  width: 100%;
  height: 100%; 
  object-fit: contain;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Windscreen/Windscreen.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".windscreen {\r\n  border: 1px solid var(--border-dark-color);\r\n  padding: 20px;\r\n  width: 100%;\r\n  max-width: 400px;\r\n  max-height: 120px;\r\n  margin-top: 20px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.windscreen img {\r\n  width: 100%;\r\n  height: 100%; \r\n  object-fit: contain;\r\n  border-radius: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
