// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-box {
  position: absolute;
  top: -5px;
  left: 105%;
  background-color: var(--primary-color);
  color: var(--text-light-color);
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 1002;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.tooltip-container:hover .tooltip-box {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,sCAAsC;EACtC,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".tooltip-container {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.tooltip-box {\r\n  position: absolute;\r\n  top: -5px;\r\n  left: 105%;\r\n  background-color: var(--primary-color);\r\n  color: var(--text-light-color);\r\n  padding: 5px 10px;\r\n  border-radius: 4px;\r\n  z-index: 1002;\r\n  white-space: nowrap;\r\n  pointer-events: none;\r\n  opacity: 0;\r\n  transition: opacity 0.2s;\r\n}\r\n\r\n.tooltip-container:hover .tooltip-box {\r\n  opacity: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
