// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ManageDealerships.css */

.ManageDealerships {
  margin: 20px;
}

form {
  margin-bottom: 20px;
}

input,
select,
button {
  margin: 5px;
  padding: 8px;
  border: 1px solid var(--border-background-color);
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid var(--th-td-border-background-color);
  padding: 10px;
  text-align: left;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  background-color: #f0f0f0;
}

.icon-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.fapluscircle-icon {
  font-size: 25px;
  cursor: pointer;
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ManageDealerships/ManageDealerships.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;;EAGE,WAAW;EACX,YAAY;EACZ,gDAAgD;EAChD,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sDAAsD;EACtD,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB","sourcesContent":["/* ManageDealerships.css */\r\n\r\n.ManageDealerships {\r\n  margin: 20px;\r\n}\r\n\r\nform {\r\n  margin-bottom: 20px;\r\n}\r\n\r\ninput,\r\nselect,\r\nbutton {\r\n  margin: 5px;\r\n  padding: 8px;\r\n  border: 1px solid var(--border-background-color);\r\n  border-radius: 4px;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-top: 20px;\r\n}\r\n\r\nth,\r\ntd {\r\n  border: 1px solid var(--th-td-border-background-color);\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\ntbody tr {\r\n  cursor: pointer;\r\n}\r\n\r\ntbody tr:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n\r\n.icon-container {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.fapluscircle-icon {\r\n  font-size: 25px;\r\n  cursor: pointer;\r\n  color: #007bff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
